import React, { useState } from 'react';
import axios from 'axios';
import 'react-responsive-modal/styles.css';
import Button from '@mui/material/Button';
import Alert from '@mui/material/Alert';
import Modal from 'react-responsive-modal';
import { createTheme, ThemeProvider } from '@mui/material/styles';

export default function AddUserModal({archive, access}){
  const [isLoading, setIsLoading] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [email, setEmail] = useState(null);
  const [role, setRole] = useState('12361224');
  const [success, setSuccess] = useState(false);
  const [emailToDisplay, setEmailToDisplay] = useState(null);
  const theme = createTheme({
    palette:{
      nepalBlue: {
        main: '#0e4c8f',
        contrastText: '#fff',
      },
    },
  });
  const addUser = async () => {
    setSuccess(false);
    if (email == null){
        alert('Please add the gmail address.')
    }else{
                    setIsLoading(true);
                    axios.post('https://qlh20lfdpc.execute-api.us-east-1.amazonaws.com/v1/postMRMSUsers',
                    {
                      "key1": `${email}`,
                      "key2": `${access}`,
                      "key3": `${archive}`,
                      "key4": `${role}`
                    }
                  ).then(() => {
                   setEmailToDisplay(`${email}`);
                   setSuccess('true');
                   setEmail(null);
                   setRole('12361224');
                   setIsLoading(false);
                  }).catch(error => {
                    alert('There was an issue adding ' + `${email}`+ 'to the repository. Please report at contact@archivenepal.org. Thanks', error);
                    setSuccess(false);
                    setIsLoading(false);
                  });
    } }

  return (
    <div>
      <Button onClick={() => setOpen(true)} style={{float: 'right', marginRight: '10px'}}> Add user</Button>
      <Modal open={open} onClose={() => setOpen(false)} >
            <div style={{maxWidth: 'auto', maxHeight: 'auto', marginTop: '20px', padding: '15px'}}>
            {isLoading && <span id='loader'><img src="/images/loader.gif" id='loader' /></span>}  
            { success &&  <Alert severity="success">The user {emailToDisplay} was added successfully to the {archive} repository. They can login into <span style={{fontWeight: 'bold'}}>https://www.archivenepal.org/mrms</span> to access.<ThemeProvider theme={theme}><Button color='nepalBlue' variant="contained" size="small" onClick={() => setSuccess(false)}>Ok</Button></ThemeProvider></Alert>}
            {!isLoading && ( 
            <div>
            <h1>Add users to access the '{archive}' repository.</h1>    
            <label style={{ float: "left" }}>Gmail of the user:</label>
                    <input
                        type="text"
                        name="email"
                        onChange={e => setEmail(e.target.value)}
                        value={email}
                        style={{ marginBottom: '25px' }}
                    />        
            <label style={{ float: "left" }}>Select the role:</label>
            <select name="role" onChange={e => setRole(e.target.value)} >
                <option value="12361224" selected>General</option>
                <option value="1236122442">Contributor</option>
                <option value="1235813">Admin</option>
            </select> 
            <ThemeProvider theme={theme}>
               <Button color='nepalBlue' variant="contained" onClick={() => addUser()} style={{ marginTop: '20px'}} disabled={isLoading}> Add User</Button>
            </ThemeProvider>
            <ul style={{listStyleType: 'none', marginTop: '20px', marginBottom: '5px', fontSize: '15px'}}>
              <li>* <span style={{fontWeight: 'bold'}}>Admin</span> can contribute to the repository and grant other users access to the repository.</li>
              <li>* <span style={{fontWeight: 'bold'}}>Contributor</span> can access and contribute to the repository but CANNOT grant other users access.</li>
              <li>* <span style={{fontWeight: 'bold'}}>General</span> users can only access the repository but CANNOT contribute or grant other users access.</li>
            </ul>
            <p style={{ fontSize: '13px', textAlign: 'left' }}>For questions or to report an issue please email us at <a href="mailto:contact@archivenepal.org">contact@archivenepal.org</a></p>     
              </div>
          )}
          </div>
      </Modal>
    </div>
  );
}