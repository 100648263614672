import React, { useState, useEffect } from 'react';
import { FcGoogle } from 'react-icons/fc';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { GoogleLogin } from '@react-oauth/google';
import jwt_decode from 'jwt-decode';
import config from '../config/env.json';

export default function GoogleLoginPage() { 
  const [data, setData] = useState([]);
  const [email, setEmail] = useState([]); 
  const [name, setName] = useState([]); 
  //const [code, setCode] = useState([]); 
  const fetchURL = "https://hyt2czbda0.execute-api.us-east-1.amazonaws.com/v1/getAccessLink?username=" + email;
  const now = new Date();
  const item = {
    expiry: now.getTime() + 3600000,
  }

  useEffect(() => {
   fetch(fetchURL)
      .then((res) => res.json())
      .then((data) => {
       setData(data.data);
       localStorage.setItem('key', JSON.stringify(item))
       localStorage.setItem('user', JSON.stringify(name))
      })
      .catch((err) => {
        console.log(err);
      });
  }, [email]);

  const responseGoogle = (response) => {
      console.log(response.credential);
      var decoded = jwt_decode(response.credential);
      setName(decoded.name);
      setEmail(decoded.email);
    }
    
function datamap(){
  try {
      data.map((item) =>
        localStorage.setItem('archive', item.Item.archivename.trim())
      )  
      data.map((item) =>
        localStorage.setItem('code', item.Item.code.trim())
      )  
      data.map((item) =>
      localStorage.setItem('access', item.Item.accesslink.trim())
      )      
     data.map((item) =>
       (window.location.replace(config.REACT_ENV + item.Item.accesslink.trim()))
      ) 
  } catch(err) {
    alert('You are not signed up for the Manaslu Record Management System. Please contact Archive Nepal at contact@archivenepal.org for subscription details.');
    window.location.replace(config.REACT_ENV + 'mrms');
}
}

  return (
    <div className="">
          <div className="">
            <GoogleOAuthProvider 
                clientId="669728913801-6beq07brso86492q6qm2lpua8v3ttg12.apps.googleusercontent.com"
                >
         <GoogleLogin
              render={(renderProps) => (
                <button
                  type="button"
                  className=""
                  onClick={renderProps.onClick}
                  disabled={renderProps.disabled}
                >
                  <FcGoogle className="" /> Use google to sign in
                </button>
              )}
              onSuccess={responseGoogle}
              onFailure={responseGoogle}
              cookiePolicy="single_host_origin"
              />
            </GoogleOAuthProvider>
          </div>
          {
           // data.map((item) => (window.location.replace(config.REACT_ENV + item.Item.accesslink.trim())))   
           datamap()
          }
    </div>
  );
}