import * as React from 'react';
import 'react-responsive-modal/styles.css';
import Button from '@mui/material/Button';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Modal from 'react-responsive-modal';
import Outgoing from '@mui/icons-material/CallMissedOutgoing';
import { HiOutlinePhotograph } from 'react-icons/hi';

export default function ImageModal({id, url, title, desc, salestype, link, collection}){
  const [open, setOpen] = React.useState(false);
  const descForm = 'https://docs.google.com/forms/d/e/1FAIpQLSf-EF3H0sZRAQnfAKudzeQMR4SITO38eppI0rMypUXcaGezqQ/viewform?usp=sf_link&entry.1800311655='+ id + '||' + url +'&entry.1233992912='+ title;
  const theme = createTheme({
    palette:{
      nepalBlue: {
        main: '#0e4c8f',
        contrastText: '#fff',
      },
    },
  });
 // const requestform = "https://docs.google.com/forms/d/e/1FAIpQLSeQsZsbsPyI-Rv4doLHLAqtJ-3B2-2gioo69rdMImoSOS1_qA/viewform?entry.2053101105=" + JSON.stringify(title);

  return (
    <div>
      <h4 className="sui-result_title sui-result_title-link"  onClick={() => setOpen(true)} style={{ fontWeight: 'bold', cursor:'pointer'}}><span style={{ marginRight: '5px' }}>< HiOutlinePhotograph size={20} style={{ marginBottom: '5px' }} /></span>{title}</h4>
      <Modal open={open} onClose={() => setOpen(false)} >
            <div style={{maxWidth: '50%', maxHeight: 'auto', marginTop: '20px'}}>
                <img src={url} style={{ maxWidth: '200%', maxHeight: 'auto', border: '1px solid black'}} />
            </div>
            <h3>{title}</h3>
        <div style={{padding: '10px', textAlign: 'left'}}>{desc}</div>
        {collection == 'Shyam Chitrakar Collection' ? <span><p style={{fontSize: '13px'}}> * This project was supported by the generous donations from the global Nepali diaspora and with the support of our digital partner</p><p><a href='https://professional.edu.np/' target='_blank'><img style={{width: '20%', marginTop: '-25px', marginBottom: '0px', display: 'block', marginLeft: 'auto', marginRight: 'auto'}} src='https://archivenepal.s3.amazonaws.com/images/pec.PNG' /></a></p> </span>: <p></p>}
        {salestype == 'Physical Reprints' ?  <ThemeProvider theme={theme}><a href={url} target='_blank'><Button color='nepalBlue' variant='contained' size='small' className='searchButton'>Expand view <Outgoing size={3} /></Button></a><a href={link} target='_blank'><Button color='nepalBlue' variant='contained' size='small' className='searchButton' style={{float: 'right'}}>Order reprints</Button></a></ThemeProvider> : salestype == 'Original Art' ?  <ThemeProvider theme={theme}><a href={url} target='_blank'><Button color='nepalBlue' variant='contained' size='small' className='searchButton'>Expand view <Outgoing size={3} /></Button></a><a href='https://www.archivenepal.org/contactUs' target='_blank'><Button color='nepalBlue' variant='contained' size='small' className='searchButton' style={{float: 'right'}}>Contact to buy</Button></a></ThemeProvider> :  <ThemeProvider theme={theme}><a href={link} target='_blank'><Button color='nepalBlue' variant='contained' size='small' className='searchButton'>Learn more <Outgoing size={3} /></Button></a></ThemeProvider>}  
        <div style={{marginTop: '10px'}}><a href={descForm} target='_blank'><Button variant='outlined' size='small'>Know more about this image? Suggest a description<Outgoing size={3} /></Button></a></div>
      </Modal>
    </div>
  );
}
