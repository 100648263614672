import React, { Component } from 'react';
import '../styles/main.css';
import { Link } from 'react-router-dom';
import Box from '@mui/material/Box';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import { FaSearch } from 'react-icons/fa';
import { Helmet } from 'react-helmet';
import ImageModal2 from '../imageModal2';

const itemData = 
[
    {
        "img": "https://images.eap.bl.uk/EAP838/EAP838_1_7_3/659.jp2/full/!1400,700/0/default.jpg",
        "link": "https://eap.bl.uk/item/EAP838-1-7-3-659",
        "title": "An aerial view of Kathmandu Valley [1950]",
        "date": "1950"
      },
      {
        "img": "https://archivenepal.s3.amazonaws.com/images/terai.jpeg",
        "link": "https://lh3.googleusercontent.com/pw/AL9nZEVMLKf_-szeHHgA3wVjIvBJOEZDAvvCD4Hx9Rnplq96EPzPQ2sFbWDt3Dl3mjqUtH5p6g3m7CkTEb8JMoiCXZYv7HiSBknArgqe0kLJao8qXAMitYUaqmaaR-cRvO3VdFkFIFn7Dej7Ojheu_cnBq5HzQ=w1024-h704-no?authuser=0",
        "title": "Terai Aerial view [2006]",
        "date": "2006"
      },
      {
        "img": "https://images.eap.bl.uk/EAP838/EAP838_1_7_3/85.jp2/full/!1400,700/0/default.jpg",
        "link": "https://eap.bl.uk/item/EAP838-1-7-3-85",
        "title": "Aerial View of Swyayambhu [1950]",
        "date": "1950"
      },
      {
        "img": "https://roundsimaging.com/galleries/nepal-peace-corp-photos/individual-contributors/mel-goldman/photos/MG_CS000137.jpg",
        "link": "https://roundsimaging.com/galleries/nepal-peace-corp-photos/individual-contributors/mel-goldman/MG_CS000137-single.php",
        "title": "Aerial view from helicopter of Kali Gandaki river and surrounding hills. [1969]",
        "date": "1969"
      },
      {
        "img": "https://roundsimaging.com/galleries/nepal-peace-corp-photos/individual-contributors/mike-furst/photos/MFurst0030.jpg",
        "link": "",
        "title": "Aerial view of Kathmandu valley and Thimi area, on approach to Tribhuvan airport [1971]",
        "date": "1971"
      },
      {
        "img": "https://roundsimaging.com/galleries/nepal-peace-corp-photos/individual-contributors/alan-fairbank/photos/AF_CS00634.jpg",
        "link": "https://roundsimaging.com/galleries/nepal-peace-corp-photos/individual-contributors/alan-fairbank/AF_CS00634-single.php",
        "title": "Aerial view of Kathmandu International Airport with the Himalayan range in the far background [1969]",
        "date": "1969"
      },
      {
        "img": "https://roundsimaging.com/galleries/nepal-peace-corp-photos/individual-contributors/alan-fairbank/photos/AF_CS00011.jpg",
        "link": "https://roundsimaging.com/galleries/nepal-peace-corp-photos/individual-contributors/alan-fairbank/AF_CS00011-single.php",
        "title": "Aerial view of Bhaktapur center city showing Nyatapola temple prominently in the center [1969]",
        "date": "1969"
      },
      {
        "img": "https://roundsimaging.com/galleries/nepal-peace-corp-photos/individual-contributors/peter-von-mertens/photos/PVM_CS000151.jpg",
        "link": "https://roundsimaging.com/galleries/nepal-peace-corp-photos/individual-contributors/peter-von-mertens/PVM_CS000151-single.php",
        "title": "Aerial view of the southern end of Dhankuta. Compound on the left of main trail is the local hospital [1966]",
        "date": "1966"
      },
      {
        "img": "https://roundsimaging.com/galleries/nepal-peace-corp-photos/individual-contributors/bill-hanson/photos/BH_CS000204.jpg",
        "link": "https://roundsimaging.com/galleries/nepal-peace-corp-photos/individual-contributors/bill-hanson/BH_CS000204-single.php",
        "title": "Aerial view of rice fields and small bazaar as the airplane nears Pokhara to land [1966]",
        "date": "1966"
      },
      {
        "img": "https://roundsimaging.com/galleries/nepal-peace-corp-photos/individual-contributors/alan-fairbank/photos/AF_CS00006.jpg",
        "link": "https://roundsimaging.com/galleries/nepal-peace-corp-photos/individual-contributors/alan-fairbank/AF_CS00006-single.php",
        "title": "Aerial view of the tundikhel and Ratna Park looking north towards the Narayanhiti Palace [1969]",
        "date": "1969"
      },
      {
        "img": "https://roundsimaging.com/galleries/nepal-peace-corp-photos/individual-contributors/alan-fairbank/photos/AF_CS00045.jpg",
        "link": "https://roundsimaging.com/galleries/nepal-peace-corp-photos/individual-contributors/alan-fairbank/AF_CS00045-single.php",
        "title": "Aerial view of rice fields flooded from monsoon rains in Surkhet Valley [1968]",
        "date": "1968"
      },
      {
        "img": "https://roundsimaging.com/galleries/nepal-peace-corp-photos/individual-contributors/peter-von-mertens/photos/PVM_CS000151.jpg",
        "link": "https://roundsimaging.com/galleries/nepal-peace-corp-photos/individual-contributors/peter-von-mertens/PVM_CS000151-single.php",
        "title": "Aerial view of the southern end of Dhankuta. Compound on the left of main trail is the local hospital [1966]",
        "date": "1966"
      },
      {
        "img": "https://roundsimaging.com/galleries/nepal-peace-corp-photos/individual-contributors/alan-fairbank/photos/AF_CS00593.jpg",
        "link": "https://roundsimaging.com/galleries/nepal-peace-corp-photos/individual-contributors/alan-fairbank/AF_CS00593-single.php",
        "title": "Aerial view of bridge over the Bheri River at Rang Ghat [1971]",
        "date": "1971"
      },
      {
        "img": "https://roundsimaging.com/galleries/nepal-peace-corp-photos/individual-contributors/bill-borsa/photos/1.jpg",
        "link": "https://roundsimaging.com/galleries/nepal-peace-corp-photos/individual-contributors/bill-borsa/1-single.php",
        "title": "Aerial view of Bodey, a small, compact Newar town east of Tribhuvan Airport [1967]",
        "date": "1967"
      },
      {
        "img": "https://roundsimaging.com/galleries/nepal-peace-corp-photos/individual-contributors/alan-fairbank/photos/AF_CS00635.jpg",
        "link": "https://roundsimaging.com/galleries/nepal-peace-corp-photos/individual-contributors/alan-fairbank/AF_CS00635-single.php",
        "title": "Aerial view of main street of Bhairahawa.. [1968]",
        "description": "Location: Siddharthanagar, Rupendehi Description: Aerial view of main street of Bhairahawa looking north as airplane approaches landing at Bhairahawa airport several miles to the west.",
        "date": "1968"
      },
      {
        "img": "https://roundsimaging.com/galleries/nepal-peace-corp-photos/individual-contributors/perry-letson/photos/PCL265.jpg",
        "link": "https://roundsimaging.com/galleries/nepal-peace-corp-photos/individual-contributors/perry-letson/PCL265-single.php",
        "title": "An aerial view of the Arun River. During the monsoon, the mighty river rushes out of the hills at the Chatra Gorge and overflows its banks in the Terai [1971]",
        "date": "1971"
      },
      {
        "img": "https://roundsimaging.com/galleries/nepal-peace-corp-photos/individual-contributors/alan-fairbank/photos/AF_CS00299.jpg",
        "link": "",
        "title": "Aerial view of Jumla town looking northward towards Mugu Distirct. The trail following the river north is the way to Rara Lake in Mugu. [1971]",
        "date": "1971"
      },
      {
        "img": "https://roundsimaging.com/galleries/nepal-peace-corp-photos/individual-contributors/doug-and-nancy-hatch/photos/DNH_CS00288.jpg",
        "link": "https://roundsimaging.com/galleries/nepal-peace-corp-photos/individual-contributors/doug-and-nancy-hatch/DNH_CS00288-single.php",
        "title": "Aerial view of houses below the temple area on the ridge. Picture taken from helicopter as it went from Silgadhi to the STOL airfield at Dipayal to refuel [1968]",
        "date": "1968"
      },
      {
        "img": "https://roundsimaging.com/galleries/nepal-peace-corp-photos/individual-contributors/mike-furst/photos/MFurst0458.jpg",
        "link": "https://roundsimaging.com/galleries/nepal-peace-corp-photos/individual-contributors/mike-furst/MFurst0458-single.php",
        "title": "Aerial view of Surket valley [1970]",
        "date": "1970"
      },
      {
        "img": "https://roundsimaging.com/galleries/nepal-peace-corp-photos/individual-contributors/brian-cooke/photos/BC_CS0088.jpg",
        "link": "https://roundsimaging.com/galleries/nepal-peace-corp-photos/individual-contributors/brian-cooke/BC_CS0088-single.php",
        "title": " Aerial view of British Gurkha camp, a small station intended to assist British Gurkhas Nepal operations in eastern Nepal [1965]",
        "date": "1965"
      },
      {
        "img": "https://roundsimaging.com/galleries/nepal-peace-corp-photos/individual-contributors/rich-pfau/photos/RP_FB0845.jpg",
        "link": "https://roundsimaging.com/galleries/nepal-peace-corp-photos/individual-contributors/rich-pfau/RP_FB0845-single.php",
        "title": "Air view of new houses being built in area of rice paddy and fields [1977]",
        "date": "1977"
      },
      {
        "img": "https://archivenepal.s3.amazonaws.com/images/Terai-19.jpeg",
        "link": "https://photos.google.com/share/AF1QipOIR0g7mq--cJ45iTFODUZuyXR7SRw2bIUFvCYO0H_E8Q-o-KKqoZJzsH1RVm8HEA/photo/AF1QipPBwPHOlDXCsRjJqiu1SWx_3IZLc3tpIJQ12z5N?key=QXpHai02UkUwVlh4V1dvTUNPU0NYN1BKRWRlVlJ3",
        "title": "Aerial view of Terai [2011]",
        "date": "2011"
      },
      {
        "img": "https://archivenepal.s3.amazonaws.com/images/Bodnath-7.jpeg",
        "link": "https://photos.google.com/share/AF1QipPAlULi6YxU_ImH6_Olx-VJBg9yTGAiyPJoxZt645NJh1qifFvPIcFpxnj2fAdFqg/photo/AF1QipMX5GrJH_neMm4Hu9C2f2apHIeHr_2agtyFGWwK?key=UmVnSDRBUEtPRVVVQTk5NjVfd0ZtYW1rMEZkQzhB",
        "title": "Aerial view of Boudhanath [1979]",
        "date": "1979"
      },
      {
        "img": "https://archivenepal.s3.amazonaws.com/images/ENepal-20.jpeg",
        "link": "https://photos.google.com/share/AF1QipMfSqFZxCS_dg_jS2sW8UnyjAY1zTA-Agk8Tk6Nx8t3Sm1tFGDvMkJLS8AMm91uCA/photo/AF1QipO3L_IatEM03WaqyFtOV_i1NxG3Uz_vVRTKGao1?key=WmN6dXFhMjN5aWJHVjJqNWZfZGJNbkFJUVM2R2pB",
        "title": "Aerial view of Eastern Nepal [2011]",
        "date": "2011"
      },
      {
        "img": "https://archivenepal.s3.amazonaws.com/images/Kathmandu-41.jpeg",
        "link": "https://photos.google.com/share/AF1QipMfSqFZxCS_dg_jS2sW8UnyjAY1zTA-Agk8Tk6Nx8t3Sm1tFGDvMkJLS8AMm91uCA/photo/AF1QipPE9yWl04W93tPb9aRG3hBZfvwiBIMAqYgKT7OT?key=WmN6dXFhMjN5aWJHVjJqNWZfZGJNbkFJUVM2R2pB",
        "title": "Air view of Kathmandu [2011]",
        "date": "2011"
      },
      {
        "img": "https://archivenepal.s3.amazonaws.com/images/Swayambhunath-10.jpeg",
        "link": "https://photos.google.com/share/AF1QipOsge-Bh2ec2UID6kGi4KyM6Y-5P-BJNaanhD3nDJKmOiXZrWReJ-Al71lzeFHDSg/photo/AF1QipPGH2JhSB6w8KnhajLvcoHcNp_MijaajPZhGl7p?key=MkdBWVppYkVSV2ktVXpYcms1YlJaWTJRSUZGeW5R",
        "title": "Aerial View of Swayambhunath [2011]",
        "date": "2011"
      },
      {
        "img": "https://archivenepal.s3.amazonaws.com/images/Pashupatinath-1b.jpeg",
        "link": "https://photos.google.com/share/AF1QipMfSqFZxCS_dg_jS2sW8UnyjAY1zTA-Agk8Tk6Nx8t3Sm1tFGDvMkJLS8AMm91uCA/photo/AF1QipNDEznhBHJmMBQPXHA8m_kN3w28tsG9kG5lRfzw?key=WmN6dXFhMjN5aWJHVjJqNWZfZGJNbkFJUVM2R2pB",
        "title": "Pashupatinath Aerial view [2011]",
        "date": "2011"
      },
      {
        "img": "https://roundsimaging.com/galleries/nepal-peace-corp-photos/individual-contributors/alan-fairbank/photos/AF_CS00605.jpg",
        "link": "https://roundsimaging.com/galleries/nepal-peace-corp-photos/individual-contributors/alan-fairbank/AF_CS00605-single.php",
        "title": "View of the Himalayas looking northeast from an airplane approaching Bhairahawa flying at about 10,000’ about 100 miles west of Kathmandu [1969]",
        "date": "1969"
      }
 ] 

class AerialViews extends Component {
    constructor(props) {
        super(props);
        this.state = {
            windowWidth: window.innerWidth,
            searchTerms: '',
            isLoading: false,
            error: null
        };
    }

    onChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    }

    onKeyUp(e) {
        if (e.charCode === 13) {
            window.location.href = '/search?q=' + e.target.value;
        }
    }

    handleResize = (e) => {
        this.setState({ windowWidth: window.innerWidth });
    }

    componentDidMount() {
        window.addEventListener("resize", this.handleResize);
    }

    componentWillUnmount() {
        window.addEventListener("resize", this.handleResize);
    }

    render() {
        const { windowWidth, searchTerms, isLoading, error } = this.state;

        return (
            <div className="application">
                <Helmet>
                    <meta charset="utf-8" />
                    <title>Aerial views of Nepal | Archive Nepal</title>
                    <link rel="canonical" href="https://www.archivenepal.org/aerialviews" />
                    <meta name="description" content="Aerial view of Nepal" />
                </Helmet>

                <div id="page-wrapper">

                    {windowWidth >= 1265 ? <div className="input-group col-md-4 childSearchBox" style={{ float: 'right', marginTop: '65px' }}>
                        <input type="text" className="form-control input-lg" name="searchTerms" placeholder="Discover Nepal through its history" value={searchTerms} onChange={this.onChange} onKeyPress={this.onKeyUp} style={{ padding: "5px" }} />
                        <Link to={'/search?q=' + this.state.searchTerms} className="searchButton" ><FaSearch /></Link>
                    </div> :
                        <div className="input-group col-md-4 childSearchBox" style={{ float: 'right', marginTop: '65px' }}>
                            <input type="text" className="form-control input-md" name="searchTerms" placeholder="Discover Nepal through its history" value={searchTerms} onChange={this.onChange} onKeyPress={this.onKeyUp} />
                            <Link to={'/search?q=' + this.state.searchTerms} className="searchButton" ><FaSearch /></Link>
                        </div>
                    }
                    <section id="main" className="container">
                        <h2>Aerial views of Nepal</h2>
                        <h5>Compiled by: Shreya Gautam</h5>
                        <section className="box special features">
                            <div className="features-row">
                                <div id="masonry">

                                    {windowWidth >= 1265 ? <Box>
                                        <ImageList variant="masonry" cols={3} gap={20}>
                                            {itemData.map((item) => (
                                                    <ImageListItem key={item.img}>
                                                     <ImageModal2 url={item.img} title={item.title} desc={item.description} salestype='none' link={item.link} />
                                                    </ImageListItem>
                                            ))}
                                        </ImageList>
                                    </Box> :
                                        <Box>
                                            <ImageList variant="masonry" cols={1} gap={1}>
                                                {itemData.map((item) => (
                                                    <ImageListItem key={item.img}>
                                                        <ImageModal2 url={item.img} title={item.title} desc={item.description} salestype='none' link={item.link} />                                    
                                                    </ImageListItem>
                                                ))}
                                            </ImageList>
                                        </Box>
                                    }

                                </div>
                            </div>
                        </section>
                    </section>
                </div>
            </div>
        )
    }
}
export default AerialViews;
