import React, { useState } from 'react';
import axios from 'axios';
import S3 from 'react-aws-s3';
import Button from '@mui/material/Button';
import { TextareaAutosize } from '@mui/material';

// installed using npm install buffer --save
window.Buffer = window.Buffer || require("buffer").Buffer;

// a React functional component, used to create a simple upload input and button

const Upload = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [selectedFile, setSelectedFile] = useState(null);
    const [title, setTitle] = useState(null);
    const [description, setDescription] = useState(null);

    // the configuration information is fetched from the .env file
    const config = {
        bucketName: 'archivenepal',
        dirName: 'nepalitimes',
        region: 'us-east-1',
        accessKeyId: 'AKIAY4C5JYJP5UP7YZAF',
        secretAccessKey: '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',
        s3Url: 'https://archivenepal.s3.amazonaws.com'
    }

    const handleFileInput = (e) => {
        setSelectedFile(e.target.files[0]);
    }

    const uploadFile = async (file) => {
        if (title == null){
            alert('Please add a title.')
        }else if (selectedFile == null){
            alert('Please select a file to upload.')
        }else{
            setIsLoading(true);
            const ReactS3Client = new S3(config);
            // the name of the file uploaded is used to upload it to S3
            const newFileName = file.name.replace("#", "_");
            ReactS3Client
                .uploadFile(file, newFileName)
                .then((data) => {
                    if (data.status == 204) {
                        axios.post('https://c9ah5rkgh4.execute-api.us-east-1.amazonaws.com/v1/postntdetails',
                        {
                          "key1": `NT`,
                          "key2": Date.parse(Date().toLocaleString()),
                          "key3": `2023`,
                          "key4": data.location,
                          "key5": `${title}`,
                          "key6": `${description}`
                        }
                      ).then((res) => {
                        setTitle('');
                        setSelectedFile(null);
                        setIsLoading(false);
                      }).catch(error => {
                        alert('File was uploaded successfully but failed to add the record to the database. Please report at contact@archivenepal.org. Thanks', error);
                        setIsLoading(false);
                      });
                        alert('Upload successful and available at ' + data.location);
                        window.location.reload(false);
                    } else {
                        setIsLoading(false);
                        alert('Error during upload. Please contact the administrator at contact@archivenepal.org.')
                    }
                })
                .catch(err => console.error(err))
        } 
    }
    return (
        <div id="page-wrapper">
            <section id="main" className="container">
                <section className="box special features">
                { isLoading && <span id='loader'><img src="https://archivenepal.s3.amazonaws.com/images/loader.gif" id='loader' /></span> }
                    <h3>Archive Nepal File Uploader</h3>
                    <label style={{ float: "left" }}>Title [eg. 20 - 26 May 2022]:</label>
                    <input
                        type="text"
                        name="title"
                        onChange={e => setTitle(e.target.value)}
                        value={title}
                        style={{ marginBottom: '25px' }}
                    />
                    <label style={{ float: "left" }}>Description [Optional]:</label>
                    <TextareaAutosize
                        name="description"
                        onChange={e => setDescription(e.target.value)}
                        value={description}
                        style={{ marginBottom: '25px' }}
                    />

                    <input type="file" onChange={handleFileInput} style={{ float:'left', width: '100%', marginBottom: '25px'}}/>

                    <Button variant="contained" onClick={() => uploadFile(selectedFile)} disabled={isLoading} > Upload</Button>
                    <p style={{ marginTop: '40px', fontSize: '15px', textAlign: 'left' }}>For questions or to report an issue please email us at <a href="mailto:contact@archivenepal.org">contact@archivenepal.org</a></p>
                </section>
            </section>
        </div>
    )
}

export default Upload;
