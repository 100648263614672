import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import { FaLinkedin, FaInstagram, FaTwitter } from 'react-icons/fa';
import './styles/main.css';

class OurTeam extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div className="application">
                <Helmet>
                    <meta charset="utf-8" />
                    <title>Our Team | Archive Nepal</title>
                    <link rel="canonical" href="https://www.archivenepal.org/ourTeam" />
                    <meta name="description" content="Our team at Archive Nepal is dedicated to the goal of creating a digital archive of Nepal and ensuring the historic materials related to Nepal are freely accessible." />
                </Helmet>
                <div id="page-wrapper">
                    <section id="main" className="container">
                        <div style={{ textAlign: 'left' }}>
                            <h3>Our Team</h3>
                            <div class="row">
                                <div class="column">
                                    <div class="card">
                                        <div class="cardImage">
                                            <img src="https://archivenepal.s3.amazonaws.com/images/our-team/Monish.jpeg" alt="Monish Singh" style={{ width: '100%' }} />
                                        </div>
                                        <div class="containerTitle">
                                            <h5 style={{ fontSize: '25px', fontWeight: 'bold' }}>Monish Singh</h5>
                                            <p class="title">Founder and Executive Director</p>
                                        </div>
                                        <div class="containerText">
                                            <p>Monish is our Founder and Executive Director. He works as a Project Manager for W.W. Norton and Company, a publishing company in New York, US. He is also an Adjunct Faculty of Systems Analysis and Design, a graduate level course at Marist College, NY. He has worn multiple hats as a Software Developer, Business Analyst and Project Manager during his 14 years career in the Software Development field and has an extensive experience working as a Digital Archivist managing digitization and archival projects. From 2014 - 2016 he also served as a Steering committee member for the Encoded Archival Description (EAD) Roundtable of the Society of American Archivist, focusing on promoting the implementation and use of the encoding standards for archival information. He has a M.Sc. in Information Systems from Marist College, NY and B.E. (Computer Science and Engineering) from Visvesvaraya Technological Univeristy, Belgaum, India. </p>
                                            <p style={{ marginTop: '-20px'}}><span className="icon minor"><a href="https://www.linkedin.com/in/monishsingh" target="_blank" style={{textDecoration: 'none'}}><FaLinkedin size={20} /></a></span></p>
                                        </div>
                                    </div>
                                </div>

                                <div class="column">
                                    <div class="card">
                                        <div class="cardImage">
                                            <img src="https://archivenepal.s3.amazonaws.com/images/our-team/Dristi.jpg" alt="Dristi Manandhar" style={{ width: '100%' }} />
                                        </div>
                                        <div class="containerTitle">
                                            <h5 style={{ fontSize: '25px', fontWeight: 'bold' }}>Dristi Manandhar</h5>
                                            <p class="title">Director of Nepal Operations </p>
                                        </div>    
                                        <div class="containerText">
                                            <p>Dristi is our Director of Nepal Operations and Collection Development. She works as an architectural designer at Carleton Hart Architecture in Portland, Oregon. She is also a freelance artist and cofounder of Didibahini. She has a Masters in Architecture from the University of Oregon and Bachelor's in Architecture from Institute of Engineering, Nepal.</p>
                                            <p style={{ marginTop: '-20px'}}><span className="icon minor"><a href="https://www.linkedin.com/in/dristi-manandhar-182779aa" target="_blank" style={{textDecoration: 'none'}}><FaLinkedin size={20} /></a></span><span className="icon minor"><a href="https://www.instagram.com/didibahini/" target="_blank" style={{textDecoration: 'none'}}><FaInstagram size={20} /></a></span></p>
                                        </div>
                                    </div>
                                </div>
                            
                                <div class="column">
                                    <div class="card">
                                        <div class="cardImage">
                                            <img src="https://archivenepal.s3.amazonaws.com/images/our-team/Sristi.jpg" alt="Sristi Shrestha" style={{ width: '100%' }} />
                                        </div>
                                        <div class="containerTitle">
                                            <h5 style={{ fontSize: '25px', fontWeight: 'bold' }}>Sristi Shrestha</h5>
                                            <p class="title">Director of Communications</p>
                                        </div>    
                                        <div class="containerText">
                                            <p>Sristi is our Director of  Communications. She works as a Data Analyst in Solutions 4 Community Health, a health care solutions company in New York, US. She previously worked as a Claims Consultant in London, UK before moving to the US. She has M.Sc. in Information Systems from Marist College, NY and MBA from University of Gloucestershire, UK. She holds a Bachelor's degree in Business Information Systems from KCM (Kathmandu College of Management), Nepal.</p>
                                            <p style={{ marginTop: '-20px'}}><span className="icon minor"><a href="https://www.linkedin.com/in/sristishrestha" target="_blank" style={{textDecoration: 'none'}}><FaLinkedin size={20} /></a></span></p>
                                        </div>
                                    </div>
                                </div>

                                <div class="column">
                                    <div class="card">
                                        <div class="cardImage">
                                            <img src="https://archivenepal.s3.amazonaws.com/images/our-team/Jayan.jpeg" alt="Jayan Singh" style={{ width: '100%' }} />
                                        </div>
                                        <div class="containerTitle">
                                            <h5 style={{ fontSize: '25px', fontWeight: 'bold' }}>Jayan Singh</h5>
                                            <p class="title">Data Engineer</p>
                                        </div>  
                                        <div class="containerText">
                                            <p>Jayan is our Data Engineer. He is currently working as a Technical Solutions Engineer for a staffing software company. Over the years he has held roles in data visualization, data analysis, and data migration. He has a Bachelor's Degree in Computer Engineering. He is currently based in Vancouver, Canada.</p>
                                            <p style={{ marginTop: '-20px'}}><span className="icon minor"><a href="https://www.linkedin.com/in/jayansingh91" target="_blank" style={{textDecoration: 'none'}}><FaLinkedin size={20} /></a></span></p>
                                        </div>
                                    </div>
                                </div>

                                <div class="column">
                                    <div class="card">
                                        <div class="cardImage">
                                            <img src="https://archivenepal.s3.amazonaws.com/images/our-team/Shovana.jpeg" alt="Shovana Shrestha" style={{ width: '100%' }} />
                                        </div>
                                        <div class="containerTitle">
                                            <h5 style={{ fontSize: '25px', fontWeight: 'bold' }}>Shovana Shrestha</h5>
                                            <p class="title">Director of HR & Administration</p>
                                        </div>  
                                        <div class="containerText">
                                            <p>Shovana Shrestha is our Operations and HR Manager. She works as a Contracts Administrator for a real-estate development firm. At Archive Nepal, she oversees organizational activities, strategizes process improvements, trains volunteers, and coordinates on going projects. Shovana brings a wealth of administrative experience from roles she has held in business, consulting, education, and non-profit industries. She has a Bachelors in International Studies and Sociology from Simon Fraser University, Vancouver, BC.</p>
                                            <p style={{ marginTop: '-20px'}}><span className="icon minor"><a href="https://www.linkedin.com/in/shovana-shrestha-93b88099/" target="_blank" style={{textDecoration: 'none'}}><FaLinkedin size={20} /></a></span></p>
                                        </div>
                                    </div>
                                </div>

                                <div class="column">
                                    <div class="card">
                                        <div class="cardImage">
                                            <img src="https://archivenepal.s3.amazonaws.com/images/our-team/Prabin.jpg" alt="Prabin Gautam" style={{ width: '100%' }} />
                                        </div>
                                        <div class="containerTitle">
                                            <h5 style={{ fontSize: '25px', fontWeight: 'bold' }}>Prabin Gautam</h5>
                                            <p class="title">Director of Public Outreach</p>
                                        </div>  
                                        <div class="containerText">
                                            <p>Prabin is our Director of Public Outreach. He works as Quality Assurance Lead and offshore coordinator in Minnesota based Medical Insurance company. He has worked previously as Developer and Data warehouse Analyst at multiple companies across US, UK and Nepal. Prabin has also worked as a Software Developer for Madan Puraskar Pustakalaya, Lalitpur, Nepal (one of the pioneer archiving organization in Nepal). Besides, he is also a founder secretary of FOSS Nepal Community and involve in many community projects in Nepal. Prabin has a M.Sc. in Computing from the University of Northampton, UK and B.Sc.(Hons.) from Kathmandu University, Nepal.</p>
                                            <p style={{ marginTop: '-20px'}}><span className="icon minor"><a href="https://www.linkedin.com/in/prabin-gautam" target="_blank" style={{textDecoration: 'none'}}><FaLinkedin size={20} /></a></span></p>
                                        </div>
                                    </div>
                                </div>

                                <div class="column">
                                    <div class="card">
                                        <div class="cardImage">
                                            <img src="https://archivenepal.s3.amazonaws.com/images/our-team/Samip.jpg" alt="Samip Shrestha" style={{ width: '100%' }} />
                                        </div>
                                        <div class="containerTitle">
                                            <h5 style={{ fontSize: '25px', fontWeight: 'bold' }}>Samip Shrestha</h5>
                                            <p class="title">Outreach Manager</p>
                                        </div>  
                                    </div>
                                </div>

                                <div class="column">
                                    <div class="card">
                                        <div class="cardImage">
                                            <img src="https://archivenepal.s3.amazonaws.com/images/our-team/Pratiz.PNG" alt="Pratiz Dangol" style={{ width: '100%' }} />
                                        </div>
                                        <div class="containerTitle">
                                            <h5 style={{ fontSize: '25px', fontWeight: 'bold' }}>Pratiz Dangol</h5>
                                            <p class="title">Account Manager and Contract Consultant</p>
                                        </div>  
                                    </div>
                                </div>

                                <div class="column">
                                    <div class="card">
                                        <div class="cardImage">
                                            <img src="https://archivenepal.s3.amazonaws.com/images/our-team/Komin.jpg" alt="Komintal Thami" style={{ width: '100%' }} />
                                        </div>
                                        <div class="containerTitle">
                                            <h5 style={{ fontSize: '25px', fontWeight: 'bold' }}>Komintal Thami</h5>
                                            <p class="title">Archive Assistant [Contract]</p>
                                        </div>  
                                    </div>
                                </div>

                            </div>

                        <h3>Advisory Council</h3>

                        <div class="row">
                                <div class="column">
                                    <div class="card">
                                        <div class="cardImage">
                                            <img src="https://markturin.sites.olt.ubc.ca/files/2023/05/Mark_Turin_2023_cropped.jpg" alt="Dr. Mark Turin" style={{ width: '60%', margin:'auto', display: 'block' }} />
                                        </div>
                                        <div class="containerTitle">
                                            <h5 style={{ fontSize: '25px', fontWeight: 'bold' }}>Dr. Mark Turin</h5>
                                            <p class="title">Advisory council member</p>
                                        </div>  
                                        <div class="containerText">
                                            <p>Mark Turin is a socio-cultural anthropologist and an Associate Professor at the University of British Columbia. He is cross-appointed between the Institute for Critical Indigenous Studies and the Department of Anthropology.</p>
                            <p >Mark directs both the World Oral Literature Project, an urgent global initiative to document and make accessible endangered oral literatures before they disappear without record, and the <a href='https://www.digitalhimalaya.com/'>Digital Himalaya</a> Project which he co-founded in 2000 as a platform to make multi-media resources from the Himalayan region widely available online. For over twenty years, Mark’s regional focus has been the Himalayan region (particularly Nepal, northern India and Bhutan), and more recently, the Pacific Northwest.</p>
                            <p>He is also the author or co-author of four books, three travel guides, the editor of 12 volumes, and he edits a series on oral literature. Mark is a regular BBC presenter on issues of linguistic diversity and language endangerment. Read his full bio at <a href='https://markturin.arts.ubc.ca/' target='_blank'>https://markturin.arts.ubc.ca/</a></p>
                            <p style={{ marginTop: '-20px' }}><span className="icon minor"><a href="https://twitter.com/markturin" target="_blank" style={{ textDecoration: 'none' }}><FaTwitter size={20} /></a></span></p>
                                        </div>
                                    </div>
                                </div>
                                </div>
                        </div>
                        

                        <div id="volunteers">
                        <h3>Archive Nepal Volunteers</h3>

                        <p>Volunteers play a vital role in our mission, ensuring that the data we ingest is of the highest quality, descriptions are accurate and optimized for discovery, and creating engaging and informative content. We would like to thank all the past and current volunteers for their commitment to this cause and the time and effort you have invested in making our mission a success.</p>
                        <h4>Current Volunteers</h4>
                        <ol>
                            <li>Anjali Tandukar</li>
                            <li>Dinod Shrestha</li>
                            <li>Prapti Tiwari</li>
                            <li>Samarth Sapkota</li>
                            <li>Seniwa Nembang</li>
                        </ol>

                        <h4>Past Volunteers</h4>
                        <ol>
                            <li>Ajay Chaudhary</li>
                            <li>Shreya Gautam</li>
                            <li>Yutsna Gautam</li>
                        </ol>
                        </div>
                    </section>
                </div>
            </div>

        )
    }
}
export default OurTeam;
