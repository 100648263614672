import React, { Component } from 'react';
import './styles/main.css';
import { Helmet } from 'react-helmet';

class ContactUs extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            windowWidth: window.innerWidth
            };
    }

    handleResize = (e) => {
        this.setState({ windowWidth: window.innerWidth });
    }

    componentDidMount() {
        window.addEventListener("resize", this.handleResize);
    }

    componentWillUnmount() {
        window.addEventListener("resize", this.handleResize);
    }
    render() {
        const { windowWidth} = this.state;

        return (
            <div className="application">
                <Helmet>
                    <meta charset="utf-8" />
                    <title>Contact us | Archive Nepal</title>
                    <link rel="canonical" href="https://www.archivenepal.org/contactUs" />
                    <meta name="description" content="We would love to hear your feedback and suggestions on how to better improve Archive Nepal." />
                </Helmet>
            <div id="page-wrapper">
                <section id="main" className="container">
                    <section className="box special features">
                    <p>We would love to hear from you. Please fill out this form or email us at <a href="mailto:contact@archivenepal.org">contact@archivenepal.org</a> and we will get in touch with you soon.</p>
                    <div className="features-row">

                            {windowWidth >= 1285 ? <iframe src="https://docs.google.com/forms/d/e/1FAIpQLSdKQd9XY9x5F8Wswh1H1kTnStqG0vmr6vlTBG8Y5piFhRIEag/viewform?embedded=true" width="600" height="700" frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe> :
                             <iframe src="https://docs.google.com/forms/d/e/1FAIpQLSdKQd9XY9x5F8Wswh1H1kTnStqG0vmr6vlTBG8Y5piFhRIEag/viewform?embedded=true" width="350" height="700" frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe> }
                  
                    </div>
                    </section>
                </section>
            </div>
            </div>
        )
    }
}
export default ContactUs;
