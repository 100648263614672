// index.component.js
import React, { Component } from 'react';
import '../styles/main.css';
import { Link } from 'react-router-dom';
import { FaSearch } from 'react-icons/fa';
import { IoDocumentTextOutline } from 'react-icons/io5';
import Outgoing from '@mui/icons-material/CallMissedOutgoing';
import Carousel from 'react-grid-carousel';
import { Helmet } from 'react-helmet';

export default class Index extends Component {
	constructor(props) {
		super(props);
		this.state = {
			windowWidth: window.innerWidth,
			searchTerms: '',
			isLoading: false,
			error: null
		};
	}

	onChange = (e) => {
		this.setState({ [e.target.name]: e.target.value });
	}

	onKeyUp(e) {
		if (e.charCode === 13) {
			window.location.href = '/search?q=' + e.target.value;
		}
	}

	handleResize = (e) => {
        this.setState({ windowWidth: window.innerWidth });
    }

    componentDidMount() {
        window.addEventListener("resize", this.handleResize);
    }

    componentWillUnmount() {
        window.addEventListener("resize", this.handleResize);
    }

	render() {
		const { windowWidth, searchTerms, isLoading, error } = this.state;

		if (error) {
			return <p>{error.message}</p>;
		}

		if (isLoading) {
			return <p>Loading ....</p>;
		}

		return (
			<div className="application">
				<Helmet>
					<meta charset="utf-8" />
					<title>Digital Himalaya | Archive Nepal</title>
					<link rel="canonical" href="https://www.archivenepal.org/digitalhimalaya" />
					<meta name="description" content="A project to develop digital collection, storage and distribution strategies for multimedia anthropological information from the Himalayan region. The Digital Himalaya project was designed by Alan Macfarlane and Mark Turin as a strategy for archiving and making available ethnographic materials from the Himalayan region. The collection includes Birds of Nepal, 2001 Nepal Census, Christophe von Furer-Haimendorf, Films, Maps of Nepal, Music and audios from the Himalaya, Naga Videodisc, Rare books of Nepal, Thak Archive, Thangmi Archive, Federick Williamson and Journals such as Ancient Nepal
Asian Highland Perspectives	
Ādarśa	
The Bhutan Review	
Britain-Nepal Society Journal	
Bulletin of Tibetology	
Carter Center	
Center for Constitutional Dialogue	
Chowkidar	
Contributions to Nepalese Studies	
Crisis Group	
Doon Library Newsletter	
European Bulletin of Himalayan Research	
Gochali	
Himal	
Himal Southasian	
Himalayan Journal of Sciences	
Hot Nepal	
International IDEA	
Isilim	
Journal of Bhutan Studies	
Journal of the International Association for Bon Research	
Journal of Newar Studies	
Journal of the Tibet Society	
Kailash	
Martin Chautari Policy Briefs	
Midweek	
Mother Tongue Pipal Pustak	
Mulyankan	
Nation Weekly	
Nepalese Translation	
Nepalese Linguistics	
Nepali Aawaz	
Nepali Times	
Newsfront	
Occasional Papers in Sociology and Anthropology	
Ogmios	
Postal Himal	
Peace and Democracy in South Asia	
Pūrṇimā	
Raven	
Read	
Regmi Research Series	
Revue d'Etudes Tibétaines	
Rhythm of Bya Chu	
Shezoed: Rigzhung Research Journal, Samaya, Saptahik, Sharada, Shikshak, Spotlight " />
				</Helmet>
				<div id="page-wrapper">
					<section id="bannerDH">
						<h2><a href="https://www.digitalhimalaya.com/" target='_blank'><img src="https://archivenepal.s3.amazonaws.com/images/dhbg.png" /></a></h2>
						<div className="input-group col-md-4 searchBox">
							<input type="text" className="form-control input-lg" name="searchTerms" placeholder="Search collections from the Digital Himalaya" value={searchTerms} onChange={this.onChange} onKeyPress={this.onKeyUp} style={{ padding: "5px" }} />
							<Link to={'/search?q=' + this.state.searchTerms + '&filters%5B0%5D%5Bfield%5D=collection&filters%5B0%5D%5Bvalues%5D%5B0%5D=Digital Himalaya'} className="searchButton" ><FaSearch /></Link>
						</div>
					</section>
					<section id="main" className="container">
						<section style={{ fontSize: '11pt' }}>
							<p style={{ marginBottom: '5px' }}>A project to develop digital collection, storage and distribution strategies for multimedia anthropological information from the Himalayan region.</p>
							<p>The Digital Himalaya project was designed by Alan Macfarlane and Mark Turin as a strategy for archiving and making available ethnographic materials from the Himalayan region. Based at the Department of Social Anthropology at the University of Cambridge, the project was established in December 2000. From 2002 to 2005, the project moved to the Department of Anthropology at Cornell University and began its collaboration with the University of Virginia. From July 2014, the project has relocated to the University of British Columbia, Vancouver, Canada, and is engaged in a long term collaboration with Sichuan University. <a href="https://www.digitalhimalaya.com/" target='_blank'><Outgoing size={6} /></a></p>
						</section>

						<h3>Browse by Collections:</h3>
						<section className="box special features">

							<div className="features-row">
								<Carousel cols={3} rows={1} gap={10} showDots>
									<Carousel.Item>
										<span className="icon major"><Link to={'/search?size=n_20_n&filters%5B0%5D%5Bfield%5D=collection&filters%5B0%5D%5Bvalues%5D%5B0%5D=Digital%20Himalaya&filters%5B0%5D%5Btype%5D=all&filters%5B1%5D%5Bfield%5D=sub_collection&filters%5B1%5D%5Bvalues%5D%5B0%5D=Birds%20of%20Nepal&filters%5B1%5D%5Btype%5D=all'} style={{ textDecoration: 'none' }}><IoDocumentTextOutline size={50} /></Link></span>
										<Link to={'/search?size=n_20_n&filters%5B0%5D%5Bfield%5D=collection&filters%5B0%5D%5Bvalues%5D%5B0%5D=Digital%20Himalaya&filters%5B0%5D%5Btype%5D=all&filters%5B1%5D%5Bfield%5D=sub_collection&filters%5B1%5D%5Bvalues%5D%5B0%5D=Birds%20of%20Nepal&filters%5B1%5D%5Btype%5D=all'} style={{ textDecoration: 'none' }}><h4>Birds of Nepal</h4></Link>
									</Carousel.Item>
									<Carousel.Item>
										<span className="icon major"><Link to={'/search?q=census&size=n_20_n&filters%5B0%5D%5Bfield%5D=collection&filters%5B0%5D%5Bvalues%5D%5B0%5D=Digital%20Himalaya&filters%5B0%5D%5Btype%5D=all&filters%5B1%5D%5Bfield%5D=sub_collection&filters%5B1%5D%5Bvalues%5D%5B0%5D=Nepal%20Census%202001&filters%5B1%5D%5Btype%5D=all'} style={{ textDecoration: 'none' }}><IoDocumentTextOutline size={50} /></Link></span>
										<Link to={'/search?q=census&size=n_20_n&filters%5B0%5D%5Bfield%5D=collection&filters%5B0%5D%5Bvalues%5D%5B0%5D=Digital%20Himalaya&filters%5B0%5D%5Btype%5D=all&filters%5B1%5D%5Bfield%5D=sub_collection&filters%5B1%5D%5Bvalues%5D%5B0%5D=Nepal%20Census%202001&filters%5B1%5D%5Btype%5D=all'} style={{ textDecoration: 'none' }}><h4>Nepal Census 2001</h4></Link>
									</Carousel.Item>
									<Carousel.Item>
										<span className="icon major"><Link to={'/search?size=n_20_n&filters%5B0%5D%5Bfield%5D=collection&filters%5B0%5D%5Bvalues%5D%5B0%5D=Digital%20Himalaya&filters%5B0%5D%5Btype%5D=all&filters%5B1%5D%5Bfield%5D=sub_collection&filters%5B1%5D%5Bvalues%5D%5B0%5D=Fürer-Haimendorf%20Collection&filters%5B1%5D%5Btype%5D=all'} style={{ textDecoration: 'none' }}><IoDocumentTextOutline size={50} /></Link></span>
										<Link to={'/search?size=n_20_n&filters%5B0%5D%5Bfield%5D=collection&filters%5B0%5D%5Bvalues%5D%5B0%5D=Digital%20Himalaya&filters%5B0%5D%5Btype%5D=all&filters%5B1%5D%5Bfield%5D=sub_collection&filters%5B1%5D%5Bvalues%5D%5B0%5D=Fürer-Haimendorf%20Collection&filters%5B1%5D%5Btype%5D=all'} style={{ textDecoration: 'none' }}><h4>Christoph von Furer-Haimendorf</h4></Link>
									</Carousel.Item>
									<Carousel.Item>
										<span className="icon major"><Link to={'/search?size=n_20_n&filters%5B0%5D%5Bfield%5D=collection&filters%5B0%5D%5Bvalues%5D%5B0%5D=Digital%20Himalaya&filters%5B0%5D%5Btype%5D=all&filters%5B1%5D%5Bfield%5D=sub_collection&filters%5B1%5D%5Bvalues%5D%5B0%5D=Films&filters%5B1%5D%5Btype%5D=all'} style={{ textDecoration: 'none' }}><IoDocumentTextOutline size={50} /></Link></span>
										<Link to={'/search?size=n_20_n&filters%5B0%5D%5Bfield%5D=collection&filters%5B0%5D%5Bvalues%5D%5B0%5D=Digital%20Himalaya&filters%5B0%5D%5Btype%5D=all&filters%5B1%5D%5Bfield%5D=sub_collection&filters%5B1%5D%5Bvalues%5D%5B0%5D=Films&filters%5B1%5D%5Btype%5D=all'} style={{ textDecoration: 'none' }}><h4>Films</h4></Link>
									</Carousel.Item>
									<Carousel.Item>
										<span className="icon major"><Link to={'/search?size=n_20_n&filters%5B0%5D%5Bfield%5D=type&filters%5B0%5D%5Bvalues%5D%5B0%5D=journal&filters%5B0%5D%5Btype%5D=all&filters%5B1%5D%5Bfield%5D=collection&filters%5B1%5D%5Bvalues%5D%5B0%5D=Digital%20Himalaya&filters%5B1%5D%5Btype%5D=all'} style={{ textDecoration: 'none' }}><IoDocumentTextOutline size={50} /></Link></span>
										<Link to={'/search?size=n_20_n&filters%5B0%5D%5Bfield%5D=type&filters%5B0%5D%5Bvalues%5D%5B0%5D=journal&filters%5B0%5D%5Btype%5D=all&filters%5B1%5D%5Bfield%5D=collection&filters%5B1%5D%5Bvalues%5D%5B0%5D=Digital%20Himalaya&filters%5B1%5D%5Btype%5D=all'} style={{ textDecoration: 'none' }}><h4>Journals</h4></Link>
									</Carousel.Item>
									<Carousel.Item>
										<span className="icon major"><Link to={'/search?size=n_20_n&filters%5B0%5D%5Bfield%5D=collection&filters%5B0%5D%5Bvalues%5D%5B0%5D=Digital%20Himalaya&filters%5B0%5D%5Btype%5D=all&filters%5B1%5D%5Bfield%5D=sub_collection&filters%5B1%5D%5Bvalues%5D%5B0%5D=Maps&filters%5B1%5D%5Btype%5D=all'} style={{ textDecoration: 'none' }}><IoDocumentTextOutline size={50} /></Link></span>
										<Link to={'/search?size=n_20_n&filters%5B0%5D%5Bfield%5D=collection&filters%5B0%5D%5Bvalues%5D%5B0%5D=Digital%20Himalaya&filters%5B0%5D%5Btype%5D=all&filters%5B1%5D%5Bfield%5D=sub_collection&filters%5B1%5D%5Bvalues%5D%5B0%5D=Maps&filters%5B1%5D%5Btype%5D=all'} style={{ textDecoration: 'none' }}><h4>Maps</h4></Link>
									</Carousel.Item>
									<Carousel.Item>
										<span className="icon major"><Link to={'search?q=music%20and%20audio%20from%20the%20himalayas&size=n_20_n&filters%5B0%5D%5Bfield%5D=collection&filters%5B0%5D%5Bvalues%5D%5B0%5D=Digital%20Himalaya&filters%5B0%5D%5Btype%5D=all&filters%5B1%5D%5Bfield%5D=sub_collection&filters%5B1%5D%5Bvalues%5D%5B0%5D=Music%20and%20Audio%20from%20the%20Himalayas&filters%5B1%5D%5Btype%5D=all'} style={{ textDecoration: 'none' }}><IoDocumentTextOutline size={50} /></Link></span>
										<Link to={'search?q=music%20and%20audio%20from%20the%20himalayas&size=n_20_n&filters%5B0%5D%5Bfield%5D=collection&filters%5B0%5D%5Bvalues%5D%5B0%5D=Digital%20Himalaya&filters%5B0%5D%5Btype%5D=all&filters%5B1%5D%5Bfield%5D=sub_collection&filters%5B1%5D%5Bvalues%5D%5B0%5D=Music%20and%20Audio%20from%20the%20Himalayas&filters%5B1%5D%5Btype%5D=all'} style={{ textDecoration: 'none' }}><h4>Music</h4></Link>
									</Carousel.Item>
									<Carousel.Item>
										<span className="icon major"><Link to={'/search?q=naga%20videodisc&size=n_20_n&filters%5B0%5D%5Bfield%5D=collection&filters%5B0%5D%5Bvalues%5D%5B0%5D=Digital%20Himalaya&filters%5B0%5D%5Btype%5D=all&filters%5B1%5D%5Bfield%5D=sub_collection&filters%5B1%5D%5Bvalues%5D%5B0%5D=Naga%20Videodisc&filters%5B1%5D%5Btype%5D=all'} style={{ textDecoration: 'none' }}><IoDocumentTextOutline size={50} /></Link></span>
										<Link to={'/search?q=naga%20videodisc&size=n_20_n&filters%5B0%5D%5Bfield%5D=collection&filters%5B0%5D%5Bvalues%5D%5B0%5D=Digital%20Himalaya&filters%5B0%5D%5Btype%5D=all&filters%5B1%5D%5Bfield%5D=sub_collection&filters%5B1%5D%5Bvalues%5D%5B0%5D=Naga%20Videodisc&filters%5B1%5D%5Btype%5D=all'} style={{ textDecoration: 'none' }}><h4>Naga Videodisc and Database</h4></Link>
									</Carousel.Item>
									<Carousel.Item>
										<span className="icon major"><Link to={'/search?q=naga%20videodisc&size=n_20_n&filters%5B0%5D%5Bfield%5D=collection&filters%5B0%5D%5Bvalues%5D%5B0%5D=Digital%20Himalaya&filters%5B0%5D%5Btype%5D=all&filters%5B1%5D%5Bfield%5D=sub_collection&filters%5B1%5D%5Bvalues%5D%5B0%5D=Rare%20Books%20%26%20Manuscripts&filters%5B1%5D%5Btype%5D=all'} style={{ textDecoration: 'none' }}><IoDocumentTextOutline size={50} /></Link></span>
										<Link to={'/search?q=naga%20videodisc&size=n_20_n&filters%5B0%5D%5Bfield%5D=collection&filters%5B0%5D%5Bvalues%5D%5B0%5D=Digital%20Himalaya&filters%5B0%5D%5Btype%5D=all&filters%5B1%5D%5Bfield%5D=sub_collection&filters%5B1%5D%5Bvalues%5D%5B0%5D=Rare%20Books%20%26%20Manuscripts&filters%5B1%5D%5Btype%5D=all'} style={{ textDecoration: 'none' }}><h4>Rare Books</h4></Link>
									</Carousel.Item>
									<Carousel.Item>
										<span className="icon major"><Link to={'/search?q=thak&size=n_20_n&filters%5B0%5D%5Bfield%5D=collection&filters%5B0%5D%5Bvalues%5D%5B0%5D=Digital%20Himalaya&filters%5B0%5D%5Btype%5D=all&filters%5B1%5D%5Bfield%5D=sub_collection&filters%5B1%5D%5Bvalues%5D%5B0%5D=The%20Thak%20Archive&filters%5B1%5D%5Btype%5D=all'} style={{ textDecoration: 'none' }}><IoDocumentTextOutline size={50} /></Link></span>
										<Link to={'/search?q=thak&size=n_20_n&filters%5B0%5D%5Bfield%5D=collection&filters%5B0%5D%5Bvalues%5D%5B0%5D=Digital%20Himalaya&filters%5B0%5D%5Btype%5D=all&filters%5B1%5D%5Bfield%5D=sub_collection&filters%5B1%5D%5Bvalues%5D%5B0%5D=The%20Thak%20Archive&filters%5B1%5D%5Btype%5D=all'} style={{ textDecoration: 'none' }}><h4>Thak Archive</h4></Link>
									</Carousel.Item>
									<Carousel.Item>
										<span className="icon major"><Link to={'/search?q=Thangmi&size=n_20_n&filters%5B0%5D%5Bfield%5D=collection&filters%5B0%5D%5Bvalues%5D%5B0%5D=Digital%20Himalaya&filters%5B0%5D%5Btype%5D=all&filters%5B1%5D%5Bfield%5D=sub_collection&filters%5B1%5D%5Bvalues%5D%5B0%5D=Thangmi%20Film%20Collection&filters%5B1%5D%5Btype%5D=all'} style={{ textDecoration: 'none' }}><IoDocumentTextOutline size={50} /></Link></span>
										<Link to={'/search?q=Thangmi&size=n_20_n&filters%5B0%5D%5Bfield%5D=collection&filters%5B0%5D%5Bvalues%5D%5B0%5D=Digital%20Himalaya&filters%5B0%5D%5Btype%5D=all&filters%5B1%5D%5Bfield%5D=sub_collection&filters%5B1%5D%5Bvalues%5D%5B0%5D=Thangmi%20Film%20Collection&filters%5B1%5D%5Btype%5D=all'} style={{ textDecoration: 'none' }}><h4>Thangmi Archive</h4></Link>
									</Carousel.Item>
									<Carousel.Item>
										<span className="icon major"><Link to={'/search?q=Frederick%20Williamson&size=n_20_n&filters%5B0%5D%5Bfield%5D=collection&filters%5B0%5D%5Bvalues%5D%5B0%5D=Digital%20Himalaya&filters%5B0%5D%5Btype%5D=all&filters%5B1%5D%5Bfield%5D=sub_collection&filters%5B1%5D%5Bvalues%5D%5B0%5D=Williamson%20Collection&filters%5B1%5D%5Btype%5D=all'} style={{ textDecoration: 'none' }}><IoDocumentTextOutline size={50} /></Link></span>
										<Link to={'/search?q=Frederick%20Williamson&size=n_20_n&filters%5B0%5D%5Bfield%5D=collection&filters%5B0%5D%5Bvalues%5D%5B0%5D=Digital%20Himalaya&filters%5B0%5D%5Btype%5D=all&filters%5B1%5D%5Bfield%5D=sub_collection&filters%5B1%5D%5Bvalues%5D%5B0%5D=Williamson%20Collection&filters%5B1%5D%5Btype%5D=all'} style={{ textDecoration: 'none' }}><h4>Frederick Williamson</h4></Link>
									</Carousel.Item>
								</Carousel>
							</div>
							{windowWidth <= 500 ? <p>Please swipe to view additional items</p> : ''}
						</section>
						<h3>Featured:</h3>
						<section className="box special features">

							<div className="features-row">
								<section>
									<Link to={'/nepalitimes'} style={{ textDecoration: 'none' }}><img src="https://archivenepal.s3.amazonaws.com/images/nepalitimesicon.PNG" className='icon major' /></Link>
									<Link to={'/nepalitimes'} style={{ textDecoration: 'none' }}><h4>Nepali Times</h4></Link>
								</section>
								<section>
									<Link to={'/search?size=n_20_n&filters%5B0%5D%5Bfield%5D=collection&filters%5B0%5D%5Bvalues%5D%5B0%5D=Digital%20Himalaya&filters%5B1%5D%5Bfield%5D=sub_collection&filters%5B1%5D%5Bvalues%5D%5B0%5D=Himal&filters%5B1%5D%5Btype%5D=all'} style={{ textDecoration: 'none' }}><img src="https://archivenepal.s3.amazonaws.com/images/himalicon.png" className='icon major' /></Link>
									<Link to={'/search?size=n_20_n&filters%5B0%5D%5Bfield%5D=collection&filters%5B0%5D%5Bvalues%5D%5B0%5D=Digital%20Himalaya&filters%5B1%5D%5Bfield%5D=sub_collection&filters%5B1%5D%5Bvalues%5D%5B0%5D=Himal&filters%5B1%5D%5Btype%5D=all'} style={{ textDecoration: 'none' }}><h4>Himal</h4></Link>
								</section>

							</div>
						</section>
					</section>
				</div>
			</div>
		)
	}
}