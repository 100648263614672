import React, { Component } from 'react';
import Button from '@mui/material/Button';
import '../styles/main.css';
import { Link } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import { FaSearch } from 'react-icons/fa';
import { Helmet } from 'react-helmet';

// https://www.nepalitimes.com/wp-content/themes/nepalitimes/assets/images/nepalitimes-logo.png

const itemData2022 = [
    {
        issue: '#1112',
        title: '20 - 26 May 2022',
        link: 'https://archivenepal.s3.amazonaws.com/digitalhimalaya/collections/journals/nepalitimes/pdf/Nepali_Times_1112.pdf'
    },
    {
        issue: '#1111',
        title: '13 - 19 May 2022',
        link: 'https://archivenepal.s3.amazonaws.com/digitalhimalaya/collections/journals/nepalitimes/pdf/Nepali_Times_1111.pdf'
    },
    {
        issue: '#1110',
        title: '06 - 12 May 2022',
        link: 'https://archivenepal.s3.amazonaws.com/digitalhimalaya/collections/journals/nepalitimes/pdf/Nepali_Times_1110.pdf',
    },
    {
        issue: '#1109',
        title: '29 - 05 May 2022',
        link: 'https://archivenepal.s3.amazonaws.com/digitalhimalaya/collections/journals/nepalitimes/pdf/Nepali_Times_1109.pdf',
    },
    {
        issue: '#1108',
        title: '22 - 28 April 2022',
        link: 'https://archivenepal.s3.amazonaws.com/digitalhimalaya/collections/journals/nepalitimes/pdf/Nepali_Times_1108.pdf',
    },
    {
        issue: '#1107',
        title: '15 - 21 April 2022',
        link: 'https://archivenepal.s3.amazonaws.com/digitalhimalaya/collections/journals/nepalitimes/pdf/Nepali_Times_1107.pdf',
    },
    {
        issue: '#1106',
        title: '08 - 14 April 2022',
        link: 'https://archivenepal.s3.amazonaws.com/digitalhimalaya/collections/journals/nepalitimes/pdf/Nepali_Times_1106.pdf',
    },
    {
        issue: '#1105',
        title: '01 - 07 April 2022',
        link: 'https://archivenepal.s3.amazonaws.com/digitalhimalaya/collections/journals/nepalitimes/pdf/Nepali_Times_1105.pdf',
    },
    {
        issue: '#1104',
        title: '25 - 31 March 2022',
        link: 'https://archivenepal.s3.amazonaws.com/digitalhimalaya/collections/journals/nepalitimes/pdf/Nepali_Times_1104.pdf',
    },
    {
        issue: '#1103',
        title: '18 - 24 March 2022',
        link: 'https://archivenepal.s3.amazonaws.com/digitalhimalaya/collections/journals/nepalitimes/pdf/Nepali_Times_1103.pdf',
    },
    {
        issue: '#1102',
        title: '11 - 17 March 2022',
        link: 'https://archivenepal.s3.amazonaws.com/digitalhimalaya/collections/journals/nepalitimes/pdf/Nepali_Times_1102.pdf',
    },
    {
        issue: '#1101',
        title: '04 - 10 March 2022',
        link: 'https://archivenepal.s3.amazonaws.com/digitalhimalaya/collections/journals/nepalitimes/pdf/Nepali_Times_1101.pdf',
    },
    {
        issue: '#1100',
        title: '25 February - 03 March 2022',
        link: 'https://archivenepal.s3.amazonaws.com/digitalhimalaya/collections/journals/nepalitimes/pdf/Nepali_Times_1100.pdf',
    },
    {
        issue: '#1099',
        title: '18 - 24 February 2022',
        link: 'https://archivenepal.s3.amazonaws.com/digitalhimalaya/collections/journals/nepalitimes/pdf/Nepali_Times_1099.pdf',
    },
    {
        issue: '#1098',
        title: '11 - 17 February 2022',
        link: 'https://archivenepal.s3.amazonaws.com/digitalhimalaya/collections/journals/nepalitimes/pdf/Nepali_Times_1098.pdf',
    },
    {
        issue: '#1097',
        title: '04 - 10 February 2022',
        link: 'https://archivenepal.s3.amazonaws.com/digitalhimalaya/collections/journals/nepalitimes/pdf/Nepali_Times_1097.pdf',
    },
    {
        issue: '#1096',
        title: '28 January - 03 February 2022',
        link: 'https://archivenepal.s3.amazonaws.com/digitalhimalaya/collections/journals/nepalitimes/pdf/Nepali_Times_1096.pdf',
    },
    {
        issue: '#1095',
        title: '21 - 27 January 2022',
        link: 'https://archivenepal.s3.amazonaws.com/digitalhimalaya/collections/journals/nepalitimes/pdf/Nepali_Times_1095.pdf',
    },
    {
        issue: '#1094',
        title: '14 - 20 January 2022',
        link: 'https://archivenepal.s3.amazonaws.com/digitalhimalaya/collections/journals/nepalitimes/pdf/Nepali_Times_1094.pdf',
    },
    {
        issue: '#1093',
        title: '07 - 13 January 2022',
        link: 'https://archivenepal.s3.amazonaws.com/digitalhimalaya/collections/journals/nepalitimes/pdf/Nepali_Times_1093.pdf',
    }
];


class NepaliTimes extends Component {
    constructor(props) {
        super(props);
        this.state = {
            windowWidth: window.innerWidth,
            searchTerms: '',
            isLoading: false,
            error: null,
            data: []
        };
    }

    onChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    }

    onKeyUp(e) {
        if (e.charCode === 13) {
            window.location.href = '/search?q=' + e.target.value;
        }
    }

    handleResize = (e) => {
        this.setState({ windowWidth: window.innerWidth });
    }

    componentDidMount() {
        window.addEventListener("resize", this.handleResize);
        const fetchUrl = "https://ryxu794lrg.execute-api.us-east-1.amazonaws.com/v1/getntdetails";
      fetch(fetchUrl)
      .then(res => res.json())
      .then(
        (result) => {
        this.setState({ 
          isLoaded: true,
          data: result.data.Items
        });
      },
      
      (error) => {
        this.setState({
          isLoaded: true,
          error
        });
       }
     )
    }

    componentWillUnmount() {
        window.addEventListener("resize", this.handleResize);
        const fetchUrl = "https://ryxu794lrg.execute-api.us-east-1.amazonaws.com/v1/getntdetails";
      fetch(fetchUrl)
      .then(res => res.json())
      .then(
        (result) => {
        this.setState({ 
          isLoaded: true,
          data: result.data.Items
        });
      },
      
      (error) => {
        this.setState({
          isLoaded: true,
          error
        });
       }
     )
    }


    render() {
        const { windowWidth, searchTerms, data } = this.state;

        return (
            <div className="application">
                <Helmet>
                    <meta charset="utf-8" />
                    <title>Archive Nepal | Nepali Times</title>
                    <link rel="canonical" href="https://www.archivenepal.org/nepalitimes" />
                    <meta name="description" content="Nepali Times archive." />
                </Helmet>

                <div id="page-wrapper">

                    {windowWidth >= 1285 ? <div className="input-group col-md-4 childSearchBox" style={{ float: 'right', marginTop: '65px' }}>
                        <input type="text" className="form-control input-lg" name="searchTerms" placeholder="Discover Nepal through its history" value={searchTerms} onChange={this.onChange} onKeyPress={this.onKeyUp} style={{ padding: "5px" }} />
                        <Link to={'/search?q=' + this.state.searchTerms} className="searchButton" ><FaSearch /></Link>
                    </div> :
                        <div className="input-group col-md-4 childSearchBox" style={{ float: 'right', marginTop: '65px' }}>
                            <input type="text" className="form-control input-md" name="searchTerms" placeholder="Discover Nepal through its history" value={searchTerms} onChange={this.onChange} onKeyPress={this.onKeyUp} />
                            <Link to={'/search?q=' + this.state.searchTerms} className="searchButton" ><FaSearch /></Link>
                        </div>
                    }
                    <section id="main" className="container">
                        <h2><a href='https://www.nepalitimes.com/' target='_blank'><img src='https://nepalitimes.com/img/logo.png'/></a></h2>
                        <span></span>
                        <div role="presentation">
                            <Breadcrumbs aria-label="breadcrumb" style={{fontWeight: 'normal'}}>
                                <a href="https://www.archivenepal.org/digitalhimalaya" target="_blank">
                                    Digital Himalaya
                                </a>
                                <a href="https://www.archivenepal.org/dhjournals">
                                    Journals
                                </a>
                                <Typography color="text.primary">Nepali Times</Typography>
                            </Breadcrumbs>
                        </div>
                        <section className="box special features">
                            <div className="features-row" style={{textAlign: 'left', marginTop: '-50px'}}>
                                <h3>2024</h3>
                                <ul>
                                {
                                    data.map((item) => (
                                        <div style={{marginBottom: '20px'}}>
                                            <div style={{display: 'flex'}}><a href={item.pdf} target='_blank' style={{fontWeight: 'normal'}}><h1>#{item.id} | Date: {item.maintitle} </h1></a><h1 style={{marginLeft: '10px'}}> | </h1><a href={item.link} target='_blank' style={{fontWeight: 'normal', marginLeft: '10px'}}><h1>Flipbook</h1></a></div>
                                            <span>{item.description}</span>
                                        </div>
                                    ))
                                }
                                {
                                    itemData2022.map((item) => (
                                        <div style={{marginBottom: '20px'}}>
                                            <a href={item.link} target='_blank' style={{fontWeight: 'normal'}}><h1>{item.issue} | Date: {item.title}</h1></a>                                          
                                        </div>
                                        ))
                                
                                }
                                </ul>
                            </div>
                            <Button size="medium" href='https://www.archivenepal.org/search?size=n_20_n&filters%5B0%5D%5Bfield%5D=sub_collection&filters%5B0%5D%5Bvalues%5D%5B0%5D=Nepali%20Times&filters%5B0%5D%5Btype%5D=all'>Browse Nepali Times archives [2000 - Current]</Button>
                        </section>
                    </section>
                </div>
            </div>
        )
    }
}
export default NepaliTimes;
