import React, { Component } from 'react';
import '../styles/main.css';
import Button from '@mui/material/Button';
import { Link } from 'react-router-dom';
import Box from '@mui/material/Box';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar';
import { FaSearch } from 'react-icons/fa';
import { Helmet } from 'react-helmet';

const itemData = [
    {
    img: "https://archivenepal.s3.amazonaws.com/documents/manifesto/nc2022.png",
    link: "https://archivenepal.s3.amazonaws.com/documents/manifesto/NepaliCongress-Manifesto-2022/index.html",
    date: "2022",
    title: "Nepali Congress" 
    },
    {
        img: "https://archivenepal.s3.amazonaws.com/documents/manifesto/rsp2022.png",
        link: "https://archivenepal.s3.amazonaws.com/documents/manifesto/RSP-Manifesto-2022/index.html",
        date: "2022", 
        title: "Rastriya Swatantra Party"
       },
{
 img: "https://archivenepal.s3.amazonaws.com/documents/manifesto/uml2022.png",
 link: "https://archivenepal.s3.amazonaws.com/documents/manifesto/UML-Manifesto-2022/index.html",
 date: "2022",
 title: "CPN (Unified Marxist Leninist)"
}

];

class ElectionManifestos extends Component {
    constructor(props) {
        super(props);
        this.state = {
            windowWidth: window.innerWidth,
            searchTerms: '',
            isLoading: false,
            error: null
        };
    }

    onChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    }

    onKeyUp(e) {
        if (e.charCode === 13) {
            window.location.href = '/search?q=' + e.target.value;
        }
    }

    handleResize = (e) => {
        this.setState({ windowWidth: window.innerWidth });
    }

    componentDidMount() {
        window.addEventListener("resize", this.handleResize);
    }

    componentWillUnmount() {
        window.addEventListener("resize", this.handleResize);
    }

    render() {
        const { windowWidth, searchTerms, isLoading, error } = this.state;

        return (
            <div className="application">
                <Helmet>
                    <meta charset="utf-8" />
                    <title>2022(2079 B.S.) Election Manifestos | Archive Nepal</title>
                    <link rel="canonical" href="https://www.archivenepal.org/electionmanifestos2022" />
                    <meta name="description" content="Election Manifestos for 2022(2079 B.S.) Election" />
                </Helmet>

                <div id="page-wrapper">

                    {windowWidth >= 1285 ? <div className="input-group col-md-4 childSearchBox" style={{ float: 'right', marginTop: '65px' }}>
                        <input type="text" className="form-control input-lg" name="searchTerms" placeholder="Discover Nepal through its history" value={searchTerms} onChange={this.onChange} onKeyPress={this.onKeyUp} style={{ padding: "5px" }} />
                        <Link to={'/search?q=' + this.state.searchTerms} className="searchButton" ><FaSearch /></Link>
                    </div> :
                        <div className="input-group col-md-4 childSearchBox" style={{ float: 'right', marginTop: '65px' }}>
                            <input type="text" className="form-control input-md" name="searchTerms" placeholder="Discover Nepal through its history" value={searchTerms} onChange={this.onChange} onKeyPress={this.onKeyUp} />
                            <Link to={'/search?q=' + this.state.searchTerms} className="searchButton" ><FaSearch /></Link>
                        </div>
                    }
                    <section id="main" className="container">
                        <h2>2022 (2079 B.S.) चुनावी घोषणापत्रहरू</h2>
                        <section className="box special features">
                            <div className="features-row">
                                <div style={{ textAlign: 'left' }}>
                                    <p>General elections will be held in Nepal on 20 November 2022 to elect the 275 members of the House of Representatives. There will be two ballots in the election; one to elect 165 members from single-member constituencies via FPTP, and the other to elect the remaining 110 members from a single nation-wide constituency via party-list proportional representation. The election will be held alongside provincial elections for the seven provincial assemblies.<Button size="small" href='https://en.wikipedia.org/wiki/2022_Nepalese_general_election'>Read More</Button></p>
                                </div>
                                <div id="masonry">

                                    {windowWidth >= 1285 ? <Box>
                                        <ImageList variant="masonry" cols={2} gap={50}>
                                            {itemData.map((item) => (
                                                <a href={item.link} target='_blank'>
                                                    <ImageListItem key={item.img}>
                                                        <img
                                                            src={`${item.img}?w=248&fit=crop&auto=format`}
                                                            srcSet={`${item.img}?w=248&fit=crop&auto=format&dpr=2 2x`}
                                                            alt={item.title}
                                                            loading="lazy"
                                                        />
                                                        <a href={item.link} target='_blank'>
                                                            <ImageListItemBar position="below" title={item.title} style={{ width: '300px' }} />
                                                        </a>
                                                    </ImageListItem>
                                                </a>
                                            ))}
                                        </ImageList>
                                    </Box> :
                                        <Box>
                                            <ImageList variant="masonry" cols={1} gap={1}>
                                                {itemData.map((item) => (
                                                    <ImageListItem key={item.img}>
                                                        <img
                                                            src={`${item.img}?w=248&fit=crop&auto=format`}
                                                            srcSet={`${item.img}?w=248&fit=crop&auto=format&dpr=2 2x`}
                                                            alt={item.title}
                                                            loading="lazy"
                                                        />
                                                        <a href={item.link} target='_blank'>
                                                            <ImageListItemBar position="below" title={item.title} style={{ width: '300px' }} />
                                                        </a>
                                                    </ImageListItem>
                                                ))}
                                            </ImageList>
                                        </Box>
                                    }

                                </div>
                            </div>
                        </section>
                    </section>
                </div>
            </div>
        )
    }
}
export default ElectionManifestos;
