import React, { Component } from 'react';
import './styles/main.css';
import { Helmet } from 'react-helmet';

class RequestToIndex extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            windowWidth: window.innerWidth
            };
    }

    handleResize = (e) => {
        this.setState({ windowWidth: window.innerWidth });
    }

    componentDidMount() {
        window.addEventListener("resize", this.handleResize);
    }

    componentWillUnmount() {
        window.addEventListener("resize", this.handleResize);
    }
    render() {
        const { windowWidth} = this.state;

        return (
            <div className="application">
                <Helmet>
                    <meta charset="utf-8" />
                    <title>Request to Index | Archive Nepal</title>
                    <link rel="canonical" href="https://www.archivenepal.org/requestToIndex" />
                    <meta name="description" content="Please use this form to send us your item links for indexing." />
                </Helmet>
            <div id="page-wrapper">
                <section id="main" className="container">
                    <section className="box special features">
                    <div className="features-row">

                            {windowWidth >= 1285 ? <iframe src="https://docs.google.com/forms/d/e/1FAIpQLSfFY2VHWyUExsAobX2dHoqsp8Boi13RwZKpC0DoiGfVB3u_Hg/viewform?embedded=true" width="600" height="700" frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe> :
                             <iframe src="https://docs.google.com/forms/d/e/1FAIpQLSfFY2VHWyUExsAobX2dHoqsp8Boi13RwZKpC0DoiGfVB3u_Hg/viewform?embedded=true" width="350" height="700" frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe> }
                  
                    </div>
                    </section>
                </section>
            </div>
            </div>
        )
    }
}
export default RequestToIndex;
