import React, { Component } from 'react';
import './styles/main.css';
import { Link } from 'react-router-dom';
import {FaSearch} from 'react-icons/fa';
import { Helmet } from 'react-helmet';
import Button from '@mui/material/Button';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Donate from '@mui/icons-material/VolunteerActivism';

const theme = createTheme({
    palette:{
      nepalBlue: {
        main: '#0e4c8f',
        contrastText: '#fff',
      },
    },
  });

class SupportUs extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            windowWidth: window.innerWidth, 
            searchTerms: '',
            isLoading: false,
            error: null
        };
    }
	
	onChange = (e) => {
		this.setState({ [e.target.name]: e.target.value });
	}
	
	onKeyUp(e){
		if (e.charCode === 13) {
			window.location.href = '/search?q=' + e.target.value;
		  }
	}

    handleResize = (e) => {
        this.setState({ windowWidth: window.innerWidth });
    }

    componentDidMount() {
        window.addEventListener("resize", this.handleResize);
    }

    componentWillUnmount() {
        window.addEventListener("resize", this.handleResize);
    }

    render() {
        const { windowWidth, searchTerms, isLoading, error } = this.state;

        return (
            <div className="application">
                <Helmet>
                    <meta charset="utf-8" />
                    <title>Support Us</title>
                    <link rel="canonical" href="https://www.archivenepal.org/supportus" />
                    <meta name="description" content="How you can support us in our mission." />
                </Helmet>
         
            <div id="page-wrapper">
                
                {windowWidth >= 1285 ? <div className="input-group col-md-4 childSearchBox" style={{float: 'right', marginTop: '65px'}}>
					<input type="text" className="form-control input-lg" name="searchTerms" placeholder="Discover Nepal through its history" value={searchTerms} onChange={this.onChange} onKeyPress={this.onKeyUp} style={{padding: "5px"}} />	
						<Link to={'/search?q=' + this.state.searchTerms} className="searchButton" ><FaSearch /></Link>					
					</div>  :
                    <div className="input-group col-md-4 childSearchBox" style={{float: 'right', marginTop: '65px'}}>
					<input type="text" className="form-control input-md" name="searchTerms"  placeholder="Discover Nepal through its history" value={searchTerms} onChange={this.onChange} onKeyPress={this.onKeyUp} />	
						<Link to={'/search?q=' + this.state.searchTerms} className="searchButton" ><FaSearch /></Link>					
                    </div> 
                    }
                <section id="main" className="container">
                    <h2>Support Our Work</h2>
                    <section className="box special features" style={{textAlign: 'left'}}>
                        <p>As a non profit organization, we need your continued support in our on going efforts to preserve our nation's past for our future generations. You can support us to: </p>
                        <ol>
                            <li>Create a <a href='https://donorbox.org/let-s-together-create-a-digital-archive-of-nepal' target='_blank'>DIGITAL ARCHIVE OF NEPAL</a>. You can help develop Archive Nepal as a valuable national digital resource that is always FREELY available for anyone to research and learn about Nepal. A small gift of $5 will help us keep the servers up. Your valuable donation will be used to:</li>
                            <ul style={{marginBottom: '25px'}}>
                                <li>Support our site and pay for the servers.</li>
                                <li>Continue our search for historic materials related to Nepal.</li>
                                <li>Continue our work to index digitized historic materials available on the web.</li>
                                <li>Work with organizations in Nepal to host their collections on our platform.</li>
                                <li>Promote our work and contents.</li> 
                                <li>Continue to develop new features, resources and tools to make our platform more user friendly.</li>
                            </ul>
                            <p style={{fontStyle:'italic'}}>I want to <ThemeProvider theme={theme}><a href='https://donorbox.org/let-s-together-create-a-digital-archive-of-nepal' target='_blank'><Button color='nepalBlue' variant='contained' size='small' className='searchButton' startIcon={<Donate />}>support </Button></a></ThemeProvider> to create a FREE digital resource about Nepal's history for anyone to research and learn.</p>

                            <li>Support our current projects.</li>
                            <ul>
                                <li><a href='https://www.gofundme.com/f/shyam-chitrakar-digitization-project' target='_blank'>DIGITIZATION PROJECT - SHYAM CHITRAKAR COLLECTION</a></li>
                                <p>Archive Nepal is collaborating with veteran sports photojournalist Mr. Shyam Chitrakar to digitize photos from his vast collection of thousands of photos and videos from the 1970s to the present. As part of Phase 1 of the digitization project, the Archive Nepal team will identify, digitize 500 rare photos, and make them publicly available via Archive Nepal’s website. You can play a significant role in digitally preserving and promoting one of Nepal's most extensive sports archives, which documents 50 years of Nepal’s sports history. Just $5 can help us digitize 2 rare photos. Click here to <ThemeProvider theme={theme}><a href='https://www.gofundme.com/f/shyam-chitrakar-digitization-project' target='_blank'><Button color='nepalBlue' variant='contained' size='small' className='searchButton'>Learn More</Button></a></ThemeProvider> about this project.</p>
                            </ul>
                        
                            <p style={{fontStyle:'italic'}}>I want to <ThemeProvider theme={theme}><a href='https://www.gofundme.com/f/shyam-chitrakar-digitization-project' target='_blank'><Button color='nepalBlue' variant='contained' size='small' className='searchButton' startIcon={<Donate />}>support </Button></a></ThemeProvider> to preserve Nepal's sports history for our future generations to learn and inspire from.</p>


                            <li>Sponsor a volunteer</li>
                            <p>We recognize the time and effort of our dedicated volunteers. We welcome your donation as a stipend as a token of our appreciation to our volunteers. If you would like to “Support a Volunteer” contact us at <a href="mailto:contact@archivenepal.org">contact@archivenepal.org</a>.</p>

                            <li>Get INVOLVED. We are looking for the following Volunteers to join our team. Expected time commitment is approx. 5 hours per week. No previous experience is required and training will be provided. Please feel free to share the opportunity with your network or refer anyone you view as a good fit for this role to <a href="mailto:hr@archivenepal.org">hr@archivenepal.org</a>.</li>
                            <ol>
                                <li>Data entry volunteer</li>
                                <ul>
                                    <li>Sort, clean and ingest raw data using Google Sheets from various sources so it can be made available on Archive Nepal.</li> 
                                </ul>
                                <li>Content creator volunteer</li>
                                <ul>
                                    <li>Identify and create engaging, educational digital galleries using the materials in Archive Nepal.</li>
                                    <li>Promote Archive Nepal’s mission and outreach.</li>
                                    <li>Create special digital exhibitions and newsletters.</li>
                                </ul>
                                
                            </ol>
                        </ol>
                        <p>Again THANK YOU for being part of our journey and lets keep discovering Nepal through its history!</p> 
                           

                    </section>          
                </section>
            </div> 
            </div>
        )
    }
}
export default SupportUs;
