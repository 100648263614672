import React, { Component } from 'react';
import '../styles/main.css';
import Button from '@mui/material/Button';
import { Link } from 'react-router-dom';
import Box from '@mui/material/Box';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar';
import { FaSearch } from 'react-icons/fa';
import { Helmet } from 'react-helmet';

const itemData = 
[
    {
       img:"https://www.archivenepal.org/images/defaultImage.png",
       link:"https://archivenepal.s3.amazonaws.com/nytimes/Women_Name_In_Nepal_NYTimes1959.pdf",
       title:"Article: WOMAN NAMED IN NEPAL: Elected to Parliament in Vote Led by Congress Party",
       date:"1959"
    },
    {
       img:"https://www.archivenepal.org/images/defaultImage.png",
       link:"https://archivenepal.s3.amazonaws.com/nytimes/NEPALESE_VOTING+_BEGUN-NYTimes1959.pdf",
       title:"Article: NEPALESE VOTING BEGUN: First Groups Go to Polls in First General Election",
       date:"1959"
    },
    {
        img:"https://archivenepal.s3.amazonaws.com/images/voters1959.jpg",
        link:"https://www.gettyimages.ca/detail/news-photo/voters-line-up-to-vote-at-a-polling-station-in-kathmandu-news-photo/1243611869",
        title:"Voters line up to vote at a polling station in Kathmandu, Nepal, on February 24, 1959, during Nepalese first general election.",
        date:"1959"
     },
    {
       img:"https://roundsimaging.com/galleries/nepal-peace-corp-photos/individual-contributors/wayne-stinson/photos/WS_CS00002.jpg",
       link:"https://roundsimaging.com/galleries/nepal-peace-corp-photos/individual-contributors/wayne-stinson/WS_CS00002-single.php",
       title:"Election for Women's Organization. Sub-inspector's sister, supervisor",
       date:"1966"
    },
    {
       img:"https://roundsimaging.com/galleries/nepal-peace-corp-photos/individual-contributors/wayne-stinson/photos/WS_CS00038.jpg",
       link:"https://roundsimaging.com/galleries/nepal-peace-corp-photos/individual-contributors/wayne-stinson/WS_CS00038-single.php",
       title:"Election",
       date:"1966"
    },
    {
       img:"https://roundsimaging.com/galleries/nepal-peace-corp-photos/individual-contributors/jay-cross/photos/JCross_FB0047.jpg",
       link:"https://roundsimaging.com/galleries/nepal-peace-corp-photos/individual-contributors/jay-cross/JCross_FB0047-single.php",
       title:"Voting booth in one of the 7 Panchayat organizations: farmers, youth, women, retired soldiers s, labor, graduates, and children.",
       date:"1965"
    },
    {
       img:"https://roundsimaging.com/galleries/nepal-peace-corp-photos/individual-contributors/wayne-stinson/photos/WS_CS00041.jpg",
       link:"https://roundsimaging.com/galleries/nepal-peace-corp-photos/individual-contributors/wayne-stinson/WS_CS00041-single.php",
       title:"Election",
       date:"1966-08-20"
    },
    {
       img:"https://roundsimaging.com/galleries/nepal-peace-corp-photos/individual-contributors/wayne-stinson/photos/WS_CS00094.jpg",
       link:"https://roundsimaging.com/galleries/nepal-peace-corp-photos/individual-contributors/wayne-stinson/WS_CS00094-single.php",
       title:"Observers at election of district chairman",
       date:"1967-03-13"
    },
    {
       img:"https://roundsimaging.com/galleries/nepal-peace-corp-photos/individual-contributors/jay-cross/photos/JCross_FB0044.jpg",
       link:"https://roundsimaging.com/galleries/nepal-peace-corp-photos/individual-contributors/jay-cross/JCross_FB0044-single.php",
       title:"Woman voting in the women’s organization election",
       date:"1965"
    },
    {
       img:"https://roundsimaging.com/galleries/nepal-peace-corp-photos/individual-contributors/jay-cross/photos/JCross_FB0050.jpg",
       link:"https://roundsimaging.com/galleries/nepal-peace-corp-photos/individual-contributors/jay-cross/JCross_FB0050-single.php",
       title:"Parade for a winner in the Salyan District Panchayat election",
       date:"1965"
    },
    {
       img:"https://roundsimaging.com/galleries/nepal-peace-corp-photos/individual-contributors/brian-cooke/photos/BC-0004.jpg",
       link:"https://roundsimaging.com/galleries/nepal-peace-corp-photos/individual-contributors/brian-cooke/BC-0004-single.php",
       title:"Young Newari women at an outdoor district level election",
       date:"1966"
    },
    {
       img:"https://roundsimaging.com/galleries/nepal-peace-corp-photos/individual-contributors/brian-cooke/photos/BC-0011.jpg",
       link:"https://roundsimaging.com/galleries/nepal-peace-corp-photos/individual-contributors/brian-cooke/BC-0011-single.php",
       title:"Dhankuta District Panchayat Development Officer supervising the conduct of elections for various district \"Sangathans\"",
       date:"1966"
    },
    {
       img:"https://roundsimaging.com/galleries/nepal-peace-corp-photos/individual-contributors/alan-fairbank/photos/AF_CS00406.jpg",
       link:"https://roundsimaging.com/galleries/nepal-peace-corp-photos/individual-contributors/alan-fairbank/AF_CS00406-single.php",
       title:"Karna Bahadur Singh (center, with garlands and red powder) during celebration of his election to represent Dailekh District in the National Panchayat. Karna Bahadur Singh was former English teacher at Dailekh's high school",
       date:"1969"
    },
    {
       img:"https://roundsimaging.com/galleries/nepal-peace-corp-photos/individual-contributors/jay-cross/photos/JCross_FB0041.jpg",
       link:"https://roundsimaging.com/galleries/nepal-peace-corp-photos/individual-contributors/jay-cross/JCross_FB0041-single.php",
       title:"Stage set up for candidate’s election speeches representing one of seven organizations within the Panchayat system: farmers, youth, women, retired soldiers, labor, graduates, and children",
       date:"1965"
    },
    {
       img:"https://roundsimaging.com/galleries/nepal-peace-corp-photos/individual-contributors/wayne-stinson/photos/WS_CS00083.jpg",
       link:"https://roundsimaging.com/galleries/nepal-peace-corp-photos/individual-contributors/wayne-stinson/WS_CS00083-single.php",
       title:"Women waiting to vote",
       date:""
    },
    {
       img:"https://roundsimaging.com/galleries/nepal-peace-corp-photos/individual-contributors/bob-nichols/photos/BN_FB000075.jpg",
       link:"https://roundsimaging.com/galleries/nepal-peace-corp-photos/individual-contributors/bob-nichols/BN_FB000075-single.php",
       title:"A gathering at Hile of people from the surrounding area. The function was \"political,\" a fair or a combination of the two",
       date:"1970"
    },
    {
       img:"https://roundsimaging.com/galleries/nepal-peace-corp-photos/individual-contributors/alan-fairbank/photos/AF_CS00411.jpg",
       link:"https://roundsimaging.com/galleries/nepal-peace-corp-photos/individual-contributors/alan-fairbank/AF_CS00411-single.php",
       title:"Dailekh residents form a celebratory parade past the tundikhel following the completion of voting for a new Dailekh District Panchayat",
       date:"1968"
    },
    {
       img:"https://roundsimaging.com/galleries/nepal-peace-corp-photos/individual-contributors/wayne-stinson/photos/WS_CS00188.jpg",
       link:"https://roundsimaging.com/galleries/nepal-peace-corp-photos/individual-contributors/wayne-stinson/WS_CS00188-single.php",
       title:"Election campaign poster",
       date:"1966"
    },
    {
       img:"https://roundsimaging.com/galleries/nepal-peace-corp-photos/individual-contributors/doug-and-nancy-hatch/photos/DNH_CS00145.jpg",
       link:"https://roundsimaging.com/galleries/nepal-peace-corp-photos/individual-contributors/doug-and-nancy-hatch/DNH_CS00145-single.php",
       title:"Elections taking place on the tundikhel",
       date:"1967"
    },
    {
       img:"https://roundsimaging.com/galleries/nepal-peace-corp-photos/individual-contributors/wayne-stinson/photos/WS_CS00081.jpg",
       link:"https://roundsimaging.com/galleries/nepal-peace-corp-photos/individual-contributors/wayne-stinson/WS_CS00081-single.php",
       title:"Election monitor for Baglung election. He marks the wrists of people who have voted",
       date:"1966"
    },
    {
       img:"https://roundsimaging.com/galleries/nepal-peace-corp-photos/individual-contributors/wayne-stinson/photos/WS_CS00197.jpg",
       link:"https://roundsimaging.com/galleries/nepal-peace-corp-photos/individual-contributors/wayne-stinson/WS_CS00197-single.php",
       title:"Election campaign poster",
       date:"1967"
    },
    {
       img:"https://roundsimaging.com/galleries/nepal-peace-corp-photos/individual-contributors/doug-and-nancy-hatch/photos/DNH_CS00146.jpg",
       link:"https://roundsimaging.com/galleries/nepal-peace-corp-photos/individual-contributors/doug-and-nancy-hatch/DNH_CS00146-single.php",
       title:"Elections taking place on the tundikhel",
       date:"1967"
    },
    {
       img:"https://roundsimaging.com/galleries/nepal-peace-corp-photos/individual-contributors/alan-fairbank/photos/AF_CS00407.jpg",
       link:"https://roundsimaging.com/galleries/nepal-peace-corp-photos/individual-contributors/alan-fairbank/AF_CS00407-single.php",
       title:"Panchayat officials engage in the formalities of electing a new Dailekh District Panchayat on the tundikhel in the old bazaar of Dailekh",
       date:"1968"
    },
    {
       img:"https://roundsimaging.com/galleries/nepal-peace-corp-photos/individual-contributors/wayne-stinson/photos/WS_CS00136.jpg",
       link:"https://roundsimaging.com/galleries/nepal-peace-corp-photos/individual-contributors/wayne-stinson/WS_CS00136-single.php",
       title:"Checking voter registration list",
       date:"1966"
    },
    {
       img:"https://roundsimaging.com/galleries/nepal-peace-corp-photos/individual-contributors/alan-fairbank/photos/AF_CS00541.jpg",
       link:"https://roundsimaging.com/galleries/nepal-peace-corp-photos/individual-contributors/alan-fairbank/AF_CS00541-single.php",
       title:"Karna Bahadur Singh is garlanded and covered with red powder applied by supporters celebrating his victory in being elected Dailekh’s delegate to the National Panchayat When elected, he had been serving as English teacher in Dailekh High School",
       date:"1969"
    },
    {
       img:"https://roundsimaging.com/galleries/nepal-peace-corp-photos/individual-contributors/bob-nichols/photos/BNichols00055.jpg",
       link:"https://roundsimaging.com/galleries/nepal-peace-corp-photos/individual-contributors/bob-nichols/BNichols00055-single.php",
       title:"Election campaign for panchayat pradhan panch. Man and boys on bicycles with green flag in front of the panchayat house. They supported the challenger (Bishawnath Chaudhary from Biruwa) over Babu Sahib (last name was Singh) who was running for re-election as pradhan panch. Bishawnath Chaudhary won this election. Threshing area behind the panchayat house, right.",
       date:"1967"
    },
    {
       img:"https://roundsimaging.com/galleries/nepal-peace-corp-photos/individual-contributors/wayne-stinson/photos/WS_CS00223.jpg",
       link:"https://roundsimaging.com/galleries/nepal-peace-corp-photos/individual-contributors/wayne-stinson/WS_CS00223-single.php",
       title:"Gopal Narayan after his election to the agricultural organization",
       date:"1966"
    },
    {
       img:"https://roundsimaging.com/galleries/nepal-peace-corp-photos/individual-contributors/wayne-stinson/photos/WS_CS00092.jpg",
       link:"https://roundsimaging.com/galleries/nepal-peace-corp-photos/individual-contributors/wayne-stinson/WS_CS00092-single.php",
       title:"Victory procession through bazaar after election of district chairman",
       date:"1967"
    },
    {
       img:"https://roundsimaging.com/galleries/nepal-peace-corp-photos/individual-contributors/alan-fairbank/photos/AF_CS00413.jpg",
       link:"https://roundsimaging.com/galleries/nepal-peace-corp-photos/individual-contributors/alan-fairbank/AF_CS00413-single.php",
       title:"Crowd of celebrants surround Karna Bahadur Singh (covered in red powder in center of photo) as they march through Dailekh town after his election as delegate to the National Panchayat.",
       date:"1968"
    },
    {
       img:"https://roundsimaging.com/galleries/nepal-peace-corp-photos/individual-contributors/jay-cross/JCross_FB0042-single.php",
       link:"https://roundsimaging.com/galleries/nepal-peace-corp-photos/individual-contributors/jay-cross/photos/JCross_FB0042.jpg",
       title:"Preparing ballot boxes for voting in one of the 7 Panchayat organizations. farmers, youth, women, retired soldiers, labor, graduates, and children",
       date:"1965"
    },
    {
       img:"https://roundsimaging.com/galleries/nepal-peace-corp-photos/individual-contributors/alan-fairbank/photos/AF_CS00342.jpg",
       link:"https://roundsimaging.com/galleries/nepal-peace-corp-photos/individual-contributors/alan-fairbank/AF_CS00342-single.php",
       title:"Karna Bahadur Singh making a speech during the official proceedings for the Panchayat elections take place beneath the pipul tree on Dailekh’s tundikhel",
       date:"1968"
    }
 ] 

class ElectionInNepal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            windowWidth: window.innerWidth,
            searchTerms: '',
            isLoading: false,
            error: null
        };
    }

    onChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    }

    onKeyUp(e) {
        if (e.charCode === 13) {
            window.location.href = '/search?q=' + e.target.value;
        }
    }

    handleResize = (e) => {
        this.setState({ windowWidth: window.innerWidth });
    }

    componentDidMount() {
        window.addEventListener("resize", this.handleResize);
    }

    componentWillUnmount() {
        window.addEventListener("resize", this.handleResize);
    }

    render() {
        const { windowWidth, searchTerms, isLoading, error } = this.state;

        return (
            <div className="application">
                <Helmet>
                    <meta charset="utf-8" />
                    <title>Elections in Nepal | Archive Nepal</title>
                    <link rel="canonical" href="https://www.archivenepal.org/electioninnepal" />
                    <meta name="description" content="Election in Nepal" />
                </Helmet>

                <div id="page-wrapper">

                    {windowWidth >= 1285 ? <div className="input-group col-md-4 childSearchBox" style={{ float: 'right', marginTop: '65px' }}>
                        <input type="text" className="form-control input-lg" name="searchTerms" placeholder="Discover Nepal through its history" value={searchTerms} onChange={this.onChange} onKeyPress={this.onKeyUp} style={{ padding: "5px" }} />
                        <Link to={'/search?q=' + this.state.searchTerms} className="searchButton" ><FaSearch /></Link>
                    </div> :
                        <div className="input-group col-md-4 childSearchBox" style={{ float: 'right', marginTop: '65px' }}>
                            <input type="text" className="form-control input-md" name="searchTerms" placeholder="Discover Nepal through its history" value={searchTerms} onChange={this.onChange} onKeyPress={this.onKeyUp} />
                            <Link to={'/search?q=' + this.state.searchTerms} className="searchButton" ><FaSearch /></Link>
                        </div>
                    }
                    <section id="main" className="container">
                        <h2>Elections in Nepal</h2>
                        <h5>Compiled by: Shreya Gautam</h5>
                        <section className="box special features">
                            <div className="features-row">
                                <div style={{ textAlign: 'left' }}>
                                    <p>General election will be held in Nepal on 20 November 2022 to elect the 275 members of the House of Representatives. There will be two ballots in the election; one to elect 165 members from single-member constituencies via FPTP, and the other to elect the remaining 110 members from a single nation-wide constituency via party-list proportional representation. The election will be held alongside provincial elections for the seven provincial assemblies.<Button size="small" href='https://en.wikipedia.org/wiki/2022_Nepalese_general_election'>Read More</Button></p>
                                </div>
                                <div id="masonry">

                                    {windowWidth >= 1285 ? <Box>
                                        <ImageList variant="masonry" cols={3} gap={20}>
                                            {itemData.map((item) => (
                                                <a href={item.link} target='_blank'>
                                                    <ImageListItem key={item.img}>
                                                        <img
                                                            src={`${item.img}?w=248&fit=crop&auto=format`}
                                                            srcSet={`${item.img}?w=248&fit=crop&auto=format&dpr=2 2x`}
                                                            alt={item.title}
                                                            loading="lazy"
                                                        />
                                                        <a href={item.link} target='_blank'>
                                                            <ImageListItemBar position="below" title={item.title} subtitle={<span>Date: {item.date}</span>} style={{ width: '300px' }} />
                                                        </a>
                                                    </ImageListItem>
                                                </a>
                                            ))}
                                        </ImageList>
                                    </Box> :
                                        <Box>
                                            <ImageList variant="masonry" cols={1} gap={1}>
                                                {itemData.map((item) => (
                                                    <ImageListItem key={item.img}>
                                                        <img
                                                            src={`${item.img}?w=248&fit=crop&auto=format`}
                                                            srcSet={`${item.img}?w=248&fit=crop&auto=format&dpr=2 2x`}
                                                            alt={item.title}
                                                            loading="lazy"
                                                        />
                                                        <a href={item.link} target='_blank'>
                                                            <ImageListItemBar position="below" title={item.title} subtitle={<span>Date: {item.date}</span>}  style={{ width: '300px' }} />
                                                        </a>
                                                    </ImageListItem>
                                                ))}
                                            </ImageList>
                                        </Box>
                                    }

                                </div>
                            </div>
                        </section>
                    </section>
                </div>
            </div>
        )
    }
}
export default ElectionInNepal;