import React, { useState } from "react";
import ResultView from "./resultView";
import AppSearchAPIConnector from "@elastic/search-ui-app-search-connector";
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { Helmet } from 'react-helmet';
import AdSense from 'react-adsense';

import {
  ErrorBoundary,
  Facet,
  SearchProvider,
  SearchBox,
  Results,
  PagingInfo,
  ResultsPerPage,
  Paging,
  Sorting,
  WithSearch
} from "@elastic/react-search-ui";
import {
  Layout,
  MultiCheckboxFacet,
  BooleanFacet,
  SingleSelectFacet,
  SingleLinksFacet
} from "@elastic/react-search-ui-views";
//import "@elastic/react-search-ui-views/lib/styles/styles.css" test;

import './styles/styles.css';

import {
  buildAutocompleteQueryConfig,
  buildFacetConfigFromConfig,
  buildSearchOptionsFromConfig,
  buildSortOptionsFromConfig,
  getConfig,
  getFacetFields
} from "./config/config-helper";

const { hostIdentifier, searchKey, endpointBase, engineName } = getConfig();
const connector = new AppSearchAPIConnector({
  searchKey,
  engineName,
  hostIdentifier,
  endpointBase
});
const config = {
  searchQuery: {
    facets: buildFacetConfigFromConfig(),
    ...buildSearchOptionsFromConfig()
  },
  autocompleteQuery: buildAutocompleteQueryConfig(),
  apiConnector: connector,
  alwaysSearchOnInitialLoad: true
};

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

export default function App() {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);


  return (
    <div className="application">
                <Helmet>
                    <meta charset="utf-8" />
                    <title>Search results</title>
                    <link rel="canonical" href="https://www.archivenepal.org/search" />
                    <meta name="description" content="Archive Nepal search results." />
                </Helmet>
                <AdSense.Google client='ca-pub-9192566232547544' slot='2656059013'/>
    <SearchProvider config={config}>
      {/*  <WithSearch mapContextToProps={({ wasSearched }) => ({ wasSearched })}>
        {({ wasSearched }) => {
          return (
            <div className="App">
              <ErrorBoundary>
                <Layout
                  header={<SearchBox autocompleteSuggestions={true} />}
                  sideContent={
                    <div>
                      {wasSearched && (
                        <Sorting
                          label={"Sort by"}
                          sortOptions={buildSortOptionsFromConfig()}
                        />
                      )}
                      {getFacetFields().map(field => (
                        <Facet key={field} field={field} label={field} />
                      ))}
                    </div>
                  }
                  bodyContent={
                    <Results
                      titleField={getConfig().titleField}
                      urlField={getConfig().objectField}
                      thumbnailField= {getConfig().objectField}
                      resultView = {ResultView}
                    />
                  }
                  bodyHeader={
                    <React.Fragment>
                      {wasSearched && <PagingInfo />}
                      {wasSearched && <ResultsPerPage />}
                    </React.Fragment>
                  }
                  bodyFooter={<Paging />}
                />
              </ErrorBoundary>
            </div>
          );
        }}
      </WithSearch> */}

      <WithSearch mapContextToProps={({ isLoading }) => ({ isLoading })}>
        {({ isLoading }) => (
          <div id="page-wrapper">
            <section id="main">

              {isLoading && <span id='loader'><img src="/images/loader.gif" id='loader' /></span>}
              {!isLoading && (
                <Layout
                  header={<SearchBox autocompleteSuggestions={true} />}
                  sideContent={
                    <div>
                      {WithSearch && (
                        <Sorting
                          label={"Sort by"}
                          sortOptions={buildSortOptionsFromConfig()}
                        />
                      )}
                      {getFacetFields().map(field => (
                        <Facet key={field} field={field} label={field} view={SingleLinksFacet} show={30} />
                      ))}
                    </div>
                  }
                  bodyContent={
                    <div>
                      <div className="miniFilter">
                        <Button onClick={handleOpen} variant='outlined' style={{width: '100%'}}>Show Filters</Button>
                        <Modal
                          open={open}
                          onClose={handleClose}
                          aria-labelledby="modal-modal-title"
                          aria-describedby="modal-modal-description"
                        >
                          <Box sx={style} style={{ width: '350px', height: '500px', overflow: 'auto' }}>
                            <Typography id="modal-modal-title" variant="h6" component="h2">Filter By:</Typography>
                            <Typography id="modal-modal-description" sx={{ mt: 2 }}>

                            {WithSearch && (
                        <Sorting
                          label={"Sort by"}
                          sortOptions={buildSortOptionsFromConfig()}
                        />
                      )}
                              {getFacetFields().map(field => (
                                <Facet key={field} field={field} label={field} view={SingleLinksFacet} show={30} />
                              ))}          </Typography>
                          </Box>
                        </Modal>
                      </div>
                      <Results
                        titleField={getConfig().titleField}
                        urlField={getConfig().objectField}
                        thumbnailField={getConfig().objectField}
                        resultView={ResultView}
                      />
                    </div>
                  }
                  bodyHeader={
                    <React.Fragment>
                      {WithSearch && <PagingInfo />}
                      {WithSearch && <ResultsPerPage />}
                    </React.Fragment>
                  }
                  bodyFooter={<Paging />}
                />

              )}
            </section>
          </div>
        )}
      </WithSearch>
    </SearchProvider>
</div>
  );
}
