import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import Fade from 'react-reveal/Fade';
import '../styles/main.css';
import '../styles/won.css';

class UKNepalTreaty extends Component {
    constructor(props) {
        super(props);
        this.state = {
            windowWidth: window.innerWidth,
            searchTerms: '',
            isLoading: false,
            error: null
        };
    }

    handleResize = (e) => {
        this.setState({ windowWidth: window.innerWidth });
    }

    componentDidMount() {
        window.addEventListener("resize", this.handleResize);
    }

    componentWillUnmount() {
        window.addEventListener("resize", this.handleResize);
    }

    render() {
        const { windowWidth, searchTerms, isLoading, error } = this.state;

        return (
            <div className="application">
                <Helmet>
                    <meta charset="utf-8" />
                    <title>1923 Nepal-UK Treaty of Friendship | Archive Nepal</title>
                    <link rel="canonical" href="https://www.archivenepal.org/1923treaty" />
                    <meta name="description" content="On the centenary of the 1923 treaty between Nepal and Britain, this Digibit highlights the importance of this treaty which helped Nepal preserve its independence and sovereignty. Based on the article 'Why the 1923 Nepal–Britain Treaty of Friendship is of great importance for Nepal and the UK' by Surya P Subedi who is a Professor of International Law at School of Law, University of Leeds, UK." />
                </Helmet>
                <div id="page-wrapper">
                    <section id="onlineexhibitmainUKNP" class="profilemain style1">
                        {windowWidth >= 500 ? <h3 id='profileHeader' style={{ color: '#ffffff', fontWeight: 'Bold', width: '500px', marginRight: 'auto', marginLeft: 'auto', background: '#c8102e', opacity: '0.9', fontSize: '21px' }}>1923 NEPAL - UK TREATY OF FRIENDSHIP</h3> : <h3 id='profileHeader' style={{ color: '#ffffff', fontWeight: 'Bold', marginRight: 'auto', marginLeft: 'auto', background: '#c8102e', opacity: '0.9', fontSize: '21px'}}>1923 NEPAL - UK TREATY OF FRIENDSHIP</h3>}
                        <Fade left>
                            <div style={{ padding: '0 25px 0 25px', marginTop: '600px' }}>
                            <p class="info">On the centenary of the 1923 treaty between Nepal and Britain, this Digibit highlights the importance of this treaty which helped Nepal preserve its independence and sovereignty. Based on the article <a href='https://www.nepallivetoday.com/2023/07/18/long-read-why-the-1923-nepal-britain-treaty-of-friendship-is-of-great-importance-for-nepal-and-the-uk/'>Why the 1923 Nepal–Britain Treaty of Friendship is of great importance for Nepal and the UK</a> by Surya P Subedi who is a Professor of International Law at School of Law, University of Leeds, UK.</p>
                            </div>
                        </Fade>
                    </section>
            
                    <section id="oepic2UKNP" class="profilemain style3 primary">
                    <div style={{ fontFamily: 'Montserrat', flex: 'auto' }}><h3 style={{ fontSize: '35px', color:"#000000" }}>First Formal Recognition of Sovereignty</h3></div>
                    <Fade left>
                            <div style={{ padding: '0 25px 0 25px', marginTop: '600px' }}>
                            <p class="info">The 1923 treaty was signed by the British envoy to Nepal, William O'Conner and Prime Minister Chandra Shumsher at Singha Durbar, Kathmandu on December 21, 1923. This treaty was the first formal recognition of the sovereignty and independence of Nepal by the UK. It marked the application of the international law principle of sovereign equality of states, alleviating doubts among Nepali people about a potential British invasion. View the: <a href='https://app.box.com/s/2nmskwisop21snl3rwpw4na54np2lbwc' target='_blank'>1923 Treaty between the United Kingdom and Nepal.</a></p>
                            </div>
                    </Fade>
                    <span style={{color: '#ffffff'}}>Photo Credit: DirghaMan and GaneshMan Chitrakar Art Foundation</span>
                    </section>

                    <section id="oepic3UKNP" class="profilemain style3 primary">
                    <div style={{ fontFamily: 'Montserrat', flex: 'auto' }}><h3 style={{ fontSize: '35px', color:"#ffffff" }}>International Recognition and League of Nations</h3></div>
                    <Fade left>
                            <div style={{ padding: '0 25px 0 25px', marginTop: '600px' }}>
                            <p class="info">Although not a League of Nations member, the treaty, registered in 1925, indirectly acknowledged Nepal's place among independent nations during that time. This recognition facilitated Nepal's establishment of diplomatic relations with various states, including being the first South Asian country to have an embassy in London in 1934. The treaty played a crucial role in Nepal's admission to the UN in 1955, affirming Nepal's continuous existence as a fully sovereign nation. It also helped negate any potential suzerain claim by China over Nepal, which had historical roots dating back to the 1792 Treaty.</p>
                            </div>
                    </Fade>
                    <span style={{color: '#ffffff'}}>Photo Credit: DirghaMan and GaneshMan Chitrakar Art Foundation</span>
                    </section>

                    <section id="oepic4UKNP" class="profilemain style3 primary">
                    <div style={{ fontFamily: 'Montserrat', flex: 'auto' }}><h3 style={{ fontSize: '35px', color:"#000000" }}>Hunting Diplomacy</h3></div>
                    <Fade left>
                            <div style={{ padding: '0 25px 0 25px', marginTop: '600px' }}>
                            <p class="info">Since 1911, using the 'Hunting Diplomacy', Prime Minister Chandra Shumsher had been trying to get formal British acknowledgement of Nepal's sovereignity and get permission to import and manufacture weapons. Situation finally changed after World War I in 1921, where over 100,000 Nepali men died while fighting for the British cause. During the hunting expedition in Chitwan, Nepali and British officials reached a new understanding about Nepali independence, as Thomas Cox explains in an article in the Journal of South Asian Studies. The treaty was finally formalized in 1923. [ Photo: Prime Minister Chandra Shumsher with King George V during Nepal's 'tiger hunt diplomacy' to secure the 1923 treaty with Britain. ] Reference: <a href='https://nepalitimes.com/here-now/centennial-of-hunting-diplomacy-in-nepal'>Centennial of hunting diplomacy in Nepal</a> </p>
                            </div>
                    </Fade>
                    <span style={{color: '#ffffff'}}>Photo Credit: Madan Puraskar Pustakalaya</span>
                    </section>
                   
                    <section id="oepic5UKNP" class="profilemain style3 primary">
                    <div style={{ fontFamily: 'Montserrat', flex: 'auto' }}><h3 style={{ fontSize: '35px', color:"#ffffff" }}>Preservation of Independence during Indian Independence</h3></div>
                    <Fade left>
                            <div style={{ padding: '0 25px 0 25px', marginTop: '600px' }}>
                            <p class="info">The 1923 treaty helped Nepal maintain its status as an independent sovereign state after the independence of India in 1947. It countered reported wishes by some Indian politicians to annex Nepal within India during the formation of the Union of India. [ Photo: Hunting in Chitwan]</p>
                            </div>
                    </Fade>
                    <span style={{color: '#ffffff'}}>Photo Credit: DirghaMan and GaneshMan Chitrakar Art Foundation</span>
                    </section>

                    <section id="oepic7UKNP" class="profilemain style3 primary" >
                    <div style={{ fontFamily: 'Montserrat', flex: 'auto' }}></div>
                    <Fade left>
                            <div style={{ padding: '0 25px 0 25px'}}>
                            <p class="info">Guard of Honour presented to Prince Wales Edward Albert in 1922 with Prime minister Chandra Shumsher. </p>
                            </div>
                    </Fade>
                    <iframe width="100%" height="100%" src="https://www.youtube.com/embed/Pzsv2Y1ZO54?si=deNJIZEq2vthZwLO" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                    </section>

                    <section id="oepic6UKNP" class="profilemain style3 primary">
                    <div style={{ fontFamily: 'Montserrat', flex: 'auto' }}><h3 style={{ fontSize: '35px', color:"#ffffff" }}>Diplomatic Achievement and Rana Regime</h3></div>
                    <Fade left>
                            <div style={{ padding: '0 25px 0 25px', marginTop: '600px' }}>
                            <p class="info">The treaty represented a masterful display of diplomacy by the Rana regime, especially Prime Minister Chandra Shamsher. Chandra Shamsher's efforts to improve relations with Britain, including his 1908 visit to England, were crucial in securing the treaty and recognizing Nepal's sovereignty. [ Photo: HRH Prince of Whales Edward VIII in Chitwan for a hunting trip. Prince Edward and Prime Minister Chandra Shamsher is observing the Guard of Honour.]</p>
                            </div>
                    </Fade>
                    <span style={{color: '#ffffff'}}>Photo Credit: DirghaMan and GaneshMan Chitrakar Art Foundation</span>
                    </section>

                    <section id="oepic8UKNP" class="profilemain style3 primary" >
                    <div style={{ fontFamily: 'Montserrat', flex: 'auto' }}><h3 style={{ fontSize: '35px', color:"#000000" }}>Further Readings:</h3></div>
                    <Fade left>
                            <div style={{ padding: '0 25px 0 25px'}}>
                            <p class="info">
                                <ul>
                                    <li><a href='https://nepalitimes.com/editorial/1923-2023-whsle7yp'>1923 - 2023</a></li>
                                    <li><a href='https://www.nepallivetoday.com/2023/07/18/long-read-why-the-1923-nepal-britain-treaty-of-friendship-is-of-great-importance-for-nepal-and-the-uk/'>Why the 1923 Nepal–Britain Treaty of Friendship is of great importance for Nepal and the UK</a></li>
                                    <li><a href='https://nepalitimes.com/here-now/centennial-of-hunting-diplomacy-in-nepal'>Centennial of hunting diplomacy in Nepal</a></li>
                                    <li><a href='https://blogs.lse.ac.uk/southasia/2023/08/28/nepal-britain-treaty-of-friendship-1923-an-international-legal-perspective/'>Nepal–Britain Treaty of Friendship 1923: An International Legal Perspective</a></li>
                                    <li><a href='https://ekantipur.com/opinion/2023/12/20/that-historic-treaty-06-33.html'>त्यो ऐतिहासिक सन्धि</a></li>
                                </ul>
                            </p>
                            </div>
                    </Fade>
                    </section>
                </div>
            </div>
        )
    }
}
export default UKNepalTreaty;
