import React, { Component } from 'react';
import Button from '@mui/material/Button';
import '../styles/main.css';
import { Link } from 'react-router-dom';
import Box from '@mui/material/Box';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar';
import {FaSearch} from 'react-icons/fa';
import { Helmet } from 'react-helmet';

const itemData = [
    {
        img: 'https://pustakalaya.org/media/uploads/thumbnails/document/2022/12/08/%E0%A4%A4%E0%A4%A8_%E0%A4%98%E0%A4%AE%E0%A4%A4_%E0%A4%89%E0%A4%AA%E0%A4%A8%E0%A4%AF%E0%A4%B8.jpg',
        title: 'Teen Ghumti | तीन घुम्ती (उपन्यास)',
        date: '1968',
        link: 'https://pustakalaya.org/media/uploads/documents/2022/12/08/Bpkoirala2067bs_teenghumti_b7cd9092/BPKoirala2067BS_TeenGhumti.pdf'
    }, 
    {
        img: 'https://pustakalaya.org/media/uploads/thumbnails/document/2022/12/02/%E0%A4%A6%E0%A4%B7_%E0%A4%9A%E0%A4%B6%E0%A4%AE_%E0%A4%B8%E0%A4%B9%E0%A4%B0_%E0%A4%93%E0%A4%9F_%E0%A4%AE%E0%A4%B2%E0%A4%95_%E0%A4%AE%E0%A4%A8%E0%A4%B5%E0%A4%9C%E0%A4%9E%E0%A4%A8%E0%A4%95_%E0%A4%95%E0%A4%A5.jpg',
        title: 'Doshi Chasma | दोषी चश्मा (सोह्र ओटा मौलिक मनोवैज्ञानिक कथा)',
        date: '1949',
        link: 'https://pustakalaya.org/media/uploads/documents/2022/12/02/Bpkoirala2032bs_doshichasma_c337a2c5/BPKoirala2032BS_DoshiChasma.pdf'
    }, 
    {
        img: 'https://pustakalaya.org/media/uploads/thumbnails/document/2022/11/30/%E0%A4%A8%E0%A4%B0%E0%A4%A8%E0%A4%A6%E0%A4%B0_%E0%A4%A6%E0%A4%87_%E0%A4%89%E0%A4%AA%E0%A4%A8%E0%A4%AF%E0%A4%B8.jpg',
        title: 'Narendra Dai | नरेन्द्र दाइ (उपन्यास)',
        date: '1989',
        link: 'https://pustakalaya.org/media/uploads/documents/2022/11/30/Bpkoirala1970_narendradai_c2476331/BPKoirala1970_NarendraDai.pdf'
    }, 
    {
        title: "Babu, Aama ra Chhora | बाबु, आमा र छोरा (उपन्यास)",
        date: "1989",
        img: "https://pustakalaya.org/media/uploads/thumbnails/document/2019/12/20/%E0%A4%AC%E0%A4%AC_%E0%A4%86%E0%A4%AE_%E0%A4%B0_%E0%A4%9B%E0%A4%B0_%E0%A4%89%E0%A4%AA%E0%A4%A8%E0%A4%AF%E0%A4%B8.jpg",
        link: "https://pustakalaya.org/media/uploads/documents/2019/12/20/_11f5689e/BPKoirala2045BS_BabuAamaRaChhora.pdf"
    },
    {
        img: '/images/defaultImage.png',
        title: 'Babu Aama ra Chhora [Audio Book]',
        date: '1989',
        link: 'https://archive.org/details/BabuAamaRaChhora-BpKoirala'
    },
    {
        title: "Modiain | मोदिआइन (उपन्यास)",
        date: "1980",
        img: "https://pustakalaya.org/media/uploads/thumbnails/document/2019/12/20/%E0%A4%AE%E0%A4%A6%E0%A4%86%E0%A4%87%E0%A4%A8_%E0%A4%89%E0%A4%AA%E0%A4%A8%E0%A4%AF%E0%A4%B8.jpg",
        link: "https://pustakalaya.org/media/uploads/documents/2019/12/20/_e2befad6/BPKoirala2052BS_Modiaain.pdf"
    },
    {
        title: "Swet Bhairavi | श्‍वेतभैरवी",
        date: "1983",
        img: "https://pustakalaya.org/media/uploads/thumbnails/document/2020/09/30/%E0%A4%B6%E0%A4%B5%E0%A4%A4%E0%A4%AD%E0%A4%B0%E0%A4%B5.jpg",
        link: "https://pustakalaya.org/media/uploads/documents/2020/09/30/_597ec079/BPKoirala2039BS_SoytBhairabi.pdf"
    },
    {
        title: "Sumnima | सुम्‍निमा (उपन्यास)",
        date: "1969",
        img: "https://pustakalaya.org/media/uploads/thumbnails/document/2020/09/30/%E0%A4%B8%E0%A4%AE%E0%A4%A8%E0%A4%AE_%E0%A4%89%E0%A4%AA%E0%A4%A8%E0%A4%AF%E0%A4%B8.jpg",
        link: "https://archive.org/details/BPKoirala2027BSSumnima"
    },
    {
        title: "Hitler ra Yehudi | हिटलर र यहूदी (उपन्यास)",
        date: "1958",
        img: "https://pustakalaya.org/media/uploads/thumbnails/document/2020/09/30/%E0%A4%B9%E0%A4%9F%E0%A4%B2%E0%A4%B0_%E0%A4%B0_%E0%A4%AF%E0%A4%B9%E0%A4%A6_%E0%A4%89%E0%A4%AA%E0%A4%A8%E0%A4%AF%E0%A4%B8.jpg",
        link: "https://pustakalaya.org/media/uploads/documents/2020/09/30/_163d670c/BiseshworP.Koirala2049BS_HitlerRaYahudi.pdf"
    },
    {
        title: "विश्‍वेश्‍वरप्रसाद कोइरालाको आत्मवृत्तान्त [Audio Book]",
        date: "2010",
        img: "https://pustakalaya.org/media/uploads/thumbnails/audio/2018/04/20/BPkoiralaAutobiography.jpg",
        link: "https://pustakalaya.org/audios/detail/a7a73ff7-c740-4f85-b837-aea42b2fa0ac"
    }
];



class BPBookCollection extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            windowWidth: window.innerWidth, 
            searchTerms: '',
            isLoading: false,
            error: null
        };
    }
	
	onChange = (e) => {
		this.setState({ [e.target.name]: e.target.value });
	}
	
	onKeyUp(e){
		if (e.charCode === 13) {
			window.location.href = '/search?q=' + e.target.value;
		  }
	}

    handleResize = (e) => {
        this.setState({ windowWidth: window.innerWidth });
    }

    componentDidMount() {
        window.addEventListener("resize", this.handleResize);
    }

    componentWillUnmount() {
        window.addEventListener("resize", this.handleResize);
    }

    render() {
        const { windowWidth, searchTerms, isLoading, error } = this.state;

        return (
            <div className="application">
                <Helmet>
                    <meta charset="utf-8" />
                    <title>Books by B.P. Koirala [Bishweshwar Prasad Koirala] | Archive Nepal</title>
                    <link rel="canonical" href="https://www.archivenepal.org/BPBookCollection" />
                    <meta name="description" content="Archive Nepal's digital collection of books written by B.P. Koirala (Bishweshwar Prasad Koirala). This collection includes 8 books [Teen Ghumti, Swet Bhairavi, Doshi Chasma, Narendra Dai, Babu, Aama ra Chora, Sumnima, Hitler ra Yehudi and Modiain] and 2 audio books [B P's autobiography and Babu, Aama ra Chora] all freely available." />
                </Helmet>
         
            <div id="page-wrapper">
                
                {windowWidth >= 1285 ? <div className="input-group col-md-4 childSearchBox" style={{float: 'right', marginTop: '65px'}}>
					<input type="text" className="form-control input-lg" name="searchTerms" placeholder="Discover Nepal through its history" value={searchTerms} onChange={this.onChange} onKeyPress={this.onKeyUp} style={{padding: "5px"}} />	
						<Link to={'/search?q=' + this.state.searchTerms} className="searchButton" ><FaSearch /></Link>					
					</div>  :
                    <div className="input-group col-md-4 childSearchBox" style={{float: 'right', marginTop: '65px'}}>
					<input type="text" className="form-control input-md" name="searchTerms"  placeholder="Discover Nepal through its history" value={searchTerms} onChange={this.onChange} onKeyPress={this.onKeyUp} />	
						<Link to={'/search?q=' + this.state.searchTerms} className="searchButton" ><FaSearch /></Link>					
                    </div> 
                    }
                <section id="main" className="container">
                    <h2>Books by B.P. Koirala [Bishweshwar Prasad Koirala]</h2>
                    <section className="box special features">
                        <div className="features-row">
                            <div style={{textAlign: 'left'}}>
                                <p>Bishweshwar Prasad Koirala (Nepali: विश्वेश्वरप्रसाद कोइराला; 8 September 1914 – 21 July 1982), better known as B. P. Koirala (Nepali: बीपी कोइराला), was a Nepali revolutionary, political leader and writer. He was the Prime Minister of Nepal from 1959 to 1960. He led the Nepali Congress, a social democratic political party and was the first democratically elected and 22nd Prime Minister of Nepal. </p>
                                <p>While Koirala is considered one of the most charismatic political leader of Nepal, he was also one of the most well-read and thoughtful writers of Nepalese literature. He wrote short stories and novels, and some poems. Koirala began writing short stories in Hindi. His first stories were published in Banaras in ‘'Hansa, a Hindi literary magazine edited by Prem Chand (India's Tolstoy). His first Nepali short story "Chandrabadan" was published in Sharada, a Nepali literary magazine in 1935. Koirala was very good at depicting the character and mind of women. Four other stories of Koirala were included in Katha Kusum (an anthology of Nepali stories), published in 1938 in Darjeeling. As a social realist, with good psychological insight as he was first writer to write stories and novels based on human psychology in history of Nepalese literature, Koirala had established himself as one of the most important Nepali short story writers by 1938. Doshi Chashma [Guilty Glasses], Koirala's anthology of sixteen short stories, was published in 1949. He was one of the greatest writer to contribute to the Nepali literature. <Button size="small" href='https://en.wikipedia.org/wiki/B._P._Koirala'>Read More</Button></p>

                            </div>
                            <div id="masonry">

                                {windowWidth >= 1285 ? <Box>
                                    <ImageList variant="masonry" cols={3} gap={8}>
                                        {itemData.map((item) => (
                                            <a href={item.link} target='_blank'>
                                            <ImageListItem key={item.img}>
                                                <img
                                                    src={`${item.img}?w=248&fit=crop&auto=format`}
                                                    srcSet={`${item.img}?w=248&fit=crop&auto=format&dpr=2 2x`}
                                                    alt={item.title}
                                                    loading="lazy"
                                                />
                                                    <ImageListItemBar position="below" title={item.title} subtitle={<span>Date: {item.date}</span>} style={{ width: '300px' }} />
                                            </ImageListItem>
                                            </a>
                                        ))}
                                    </ImageList>
                                </Box> :
                                    <Box>
                                        <ImageList variant="masonry" cols={1} gap={1}>
                                            {itemData.map((item) => (
                                                <ImageListItem key={item.img}>
                                                    <img
                                                        src={`${item.img}?w=248&fit=crop&auto=format`}
                                                        srcSet={`${item.img}?w=248&fit=crop&auto=format&dpr=2 2x`}
                                                        alt={item.title}
                                                        loading="lazy"
                                                    />
                                                    <a href={item.link} target='_blank'>
                                                        <ImageListItemBar position="below" title={item.title} subtitle={<span>Date: {item.date}</span>} style={{ width: '300px' }} />
                                                    </a>
                                                </ImageListItem>
                                            ))}
                                        </ImageList>
                                    </Box>
                                }

                            </div>
                        </div>
                    </section>
                </section>
                </div> 
            </div>
        )
    }
}
export default BPBookCollection;