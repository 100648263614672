import React, { useState } from "react";
import DemoResult from "./demoResult";
import AppSearchAPIConnector from "@elastic/search-ui-app-search-connector";
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { Helmet } from 'react-helmet';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import envConfig from '../../config/env.json';
import AddUser from '../addUserModal';

import {
  Facet,
  SearchProvider,
  SearchBox,
  Results,
  PagingInfo,
  ResultsPerPage,
  Paging,
  Sorting,
  WithSearch
} from "@elastic/react-search-ui";
import {
  Layout,
  SingleLinksFacet
} from "@elastic/react-search-ui-views";
//import "@elastic/react-search-ui-views/lib/styles/styles.css" test;

import '../../styles/styles.css';

import {
  buildAutocompleteQueryConfig,
  buildFacetConfigFromConfig,
  buildSearchOptionsFromConfig,
  buildSortOptionsFromConfig,
  getConfig,
  getFacetFields
} from "../../config/demo-config-helper";

const { hostIdentifier, searchKey, endpointBase, engineName } = getConfig();
const connector = new AppSearchAPIConnector({
  searchKey,
  engineName,
  hostIdentifier,
  endpointBase
});
const config = {
  searchQuery: {
    facets: buildFacetConfigFromConfig(),
    ...buildSearchOptionsFromConfig()
  },
  autocompleteQuery: buildAutocompleteQueryConfig(),
  apiConnector: connector,
  alwaysSearchOnInitialLoad: true
};

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const theme = createTheme({
  palette:{
    nepalBlue: {
      main: '#0e4c8f',
      contrastText: '#fff',
    },
  },
});

function Expired(){
  alert('Your session has expired. Please sign in again.');
  localStorage.removeItem('user');
  localStorage.removeItem('key');
  localStorage.removeItem('archive');
  localStorage.removeItem('code');
  localStorage.removeItem('access');
  window.location.replace(envConfig.REACT_ENV + 'mrms');
}

export default function App() {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const now = new Date();
  const user = localStorage.getItem('user');
  const itemStr = localStorage.getItem('key');
  const archive = localStorage.getItem('archive');
  const code = localStorage.getItem('code');
  const access = localStorage.getItem('access');
  if (!itemStr) {
    window.location.replace(envConfig.REACT_ENV + 'mrms');
  }
  const item = JSON.parse(itemStr);

  function LogOut(){
    localStorage.removeItem('user');
    localStorage.removeItem('key');
    localStorage.removeItem('archive');
    localStorage.removeItem('code');
    localStorage.removeItem('access');
    window.location.replace(envConfig.REACT_ENV + 'mrms');
  }

  return (
    <div className="application">
                <Helmet>
                    <meta charset="utf-8" />
                    <title>Search results</title>
                    <link rel="canonical" href="https://www.archivenepal.org/mrms" />
                    <meta name="description" content="Manaslu Records Management System" />
                </Helmet>
    {
      (now.getTime() < item.expiry) ? 
    <SearchProvider config={config}>
      <WithSearch mapContextToProps={({ isLoading }) => ({ isLoading })}>
        {({ isLoading }) => (
          <div>
          <header id="header" className="alt" style={{position: 'fixed'}}>
          <h1><img src="/images/archiveNepal.png" style={{ height: '35px', marginTop: '-8px'}} alt="Archive Nepal - Discover Nepal through its History"/></h1>
      </header>
          <div id="page-wrapper">
            <section id="main">
            <h6 style={{float: 'left', marginLeft: '5px'}}>Welcome {user} !</h6>
            <div style={{marginTop: '30px'}}>
              <ThemeProvider theme={theme}>
                <Button color='nepalBlue' variant='contained' size='small' onClick={LogOut} style={{float: 'right', marginRight: '10px'}}>Sign out</Button>
              </ThemeProvider>
              {code == '1235813' ? <div><AddUser archive={archive} access={access}/><Button variant="text" style={{float:'right'}}><Link href='https://docs.google.com/forms/d/e/1FAIpQLSfFY2VHWyUExsAobX2dHoqsp8Boi13RwZKpC0DoiGfVB3u_Hg/viewform' target='_blank' style={{textDecoration: 'none'}}>Request to Add</Link></Button></div> : ''}
              {code == '1236122442' ? <div><Button variant="text" style={{float:'right'}}><Link href='https://docs.google.com/forms/d/e/1FAIpQLSfFY2VHWyUExsAobX2dHoqsp8Boi13RwZKpC0DoiGfVB3u_Hg/viewform' target='_blank' style={{textDecoration: 'none', fontWeight: 'normal'}}>Request to Add</Link></Button></div> : ''}
            </div>
            <div style={{ height: '95px', width: '270px', marginTop: '-8px', display: 'block', marginLeft: 'auto', marginRight: 'auto', marginBottom: '20px'}}>
              <img src="/images/manaslu.png" style={{ height: '75px'}} alt="Manaslu Record Management System"/>
              <h1 style={{textAlign: 'center'}}>{archive}</h1>  
            </div>
              {isLoading && <span id='loader'><img src="/images/loader.gif" id='loader' /></span>}
              {!isLoading && (
                <Layout
                  header={<SearchBox autocompleteSuggestions={true} />}
                  sideContent={
                    <div>
                      {WithSearch && (
                        <Sorting
                          label={"Sort by"}
                          sortOptions={buildSortOptionsFromConfig()}
                        />
                      )}
                      {getFacetFields().map(field => (
                        <Facet key={field} field={field} label={field} view={SingleLinksFacet} show={30} />
                      ))}
                    </div>
                  }
                  bodyContent={
                    <div>
                      <div className="miniFilter">
                        <Button onClick={handleOpen} variant='outlined' style={{width: '100%'}}>Show Filters</Button>
                        <Modal
                          open={open}
                          onClose={handleClose}
                          aria-labelledby="modal-modal-title"
                          aria-describedby="modal-modal-description"
                        >
                          <Box sx={style} style={{ width: '350px', height: '500px', overflow: 'auto' }}>
                            <Typography id="modal-modal-title" variant="h6" component="h2">Filter By:</Typography>
                            <Typography id="modal-modal-description" sx={{ mt: 2 }}>

                            {WithSearch && (
                        <Sorting
                          label={"Sort by"}
                          sortOptions={buildSortOptionsFromConfig()}
                        />
                      )}
                              {getFacetFields().map(field => (
                                <Facet key={field} field={field} label={field} view={SingleLinksFacet} show={30} />
                              ))}          </Typography>
                          </Box>
                        </Modal>
                      </div>
                      <Results
                        titleField={getConfig().titleField}
                        urlField={getConfig().objectField}
                        thumbnailField={getConfig().objectField}
                        resultView={DemoResult}
                      />
                    </div>
                  }
                  bodyHeader={
                    <React.Fragment>
                      {WithSearch && <PagingInfo />}
                      {WithSearch && <ResultsPerPage />}
                    </React.Fragment>
                  }
                  bodyFooter={<Paging />}
                />

              )}
            </section>
          </div>
          </div>
        )}
      </WithSearch>
    </SearchProvider> : Expired() }
</div>
  );
}
