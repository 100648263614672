import React, { Component } from 'react';
import '../styles/main.css';
import { Link } from 'react-router-dom';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import { FaSearch } from 'react-icons/fa';
import { Helmet } from 'react-helmet';
import ImageModal2 from '../imageModal2';
import VideoModal2 from '../videoModal';

const itemData = [
    {
    img: "https://archivenepal.s3.amazonaws.com/images/pbr1.jpg",
    link: "https://archivenepal.s3.amazonaws.com/images/pbr1.jpg",
    date: "2024",
    title: "Mr. Uday Aaley introducing the Advanced Level Kusunda language class to Kusunda students",
    type: "image" 
    },
{
 img: "https://archivenepal.s3.amazonaws.com/images/pbr2.jpg",
 link: "https://archivenepal.s3.amazonaws.com/images/pbr2.jpg",
 date: "2024",
 title: "The Kusunda students",
 type: "image" 
},
{
 img: "https://archivenepal.s3.amazonaws.com/images/pbr3.jpg",
 link: "https://archivenepal.s3.amazonaws.com/images/pbr3.jpg",
 date: "2024", 
 title: "The Kusunda students with Mr. Uday Aaley",
 type: "image" 
},
{
 img: "https://archivenepal.s3.amazonaws.com/images/pbr4.jpg",
 link: "https://archivenepal.s3.amazonaws.com/images/pbr4.jpg",
 date: "2024", 
 title: "The Kusunda students compiling Kusunda words after field study",
 type: "image" 
}
]; 

class ProjectBanRaja extends Component {
    constructor(props) {
        super(props);
        this.state = {
            windowWidth: window.innerWidth,
            searchTerms: '',
            isLoading: false,
            error: null
        };
    }

    onChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    }

    onKeyUp(e) {
        if (e.charCode === 13) {
            window.location.href = '/search?q=' + e.target.value;
        }
    }

    handleResize = (e) => {
        this.setState({ windowWidth: window.innerWidth });
    }

    componentDidMount() {
        window.addEventListener("resize", this.handleResize);
    }

    componentWillUnmount() {
        window.addEventListener("resize", this.handleResize);
    }

    render() {
        const { windowWidth, searchTerms, isLoading, error } = this.state;

        return (
            <div className="application">
               <Helmet>
                    <meta charset="utf-8" />
                    <title>Safeguarding the Kusunda (The Ban Rajas) Language and Culture for Future Generations | Archive Nepal</title>
                    <link rel="canonical" href="https://www.archivenepal.org/projectbanraja" />
                    <meta name="description" content="Safeguarding the Kusunda (The Ban Rajas) Language and Culture for Future Generations." />
                </Helmet>

                <div id="page-wrapper" >

                    {windowWidth >= 1265 ? <div className="input-group col-md-4 childSearchBox" style={{ float: 'right', marginTop: '65px' }}>
                        <input type="text" className="form-control input-lg" name="searchTerms" placeholder="Discover Nepal through its history" value={searchTerms} onChange={this.onChange} onKeyPress={this.onKeyUp} style={{ padding: "5px" }} />
                        <Link to={'/search?q=' + this.state.searchTerms} className="searchButton" ><FaSearch /></Link>
                    </div> :
                        <div className="input-group col-md-4 childSearchBox" style={{ float: 'right', marginTop: '65px' }}>
                            <input type="text" className="form-control input-md" name="searchTerms" placeholder="Discover Nepal through its history" value={searchTerms} onChange={this.onChange} onKeyPress={this.onKeyUp} />
                            <Link to={'/search?q=' + this.state.searchTerms} className="searchButton" ><FaSearch /></Link>
                        </div>
                    }
                    <section id="main" className="container" >
                        <h2>Safeguarding the Kusunda (The Ban Rajas) Language and Culture for Future Generations</h2>
                        <img src='https://archivenepal.s3.amazonaws.com/images/projectbanrajared.png' style={{ display:'block', marginLeft: 'auto', marginRight: 'auto', width: '50%', marginBottom: '20px'}}/>

                        <section className="box special features">
                        <div className="features-row">
                        <div style={{ textAlign: 'left' }}>

                            <p style={{fontWeight: 'bold'}}>May 23, 2024: Introduction to the project</p>
                            <p>We are delighted to announce that we are working with the <a href='https://cultural-protection-fund.britishcouncil.org/' target='_blank'>Cultural Protection Fund</a> for ‘Safeguarding the Kusunda (The Ban Rajas) Language and Culture for Future Generations’. The Cultural Protection Fund was founded in 2016 and is led by the British Council in partnership with the Department for Culture, Media and Sport. <Button size="small" href='https://cultural-protection-fund.britishcouncil.org/projects/kusunda-ban-rajas-language-and-culture' target='_blank'>British Council - Project Announcement</Button></p>
                            <img src='https://archivenepal.s3.amazonaws.com/images/CPF_Header2.png' style={{ display:'block', marginLeft: 'auto', marginRight: 'auto', width: '50%'}}/>

                            <h3>Who are the Kusundas, the Ban Rajas?</h3>
                            <p>Kusunda people, also recognized as Mjehaq/Gemjehaq (The
King of the Forests) are originally an Indigenous ethnic group engaged in
hunter-gatherer traditions in mid-western Nepal. As a semi-nomadic ethnic group, the Kusunda people's unique way of life involved hunting while engaging in trade in nearby towns. However, the population growth, fragmentation of jungles due to farming and especially the government's decision to nationalize extensive forest areas in 1950s, further disrupted their semi-nomadic existence. Forced to abandon their traditional lifestyle, the Kusunda people turned to jobs in laboring and
agriculture for survival. The group's low population and the dispersal of its
members led to intermarriage with neighboring ethnic groups, resulting in a
significant decline in the use of their native language. For the Kusunda
people, losing their language means losing a link to their past, and to their
identity. So the preservation of this cultural heritage is essential for the
local Kusunda population and provides a profound social and community
value. 
</p>
<div>
    <img src='https://archivenepal.s3.amazonaws.com/images/kusunda.PNG' style={{ display:'block', marginLeft: 'auto', marginRight: 'auto', height: '50%'}}/>
    <p style={{ textAlign: 'center' }}>Kusunda 1975 #1b Demonstrating  shooting bow, Johan Reinhard Collection via Archive Nepal</p>                            
</div>

<p>Also from a linguistic perspective, the Kusunda language stands out as
truly unique. According to linguists it is unrelated to any other language
globally, presenting an intriguing mystery regarding its origin. Unusual
characteristics, such as the absence of a standard way to negate a
sentence, the lack of words for 'Yes' or 'No,' and the absence of words for
direction, contributing to its enigmatic nature. Moreover, Kusunda lacks the
rigid grammatical rules commonly found in most languages. Phrases must
be interpreted relative to the speaker, making the study of Kusunda
language a captivating pursuit for linguists and provides a value for natural
and scientific interest.
</p>
<p>Currently, with fewer than 200 members residing in Dang, Pyuthan, and
other districts in Lumbini Province, the Kusunda community has undergone
a significant shift, exclusively adopting Nepali and forsaking their native
language. The passing of Gyani Maiya Sen in 2020, a fluent Kusunda
speaker, has intensified the immediate threat of the Kusunda language
facing extinction. Kamala Khatri, Gyani Maiya’s sister, stands as the sole
remaining fluent speaker, adding urgency to the need for preservation
Efforts.
</p>
<p>Moreover, over time the oral traditions like stories, songs and poems have
been forgotten. The oral traditions are vital for passing on knowledge and
collective memory, playing a crucial role in keeping cultures vibrant and
alive. The absence of original documents in Kusunda has pushed it to the
brink of critical endangerment.
</p>
<h3>What our project aims to do</h3>
<p>Our project aims to preserve, promote, and revitalize the Kusunda
language while empowering the community to safeguard its cultural
Heritage with the following objectives. Collaborating with Mr. Uday Raj Aaley, a dedicated researcher, our multifaceted project aims to preserve and promote the endangered Kusunda
language. From 2019 to March 2023 with the support of the Language
Commission of Nepal, Mr. Aaley conducted 270 hours of basic language
training to 20 students (ages 10-22) in Dang district. We plan to build on
the foundation laid by him and his initial work on preserving the Kusunda
Language with the following project activities. 
</p>
<ol>
    <li><span style={{fontWeight: 'bold'}}>In person Advanced Level Course</span>: Selected students will undergo a 3-month advanced- level, in-person training program in Mahendra Secondary School at Dang with the goal to 
        <ol>
            <li>Cultivate creative writing skills, enabling participants to contribute to the literature of the Kusunda language and inspire younger generations.</li>
            <li>Graduates of the program will be equipped with the skills to become basic-level language instructors empowering them to play an active role in teaching and preserving the Kusunda language within their community.</li>
        </ol>
    </li>
    <li><span style={{fontWeight: 'bold'}}>Publication of Kusunda Language Literature Book</span>: We will publish the compilation of their creative writings which will be the first time such a book of Kusunda Literature will be published in the Kusunda language.</li>
    <li><span style={{fontWeight: 'bold'}}>Book Republishing</span>: As part of our initiative, participants will
contribute to the translation of 10 children's books from Nepali into the
Kusunda language. This process not only enhances language skills but also
promotes cultural understanding and appreciation among younger
Generations.
</li>
<li><span style={{fontWeight: 'bold'}}>11 Audiobook Recording</span>: Leveraging their knowledge of Kusunda phonics,
participants will contribute to our goal of creating 11 audiobooks ((10 for
republished children's book and 1 for the Kusunda Literature Book).
) which will
serve as a valuable resource for better understanding and learning the
nuances of the language.
</li>
<li><span style={{fontWeight: 'bold'}}>‘Learn at your own pace’ Online Language Course</span>:
We will develop a comprehensive online course. This free program will
consist of instructional videos and
general assessments contents, guiding students from beginner to advanced levels.
This digital initiative ensures that the Kusunda language is documented,
accessible and preserved for future generations.
</li>
<li><span style={{fontWeight: 'bold'}}>Book Launch Event</span>: The book launch event in Kathmandu that will
serve as a platform to bring together stakeholders, raising awareness
about the challenges, progress, and necessary actions to safeguard the
Kusunda language and culture
</li>
</ol>
<h3>Learn more about the Kusunda</h3>
<div>
    <img src='https://archivenepal.s3.amazonaws.com/images/kusunda1.PNG' style={{ display:'block', marginLeft: 'auto', marginRight: 'auto', height: '50%'}}/>
    <p style={{ textAlign: 'center' }}>Kusunda 1968 #33 Going hunting, Johan Reinhard Collection via Archive Nepal    </p>                            
</div>
<ul>
    <li><a href='https://himalaya.socanth.cam.ac.uk/collections/journals/contributions/pdf/CNAS_04_01_01.pdf' target='_blank'>The Ban Rajas - A Vanishing Himlayan Tribe by Johan Reinhard</a></li>
    <li><a href='https://www.pnas.org/doi/10.1073/pnas.0400233101' target='_blank'>Kusunda: An Indo-Pacific language in Nepal</a></li>
    <li><a href='https://www.bbc.com/future/article/20220804-kusunda-the-language-isolate-with-no-word-for-no' target='_blank'>The language that doesn't use 'no'</a></li>
    <li><a href='https://archive.org/details/gyani-maiya' target='_blank'>Gyani Maiya (2019 documentary)</a></li>
    <li><Button size="large" href='https://www.archivenepal.org/search?q=kusunda&size=n_20_n&filters%5B0%5D%5Bfield%5D=sub_collection&filters%5B0%5D%5Bvalues%5D%5B0%5D=Kusunda&filters%5B0%5D%5Btype%5D=all' target='_blank' style={{ textAlign: 'center'}}>BROWSE THE KUSUNDA COLLECTIONS IN ARCHIVE NEPAL</Button></li>
</ul>
<p>Follow us on social media with <span style={{fontWeight: 'bold'}}>#CulturalProtectionFund #SafeguardingKusundaLangauge #ProjectBanRaja </span>for more updates on the project.</p>
</div>

                            </div>
                        </section>
                    </section>

                    <section id="main" className="container" style={{marginTop: '-150px'}} >
                    <section className="box special features">
                    <p style={{fontWeight: 'bold', textAlign: 'left'}}>July 24, 2024: Updates from the field</p>
                    <h3>Pictures from the Project</h3>
                    <p style={{textAlign: 'left'}}>While we were officially planning to kick start our project on June 17th, our instructor Mr. Uday Aaley met with an unexpected accident where he fractured the clavicle bone on his left shoulder. Since the doctors advised him to rest for couple of weeks, we had to adjust our project dates and logistics accordingly. Luckily his injuries are healing and he was able to start the In person class on July 9th. </p>
                    <p style={{textAlign: 'left'}}>We would like to thank Mr. Aaley for his dedication to the students and hope for his speedy full recovery.</p>
                                <div id="masonry">
                                    {windowWidth >= 1265 ? <Box>
                                        <ImageList variant="masonry" cols={3} gap={20}>
                                            {itemData.map((item) => (
                                                    <ImageListItem key={item.img}>
                                                        {item.type == 'image' ? <ImageModal2 url={item.img} title={item.title} desc={item.description} salestype='none' link={item.link} /> : <VideoModal2 url={item.img} title={item.title} desc={item.description} link={item.link} />}
                                                    </ImageListItem>
                                            ))}
                                        </ImageList>
                                    </Box> :
                                        <Box>
                                            <ImageList variant="masonry" cols={1} gap={1}>
                                                {itemData.map((item) => (
                                                    <ImageListItem key={item.img}>
                                                        {item.type == 'image' ? <ImageModal2 url={item.img} title={item.title} desc={item.description} salestype='none' link={item.link} /> : <VideoModal2 url={item.img} title={item.title} desc={item.description} link={item.link} />}
                                                    </ImageListItem>
                                                ))}
                                            </ImageList>
                                        </Box>
                                    }

                                </div>
                                <p style={{textAlign: 'left'}}>Follow us on social media with <span style={{fontWeight: 'bold'}}>#CulturalProtectionFund #SafeguardingKusundaLangauge #ProjectBanRaja </span>for more updates on the project.</p>
                    </section> 
                    </section>   
                </div>
            </div>
        )
    }
}
export default ProjectBanRaja;
