import React, { Component } from 'react';
import './styles/main.css';
import { Link } from 'react-router-dom';
import Box from '@mui/material/Box';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar';
import {FaSearch} from 'react-icons/fa';
import { Helmet } from 'react-helmet';

const itemData = [
    {
        img: 'https://collectionapi.metmuseum.org/api/collection/v1/iiif/78187/1299669/main-image',
        title: 'Nepali Sculptures',
        link: '/objectCollection'
    },
    {
        img: '/thumbnails/BPBooks.png',
        title: 'Books by B.P. Koirala',
        link: '/BPBookCollection'
    },
    {
        img: '/thumbnails/1923Treaty.png',
        title: 'Historic Treaties and Documents',
        link: '/treatiesCollection',
    },
    {
        img: 'https://collections.mfa.org/internal/media/dispatcher/737112/preview',
        title: 'Faces of Nepal',
        link: '/facesofnepal',
    },
    {
        img: 'https://archivenepal.s3.amazonaws.com/images/lakhe.jpeg',
        title: 'Indra Jatra',
        link: '/indrajatra',
    },
    {
        img: 'https://archivenepal.s3.amazonaws.com/images/thumbnails/electionmanifestos.png',
        title: '2022 (2079 B.S.) Election Manifestos',
        link: '/electionmanifestos2022',
    },
    {
        img: 'https://archivenepal.s3.amazonaws.com/images/thumbnails/electionsinnepal.png',
        title: 'Elections in Nepal',
        link: '/electionsinnepal',
    },
    {
        img: 'https://archivenepal.s3.amazonaws.com/images/thumbnails/dirghamanganeshman.png',
        title: 'Dirghaman & Ganeshman Chitrakar Collection',
        link: '/dirghamanganeshman',
    },
    {
        img: 'https://archivenepal.s3.amazonaws.com/images/thumbnails/pashupatiDG.jpg',
        title: 'Aerial views of Nepal',
        link: '/aerialviews',
    }
];

class DigitalCollections extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            windowWidth: window.innerWidth, 
            searchTerms: '',
            isLoading: false,
            error: null
        };
    }
	
	onChange = (e) => {
		this.setState({ [e.target.name]: e.target.value });
	}
	
	onKeyUp(e){
		if (e.charCode === 13) {
			window.location.href = '/search?q=' + e.target.value;
		  }
	}

    handleResize = (e) => {
        this.setState({ windowWidth: window.innerWidth });
    }

    componentDidMount() {
        window.addEventListener("resize", this.handleResize);
    }

    componentWillUnmount() {
        window.addEventListener("resize", this.handleResize);
    }

    render() {
        const { windowWidth, searchTerms, isLoading, error } = this.state;

        return (
            <div className="application">
                <Helmet>
                    <meta charset="utf-8" />
                    <title>Digital Galleries in Archive Nepal</title>
                    <link rel="canonical" href="https://www.archivenepal.org/digitalCollection" />
                    <meta name="description" content="Digital galleries provide a snapshot of the collections available in Archive Nepal. As we create more digital galleries, you will find them listed on this page to freely access." />
                </Helmet>
         
            <div id="page-wrapper">
                
                {windowWidth >= 1285 ? <div className="input-group col-md-4 childSearchBox" style={{float: 'right', marginTop: '65px'}}>
					<input type="text" className="form-control input-lg" name="searchTerms" placeholder="Discover Nepal through its history" value={searchTerms} onChange={this.onChange} onKeyPress={this.onKeyUp} style={{padding: "5px"}} />	
						<Link to={'/search?q=' + this.state.searchTerms} className="searchButton" ><FaSearch /></Link>					
					</div>  :
                    <div className="input-group col-md-4 childSearchBox" style={{float: 'right', marginTop: '65px'}}>
					<input type="text" className="form-control input-md" name="searchTerms"  placeholder="Discover Nepal through its history" value={searchTerms} onChange={this.onChange} onKeyPress={this.onKeyUp} />	
						<Link to={'/search?q=' + this.state.searchTerms} className="searchButton" ><FaSearch /></Link>					
                    </div> 
                    }
                <section id="main" className="container">
                    <h2>Explore our Digital Galleries</h2>
                    <section className="box special features">
                         
                            <div id="masonry">

                                {windowWidth >= 1285 ? <Box>
                                    <ImageList variant="masonry" cols={2} gap={10}>
                                        {itemData.map((item) => (
                                            <a href={item.link}>
                                            <ImageListItem key={item.img}>
                                                <img
                                                    src={`${item.img}?w=248&fit=crop&auto=format`}
                                                    srcSet={`${item.img}?w=248&fit=crop&auto=format&dpr=2 2x`}
                                                    alt={item.title}
                                                    loading="lazy"
                                                />
                                                    <ImageListItemBar position="below" title={item.title} style={{ width: '400px', textAlign: 'center' }} />
                                            </ImageListItem>
                                            </a>
                                        ))}
                                    </ImageList>
                                </Box> :
                                    <Box>
                                        <ImageList variant="masonry" cols={1} gap={1}>
                                            {itemData.map((item) => (
                                                <ImageListItem key={item.img}>
                                                    <img
                                                        src={`${item.img}?w=248&fit=crop&auto=format`}
                                                        srcSet={`${item.img}?w=248&fit=crop&auto=format&dpr=2 2x`}
                                                        alt={item.title}
                                                        loading="lazy"
                                                    />
                                                    <a href={item.link}>
                                                        <ImageListItemBar position="below" title={item.title} style={{ width: '400px' }} />
                                                    </a>
                                                </ImageListItem>
                                            ))}
                                        </ImageList>
                                    </Box>
                                }

                            </div>
      </section>
                   
                </section>
                </div> 
            </div>
        )
    }
}
export default DigitalCollections;
