import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";

export default function RedirectLink() {
    const [data, setData] = useState([]);
    let params  = useParams();
    const fetchURL = "https://z5l61hqx58.execute-api.us-east-1.amazonaws.com/v1/getUrl/?alias=" + params.aliasId;
      
    useEffect(() => {
      fetch(fetchURL)
        .then((res) => res.json())
        .then((data) => {
          setData(data.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }, []);

   return (
    params.aliasId == 'donate'? window.location.replace('https://www.gofundme.com/f/donation-campaign-for-archive-nepal?utm_source=customer&utm_medium=copy_link&utm_campaign=p_cp_guide_do&member=18161021'): data.map((item) => ( window.location.replace(item.Item.org)))
    );
}
