import React, { Component } from 'react';
import Button from '@mui/material/Button';
import '../styles/main.css';
import { Link } from 'react-router-dom';
import Box from '@mui/material/Box';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar';
import {FaSearch} from 'react-icons/fa';
import { Helmet } from 'react-helmet';

const itemData = [
    {
        img: '/thumbnails/1815SugauliTreaty.png',
        title: 'Treaty of Sugauli between the East India Company and Nepal.',
        date: 'December 2, 1815',
        link: 'https://archivenepal.s3.amazonaws.com/documents/SugauliTreatyHandWrittenCopy.pdf'
    },
    {
        img: '/images/defaultImage.png',
        title: 'Admission of Nepal to UN during 555th plenary meeting',
        date: 'December 14, 1955',
        link: 'https://digitallibrary.un.org/record/646501?ln=en'
    },
    {
        img: '/thumbnails/1923Treaty.png',
        title: 'Treaty between the United Kingdom and Nepal together with Note respecting the importation of arms and ammunition into Nepal. Signed at Katmandu, December 21 1923.',
        date: 'December 21, 1923',
        link: 'https://archivenepal.s3.amazonaws.com/documents/1924_UK_and_Nepal_treaty.pdf'
    },
    {
        img: '/thumbnails/1950Treaty.png',
        title: 'Treaty of Peace and Friendship between the government of India and the government of Nepal',
        date: 'July 31, 1950',
        link: 'https://mea.gov.in/bilateral-documents.htm?dtl/6295/Treaty+of+Peace+and+Friendship'
    },
    {
        img: '/images/defaultImage.png',
        title: 'Application for Membership to UN',
        date: 'March 25, 1949',
        link: 'https://digitallibrary.un.org/record/1327846?ln=en'
    },
    {
        img: '/images/defaultImage.png',
        title: 'Letter from Nepal to UN',
        date: 'August 18, 1949',
        link: 'https://digitallibrary.un.org/record/1482263?ln=en'
    }
];



class Treaties extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            windowWidth: window.innerWidth, 
            searchTerms: '',
            isLoading: false,
            error: null
        };
    }
	
	onChange = (e) => {
		this.setState({ [e.target.name]: e.target.value });
	}
	
	onKeyUp(e){
		if (e.charCode === 13) {
			window.location.href = '/search?q=' + e.target.value;
		  }
	}

    handleResize = (e) => {
        this.setState({ windowWidth: window.innerWidth });
    }

    componentDidMount() {
        window.addEventListener("resize", this.handleResize);
    }

    componentWillUnmount() {
        window.addEventListener("resize", this.handleResize);
    }

    render() {
        const { windowWidth, searchTerms, isLoading, error } = this.state;

        return (
            <div className="application">
                <Helmet>
                    <meta charset="utf-8" />
                    <title>Historic Treaties and Documents</title>
                    <link rel="canonical" href="https://www.archivenepal.org/treatiesCollection" />
                    <meta name="description" content="Archive Nepal's digital collection about historic treaties and documents that have shaped Nepal. This digital collection includes: The 1815 Treaty of Sugauli between the East India Company and the Kingdom of Nepal, The 1923 Treaty between the United Kingdom and the Kingdom of Nepal, Nepal's 1949 application for the membership to United Nations and The 1950 Treaty of Peace and Friendship between the Government of India and the Government of Nepal. " />
                </Helmet>
            <div id="page-wrapper">
               {windowWidth >= 1285 ? <div className="input-group col-md-4 childSearchBox" style={{float: 'right', marginTop: '65px'}}>
					<input type="text" className="form-control input-lg" name="searchTerms" placeholder="Discover Nepal through its history" value={searchTerms} onChange={this.onChange} onKeyPress={this.onKeyUp} style={{padding: "5px"}} />	
						<Link to={'/search?q=' + this.state.searchTerms} className="searchButton" ><FaSearch /></Link>					
					</div>  :
                    <div className="input-group col-md-4 childSearchBox" style={{float: 'right', marginTop: '65px'}}>
					<input type="text" className="form-control input-md" name="searchTerms"  placeholder="Discover Nepal through its history" value={searchTerms} onChange={this.onChange} onKeyPress={this.onKeyUp} />	
						<Link to={'/search?q=' + this.state.searchTerms} className="searchButton" ><FaSearch /></Link>					
                    </div> 
                    }
                <section id="main" className="container">
                    <h2>Historic Treaties and Documents</h2>
                    <section className="box special features">
                        <div className="features-row">
                           
                            <div id="masonry">

                                {windowWidth >= 1285 ? <Box>
                                    <ImageList variant="masonry" cols={3} gap={8}>
                                        {itemData.map((item) => (
                                            <a href={item.link} target='_blank'>  
                                                <ImageListItem key={item.img}>
                                                    <img
                                                        src={`${item.img}?w=248&fit=crop&auto=format`}
                                                        srcSet={`${item.img}?w=248&fit=crop&auto=format&dpr=2 2x`}
                                                        alt={item.title}
                                                        loading="lazy"
                                                    />
                                                    <ImageListItemBar position="below" title={item.title} subtitle={<span>Date: {item.date}</span>} style={{ width: '300px' }} />
                                                </ImageListItem>
                                            </a>
                                        ))}
                                    </ImageList>
                                </Box> :
                                    <Box>
                                        <ImageList variant="masonry" cols={1} gap={1}>
                                            {itemData.map((item) => (
                                                <ImageListItem key={item.img}>
                                                    <img
                                                        src={`${item.img}?w=248&fit=crop&auto=format`}
                                                        srcSet={`${item.img}?w=248&fit=crop&auto=format&dpr=2 2x`}
                                                        alt={item.title}
                                                        loading="lazy"
                                                    />
                                                    <a href={item.link} target='_blank'>
                                                        <ImageListItemBar position="below" title={item.title} subtitle={<span>Date: {item.date}</span>} style={{ width: '300px' }} />
                                                    </a>
                                                </ImageListItem>
                                            ))}
                                        </ImageList>
                                    </Box>
                                }

                            </div>
                        </div>
                        <Button size="medium" href='/search?size=n_20_n&filters%5B0%5D%5Bfield%5D=type&filters%5B0%5D%5Bvalues%5D%5B0%5D=document&filters%5B0%5D%5Btype%5D=all'>Browse More</Button>
                    </section>
                    <div>
                        <p style={{ fontSize: '12px' }}>* Wikipedia contributors. (2021, July 4). Nepalese sculpture. In Wikipedia, The Free Encyclopedia. Retrieved 20:57, February 1, 2022, from https://en.wikipedia.org/w/index.php?title=Nepalese_sculpture&oldid=1031902431</p>
                    </div>
                </section>
                </div>
            </div>
        )
    }
}
export default Treaties;