import React, { Component } from 'react';
import '../styles/main.css';
import Button from '@mui/material/Button';
import { Link } from 'react-router-dom';
import { FaSearch } from 'react-icons/fa';
import { Helmet } from 'react-helmet';

class UNMembership extends Component {
    constructor(props) {
        super(props);
        this.state = {
            windowWidth: window.innerWidth,
            searchTerms: '',
            isLoading: false,
            error: null
        };
    }

    onChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    }

    onKeyUp(e) {
        if (e.charCode === 13) {
            window.location.href = '/search?q=' + e.target.value;
        }
    }

    handleResize = (e) => {
        this.setState({ windowWidth: window.innerWidth });
    }

    componentDidMount() {
        window.addEventListener("resize", this.handleResize);
    }

    componentWillUnmount() {
        window.addEventListener("resize", this.handleResize);
    }

    render() {
        const { windowWidth, searchTerms } = this.state;

        return (
            <div className="application">
                <Helmet>
                    <meta charset="utf-8" />
                    <title>Timeline of the U.N. membership of Nepal | Archive Nepal</title>
                    <link rel="canonical" href="https://www.archivenepal.org/unmembership" />
                    <meta name="description" content="Timeline and historic documents related to the U.N. membership of Nepal. Nepal applied for the membership to the United Nations in 1949 but was only approved in 1955." />
                </Helmet>

                <div id="page-wrapper">

                    {windowWidth >= 1285 ? <div className="input-group col-md-4 childSearchBox" style={{ float: 'right', marginTop: '65px' }}>
                        <input type="text" className="form-control input-lg" name="searchTerms" placeholder="Discover Nepal through its history" value={searchTerms} onChange={this.onChange} onKeyPress={this.onKeyUp} style={{ padding: "5px" }} />
                        <Link to={'/search?q=' + this.state.searchTerms} className="searchButton" ><FaSearch /></Link>
                    </div> :
                        <div className="input-group col-md-4 childSearchBox" style={{ float: 'right', marginTop: '65px' }}>
                            <input type="text" className="form-control input-md" name="searchTerms" placeholder="Discover Nepal through its history" value={searchTerms} onChange={this.onChange} onKeyPress={this.onKeyUp} />
                            <Link to={'/search?q=' + this.state.searchTerms} className="searchButton" ><FaSearch /></Link>
                        </div>
                    }
                    <section id="main" className="container">
                        <h2>Timeline of the U.N. membership of Nepal</h2>
                        <section className="box special features">
                            <div className="features-row">
                                <div style={{ textAlign: 'left' }}>
                                        <p>Nepal's journey as a United Nations (UN) member spans six decades, culminating in its admission on December 14, 1955, marking 68 years of membership. In 1949, Major-General Bijaya Shamsher applied for UN membership, emphasizing Nepal's sovereignty in South Asia and citing diplomatic ties with key nations, including the United Kingdom, the United States, France, and India. Notably, Nepal's oldest diplomatic relationship dates back to 1816 with the UK, and it established ties with the US in 1947, India in 1947, and France in 1949. Despite setbacks, including a Soviet veto on September 7, 1949, reported by The New York Times, Nepal persevered.</p>
                                        <p>Undeterred, Nepal continued its pursuit, achieving UN membership in 1955 alongside Sri Lanka. This significant moment saw the two South Asian nations join India (1945), Afghanistan (1946), and Pakistan (1947) as UN members from the region. Notably, 14 other countries, including Austria, Albania, Bulgaria, Cambodia, Finland, Hungary, Ireland, Italy, Jordan, Laos, Libya, Portugal, Romania, and Spain, also joined the UN during this period. In celebration of this significant milestone, let's explore the timeline and relevant historic documents related to the Nepal's application process with the United Nations.</p>
                                </div>
                                <div id="masonry">
                                 <iframe src="https://cdn.knightlab.com/libs/timeline3/latest/embed/index.html?source=1IUFkDEnAXEMo4BGmV2VbzpgAD51i0myyCOYIMXZ859c&font=Default&lang=en&initial_zoom=2" width="100%" height="650px" frameborder="0"></iframe> 
                                </div>
                            </div>
                        </section>
                    </section>
                </div>
            </div>
        )
    }
}
export default UNMembership;