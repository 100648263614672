import React, { Component } from 'react';
import Button from '@mui/material/Button';
import '../styles/main.css';
import { Link } from 'react-router-dom';
import Box from '@mui/material/Box';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar';
import {FaSearch} from 'react-icons/fa';
import { Helmet } from 'react-helmet';

const itemData = [
    {
        img: 'https://www.artic.edu/iiif/2/e174e943-4e1c-befc-28bf-dabb9ad30e7d/full/843,/0/default.jpg',
        title: 'God Vishnu Mount, Garuda, Standing with Hands in Gesture of Adoration (Anjalimudra)',
        date: '11th century or earlier',
        link: 'https://www.artic.edu/artworks/127634/the-sage-agastya-seated-in-meditation-on-his-water-pot-kumbha'
    },
    {
        img: 'https://www.artic.edu/iiif/2/0855f785-2cab-4795-1cfb-71574169a52f/full/843,/0/default.jpg',
        title: 'The Sage Agastya Seated in Meditation on his Water Pot (Kumbha)',
        date: '15th century',
        link: 'https://www.artic.edu/artworks/127634/the-sage-agastya-seated-in-meditation-on-his-water-pot-kumbha',
    },
    {
        img: 'https://collectionapi.metmuseum.org/api/collection/v1/iiif/38330/153630/main-image',
        title: 'SiThe Bodhisattva Manjushri as a Ferocious Destroyer of Ignorancenk',
        date: '10th century',
        link: 'https://www.metmuseum.org/art/collection/search/38330'
    },
    {
        img: 'https://collectionapi.metmuseum.org/api/collection/v1/iiif/78186/209139/main-image',
        title: 'The Goddess Durga Slaying Mahisha',
        date: ' 14th century',
        link: 'https://www.metmuseum.org/art/collection/search/78186',
    },
    {
        img: 'https://collectionapi.metmuseum.org/api/collection/v1/iiif/39397/170914/main-image',
        title: 'Seated Four-Armed Shiva',
        date: ' 12th century',
        link: 'https://www.metmuseum.org/art/collection/search/39397',
    },
    {
        img: 'https://collectionapi.metmuseum.org/api/collection/v1/iiif/75359/184084/main-image',
        title: 'Surya, the Hindu Solar Deity',
        date: '14th century',
        link: 'https://www.metmuseum.org/art/collection/search/75359'
    },
    {
        img: 'https://collection.rubinmuseum.org/internal/media/dispatcher/36872/preview',
        title: 'VISHNU WITH THE KING OF SNAKES, SHESHA',
        date: 'ca. 17th century',
        link: 'https://collection.rubinmuseum.org/objects/181/vishnu-with-the-king-of-snakes-shesha?ctx=02e1c961ccc9e0beac9cf0fbcb7adcc4d3c44407&idx=21'
    },
    {
        img: 'https://collectionapi.metmuseum.org/api/collection/v1/iiif/39117/1695484/main-image',
        title: 'Seated Jambhala',
        date: '10th century',
        link: 'https://www.metmuseum.org/art/collection/search/39117'
    },
    {
        img: 'https://collectionapi.metmuseum.org/api/collection/v1/iiif/38336/170904/main-image',
        title: 'Surya, the Hindu Solar Deity',
        date: '11th–12th century',
        link: 'https://www.metmuseum.org/art/collection/search/38336'
    },
    {
        img: 'https://collectionapi.metmuseum.org/api/collection/v1/iiif/39362/1759531/main-image',
        title: "Indra's Crown",
        date: '14th–15th century',
        link: 'https://www.metmuseum.org/art/collection/search/39362'
    },
    {
        img: 'https://collectionapi.metmuseum.org/api/collection/v1/iiif/78187/1299669/main-image',
        title: 'Head of Bhairava',
        date: '16th century',
        link: 'https://www.metmuseum.org/art/collection/search/78187'
    },
    {
        img: 'https://collectionapi.metmuseum.org/api/collection/v1/iiif/38513/95519/main-image',
        title: 'Temple Bracket with a Devata Standing on a Hermit Sage',
        date: '13th century or later',
        link: 'https://www.metmuseum.org/art/collection/search/38513'
    }
];



class Objects extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            windowWidth: window.innerWidth, 
            searchTerms: '',
            isLoading: false,
            error: null
        };
    }
	
	onChange = (e) => {
		this.setState({ [e.target.name]: e.target.value });
	}
	
	onKeyUp(e){
		if (e.charCode === 13) {
			window.location.href = '/search?q=' + e.target.value;
		  }
	}

    handleResize = (e) => {
        this.setState({ windowWidth: window.innerWidth });
    }

    componentDidMount() {
        window.addEventListener("resize", this.handleResize);
    }

    componentWillUnmount() {
        window.addEventListener("resize", this.handleResize);
    }

    render() {
        const { windowWidth, searchTerms, isLoading, error } = this.state;

        return (
            <div className="application">
                <Helmet>
                    <meta charset="utf-8" />
                    <title>Nepali Sculptures | Archive Nepal</title>
                    <link rel="canonical" href="https://www.archivenepal.org/objectCollection" />
                    <meta name="description" content="Archive Nepal's digital collection about Nepali sculptures that represent Nepal's rich history, culture and traditions." />
                </Helmet>
         
            <div id="page-wrapper">
                
                {windowWidth >= 1285 ? <div className="input-group col-md-4 childSearchBox" style={{float: 'right', marginTop: '65px'}}>
					<input type="text" className="form-control input-lg" name="searchTerms" placeholder="Discover Nepal through its history" value={searchTerms} onChange={this.onChange} onKeyPress={this.onKeyUp} style={{padding: "5px"}} />	
						<Link to={'/search?q=' + this.state.searchTerms} className="searchButton" ><FaSearch /></Link>					
					</div>  :
                    <div className="input-group col-md-4 childSearchBox" style={{float: 'right', marginTop: '65px'}}>
					<input type="text" className="form-control input-md" name="searchTerms"  placeholder="Discover Nepal through its history" value={searchTerms} onChange={this.onChange} onKeyPress={this.onKeyUp} />	
						<Link to={'/search?q=' + this.state.searchTerms} className="searchButton" ><FaSearch /></Link>					
                    </div> 
                    }
                <section id="main" className="container">
                    <h2>Nepali Sculptures</h2>
                    <section className="box special features">
                        <div className="features-row">
                            <div>
                                <p>The sculpture of Nepal is best known for small religious figures and ritual objects in bronze or copper alloy, but also has other strengths. The Newar people of Nepal had a long-lasting specialism in casting small bronze figures, mostly religious and especially Buddhist, considerable numbers of which were exported to India and Tibet over many centuries.</p>
                                <p>Though the most common survivals are small figures, for reasons of climate and history, Nepal also has an unusual quantity of surviving large sculptures in wood and bronze, which have mostly been lost in India, where significant quantities of metal sculpture from temples have only survived in Tamil Nadu and other parts of the far south. Though there are small figures similar to those in bronze, most woodcarving is architectural, including the elaborate Newar windows. <Button size="small" href='https://en.wikipedia.org/wiki/Nepalese_sculpture'>Read More</Button></p>

                            </div>
                            <div id="masonry">

                                {windowWidth >= 1285 ? <Box>
                                    <ImageList variant="masonry" cols={3} gap={8}>
                                        {itemData.map((item) => (
                                            <a href={item.link} target='_blank'>
                                            <ImageListItem key={item.img}>
                                                <img
                                                    src={`${item.img}?w=248&fit=crop&auto=format`}
                                                    srcSet={`${item.img}?w=248&fit=crop&auto=format&dpr=2 2x`}
                                                    alt={item.title}
                                                    loading="lazy"
                                                />
                                                    <ImageListItemBar position="below" title={item.title} subtitle={<span>Date: {item.date}</span>} style={{ width: '300px' }} />
                                            </ImageListItem>
                                            </a>
                                        ))}
                                    </ImageList>
                                </Box> :
                                    <Box>
                                        <ImageList variant="masonry" cols={1} gap={1}>
                                            {itemData.map((item) => (
                                                <ImageListItem key={item.img}>
                                                    <img
                                                        src={`${item.img}?w=248&fit=crop&auto=format`}
                                                        srcSet={`${item.img}?w=248&fit=crop&auto=format&dpr=2 2x`}
                                                        alt={item.title}
                                                        loading="lazy"
                                                    />
                                                    <a href={item.link} target='_blank'>
                                                        <ImageListItemBar position="below" title={item.title} subtitle={<span>Date: {item.date}</span>} style={{ width: '300px' }} />
                                                    </a>
                                                </ImageListItem>
                                            ))}
                                        </ImageList>
                                    </Box>
                                }

                            </div>
                        </div>
                        <Button size="medium" href='/search?q=sculpture&size=n_20_n&filters%5B0%5D%5Bfield%5D=type&filters%5B0%5D%5Bvalues%5D%5B0%5D=object&filters%5B0%5D%5Btype%5D=all'>Browse More</Button>
                    </section>
                    <div>
                        <p style={{ fontSize: '12px' }}>* Wikipedia contributors. (2021, July 4). Nepalese sculpture. In Wikipedia, The Free Encyclopedia. Retrieved 20:57, February 1, 2022, from https://en.wikipedia.org/w/index.php?title=Nepalese_sculpture&oldid=1031902431</p>
                    </div>
                </section>
                </div> 
            </div>
        )
    }
}
export default Objects;