import * as React from 'react';
import 'react-responsive-modal/styles.css';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Modal from 'react-responsive-modal';
import { HiOutlinePhotograph } from 'react-icons/hi';
import './styles/main.css';

export default function VideoModal2({url, link, title, desc, collection}){
  const [open, setOpen] = React.useState(false);
  const theme = createTheme({
    palette:{
      nepalBlue: {
        main: '#0e4c8f',
        contrastText: '#fff',
      },
    },
  });

  return (
    <div>
      <h4 className="sui-result_title sui-result_title-link"  onClick={() => collection == 'British Pathe' || collection == 'Digital Himalaya' ? window.open(link, '_blank') : setOpen(true)} style={{ fontWeight: 'bold', cursor:'pointer'}}><span style={{ marginRight: '5px' }}>< HiOutlinePhotograph size={20} style={{ marginBottom: '5px' }} /></span>{title}</h4>
      <Modal open={open} onClose={() => setOpen(false)} >
        <div class="responsive-container">
            <iframe class="responsive-iframe" src={link} title={title} frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
        </div>
            <h3>{title}</h3>
        <div style={{padding: '10px', textAlign: 'left'}}>{desc}</div>
      </Modal>
    </div>
  );
}
