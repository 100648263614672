import React, { useState } from 'react';
import axios from 'axios';
import Button from '@mui/material/Button';
import { TextareaAutosize } from '@mui/material';

// installed using npm install buffer --save
window.Buffer = window.Buffer || require("buffer").Buffer;

// a React functional component, used to create a simple upload input and button

const Entry = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [title, setTitle] = useState(null);
    const [description, setDescription] = useState(null);
    const [link, setLink] = useState(null);
    

    const addLink = async () => {
        if (title == null){
            alert('Please add a title.')
        }else if (link == null){
            alert('Please add a link.')
        }else{
            setIsLoading(true);
                        axios.post('https://c9ah5rkgh4.execute-api.us-east-1.amazonaws.com/v1/postntdetails',
                        {
                          "key1": `NT`,
                          "key2": Date.parse(Date().toLocaleString()),
                          "key3": `2023`,
                          "key4": `${link}`,
                          "key5": `${title}`,
                          "key6": `${description}`
                        }
                      ).then(() => {
                        setTitle('');
                        setLink('');
                        setIsLoading(false);
                      }).catch(error => {
                        alert('File was uploaded successfully but failed to add the record to the database. Please report at contact@archivenepal.org. Thanks', error);
                        setIsLoading(false);
                      });
                        alert('Link added successfully');
                        window.location.reload(false);
        } 
    }
    return (
        <div id="page-wrapper">
            <section id="main" className="container">
                <section className="box special features">
                { isLoading && <span id='loader'><img src="https://archivenepal.s3.amazonaws.com/images/loader.gif" id='loader' /></span> }
                    <h3>Archive Nepal File Uploader</h3>
                    <label style={{ float: "left" }}>Title [eg. 20 - 26 May 2022]:</label>
                    <input
                        type="text"
                        name="title"
                        onChange={e => setTitle(e.target.value)}
                        value={title}
                        style={{ marginBottom: '25px' }}
                    />
                    <label style={{ float: "left" }}>Description [Optional]:</label>
                    <TextareaAutosize
                        name="description"
                        onChange={e => setDescription(e.target.value)}
                        value={description}
                        style={{ marginBottom: '25px' }}
                    />

                    <label style={{ float: "left" }}>Link:</label>
                    <input
                        type="text"
                        name="link"
                        onChange={e => setLink(e.target.value)}
                        value={link}
                        style={{ marginBottom: '25px' }}
                    />
                    <Button variant="contained" onClick={() => addLink()} disabled={isLoading} > Add Link</Button>
                    <p style={{ marginTop: '40px', fontSize: '15px', textAlign: 'left' }}>For questions or to report an issue please email us at <a href="mailto:contact@archivenepal.org">contact@archivenepal.org</a></p>
                </section>
            </section>
        </div>
    )
}

export default Entry;
