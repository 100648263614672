import React, { Component } from 'react';
import '../styles/main.css';
import Button from '@mui/material/Button';
import { Link } from 'react-router-dom';
import Box from '@mui/material/Box';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar';
import { FaSearch } from 'react-icons/fa';
import { Helmet } from 'react-helmet';

const itemData = 
[
    {
        "img":"https://images.eap.bl.uk/EAP838/EAP838_1_7_3/359.jp2/full/!1200,900/0/default.jpg",
        "link":"https://eap.bl.uk/item/EAP838-1-7-3-359",
        "title":"Dharahara or the Bhimsen Tower was built during the 19th century by Prime Minister Bhimsen Thapa.",
        "date":"1950s-1970s"
     },
     {
        "img":"https://images.eap.bl.uk/EAP838/EAP838_1_7_3/358.jp2/full/!1400,700/0/default.jpg",
        "link":"https://eap.bl.uk/item/EAP838-1-7-3-358",
        "title":"Durbar Marga or the King's way road. Tri Chandra College is on the left and the mosque can be seen on the right",
        "date":"1963 - 70"
     },
     {
        "img":"https://images.eap.bl.uk/EAP838/EAP838_1_7_3/352.jp2/full/!1200,900/0/default.jpg",
        "link":"https://eap.bl.uk/item/EAP838-1-7-3-352",
        "title":"Vendors selling fruits in Kathmandu Durbar Square",
        "date":"1950s-1970s"
     },
     {
        "img":"https://images.eap.bl.uk/EAP838/EAP838_1_2_6/4.jp2/full/!1200,900/0/default.jpg",
        "link":"https://eap.bl.uk/item/EAP838-1-2-6-4",
        "title":"A Newari wedding group photo. In the photograph, is wife of Dirgha Man Chitrakar (8th from left)",
        "date":"1920s"
     },
     {
        "img":"https://images.eap.bl.uk/EAP838/EAP838_1_7_3/368.jp2/full/!1200,900/0/default.jpg",
        "link":"https://eap.bl.uk/item/EAP838-1-7-3-368",
        "title":"Girl Scout walking in New Road",
        "date":"1950s-1970s"
     },
     {
        "img":"https://images.eap.bl.uk/EAP838/EAP838_1_7_3/313.jp2/full/!1200,900/0/default.jpg",
        "link":"https://eap.bl.uk/item/EAP838-1-7-3-313",
        "title":"Constuction of Bishal Bazar, the first shopping center located in New Road, Kathmandu",
        "date":"1969"
     },
     {
        "img":"https://images.eap.bl.uk/EAP838/EAP838_1_7_3/310.jp2/full/!1200,900/0/default.jpg",
        "link":"https://eap.bl.uk/item/EAP838-1-7-3-310",
        "title":"The street looking into New Road. The photo is taken from Indra Chok",
        "date":"1950s-1970s"
     },
     {
        "img":"https://images.eap.bl.uk/EAP838/EAP838_1_7_3/300.jp2/full/!1200,900/0/default.jpg",
        "link":"https://eap.bl.uk/item/EAP838-1-7-3-300",
        "title":"An old monastery in Lumbini, the birthplace of Buddha",
        "date":"1950s-1970s"
     },
     {
        "img":"https://images.eap.bl.uk/EAP838/EAP838_1_7_3/594.jp2/full/!1200,900/0/default.jpg",
        "link":"https://eap.bl.uk/item/EAP838-1-7-3-594",
        "title":"Bhimsensthan in Kathmandu on a rainy day",
        "date":"1950s-1970s"
     },
     {
        "img":"https://images.eap.bl.uk/EAP838/EAP838_1_7_3/230.jp2/full/!1200,900/0/default.jpg",
        "link":"https://eap.bl.uk/item/EAP838-1-7-3-230",
        "title":"Asan Tole in Kathmandu",
        "date":"1950s-1970s"
     },
     {
        "img":"https://images.eap.bl.uk/EAP838/EAP838_1_7_3/294.jp2/full/!1200,900/0/default.jpg",
        "link":"https://eap.bl.uk/item/EAP838-1-7-3-294",
        "title":"Changing fashion. Ganesh Man Chitrakar won an award for this photograph taken during the Matya festival",
        "date":"1950s-1970s"
     },
     {
        "img":"https://images.eap.bl.uk/EAP838/EAP838_1_7_3/283.jp2/full/!1200,900/0/default.jpg",
        "link":"https://eap.bl.uk/item/EAP838-1-7-3-283",
        "title":"A scenic view",
        "date":"1950s-1970s"
     },
     {
        "img":"https://images.eap.bl.uk/EAP838/EAP838_1_1_1/111.jp2/full/!1200,900/0/default.jpg",
        "link":"https://eap.bl.uk/item/EAP838-1-1-1-111",
        "title":"General Kaiser Shamsher (1882-1964), son of Prime Minister Chandra Shamsher",
        "date":"1910s-1940s"
     },
     {
        "img":"https://images.eap.bl.uk/EAP838/EAP838_1_1_1/102.jp2/full/!1200,%20900/0/default.jpg",
        "link":"https://eap.bl.uk/item/EAP838-1-1-1-102",
        "title":"Unidentified Rana Princess",
        "date":"1880s-1940s"
     },
     {
        "img":"https://images.eap.bl.uk/EAP838/EAP838_1_1_2/118.jp2/full/!1200,%20900/0/default.jpg",
        "link":"https://eap.bl.uk/item/EAP838-1-1-2-118",
        "title":"Hunted alligator",
        "date":"1900s-1930s"
     },
     {
        "img":"https://images.eap.bl.uk/EAP838/EAP838_1_7_3/244.jp2/full/!1200,%20900/0/default.jpg",
        "link":"https://eap.bl.uk/item/EAP838-1-7-3-244",
        "title":"A variety of sacred images installed in precincts at a gaihridhara located in Nala village",
        "date":"1950s-1970s"
     },
     {
        "img":"https://images.eap.bl.uk/EAP838/EAP838_1_1_7/5.jp2/full/1200,%20900/0/default.jpg",
        "link":"https://eap.bl.uk/item/EAP838-1-1-7-5",
        "title":"Three women posing in front of the camera",
        "date":"1900s-1940s"
     },
     {
        "img":"https://images.eap.bl.uk/EAP838/EAP838_1_7_3/659.jp2/full/!1200,900/0/default.jpg",
        "link":"https://eap.bl.uk/item/EAP838-1-7-3-659",
        "title":"An aerial view of Kathmandu Valley. In the foreground Bahadur Bhawan, which later became the first hotel, Royal Hotel, in Nepal",
        "date":"1950s-1970s"
     },
     {
        "img":"https://images.eap.bl.uk/EAP838/EAP838_1_7_3/654.jp2/full/!1200,%20900/0/default.jpg",
        "link":"https://eap.bl.uk/item/EAP838-1-7-3-654",
        "title":"Sankhamul Ghat on the banks of Bagmati River",
        "date":"1970s"
     },
     {
        "img":"https://images.eap.bl.uk/EAP838/EAP838_1_7_3/226.jp2/full/!1200,%20900/0/default.jpg",
        "link":"https://eap.bl.uk/item/EAP838-1-7-3-226",
        "title":"Nyatapola Temple, consecrated in 1702, was built by King Bhupatindra Malla.",
        "date":"1950s-1970s"
     }
  
 ] 

class DandGChitrakar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            windowWidth: window.innerWidth,
            searchTerms: '',
            isLoading: false,
            error: null
        };
    }

    onChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    }

    onKeyUp(e) {
        if (e.charCode === 13) {
            window.location.href = '/search?q=' + e.target.value;
        }
    }

    handleResize = (e) => {
        this.setState({ windowWidth: window.innerWidth });
    }

    componentDidMount() {
        window.addEventListener("resize", this.handleResize);
    }

    componentWillUnmount() {
        window.addEventListener("resize", this.handleResize);
    }

    render() {
        const { windowWidth, searchTerms, isLoading, error } = this.state;

        return (
            <div className="application">
                <Helmet>
                    <meta charset="utf-8" />
                    <title>Dirghaman & Ganeshman Chitrakar Collection | Archive Nepal</title>
                    <link rel="canonical" href="https://www.archivenepal.org/dandgchitrakar" />
                    <meta name="description" content="Dirghaman & Ganeshman Chitrakar Collection" />
                </Helmet>

                <div id="page-wrapper">

                    {windowWidth >= 1285 ? <div className="input-group col-md-4 childSearchBox" style={{ float: 'right', marginTop: '65px' }}>
                        <input type="text" className="form-control input-lg" name="searchTerms" placeholder="Discover Nepal through its history" value={searchTerms} onChange={this.onChange} onKeyPress={this.onKeyUp} style={{ padding: "5px" }} />
                        <Link to={'/search?q=' + this.state.searchTerms} className="searchButton" ><FaSearch /></Link>
                    </div> :
                        <div className="input-group col-md-4 childSearchBox" style={{ float: 'right', marginTop: '65px' }}>
                            <input type="text" className="form-control input-md" name="searchTerms" placeholder="Discover Nepal through its history" value={searchTerms} onChange={this.onChange} onKeyPress={this.onKeyUp} />
                            <Link to={'/search?q=' + this.state.searchTerms} className="searchButton" ><FaSearch /></Link>
                        </div>
                    }
                    <section id="main" className="container">
                        <h2>Dirghaman & Ganeshman Chitrakar Collection</h2>
                        <h5>Compiled by: Dristi Manandhar</h5>
                        <section className="box special features">
                            <div className="features-row">
                                <div style={{ textAlign: 'left' }}>
                                    <p>Dirgha Man (1877-1951) was employed as a Royal Painter and Court Photographer. His photographs were taken when cameras were only accessible for the court and a few elites. Dirgha Man was able to capture court and local life, official events and state visits that otherwise would not have been recorded. These photographs are interesting for Nepal’s history as Nepal did not open up to outside visitors until the mid-1950s.</p>
                                    <p>Ganesh Man (1916-1985) continued his position in the Court and later worked as a chief photographer for USAID. He made the first aerial photographs of Kathmandu Valley and was also the first person in the country to develop colour slides. His photographs are essential to observe the changing landscape of Kathmandu Valley over several decades and have been of most interest to climate change researchers. His photographs have also been used to record lost and stolen artefacts from temples and other complexes from around Kathmandu. These photographs are unique and essential for understanding the history of Nepal.<Button size="small" href='https://eap.bl.uk/project/EAP838' target='_blank'>Read More</Button></p>
                                </div>
                                <div id="masonry">

                                    {windowWidth >= 1285 ? <Box>
                                        <ImageList variant="masonry" cols={3} gap={20}>
                                            {itemData.map((item) => (
                                                <a href={item.img} target='_blank'>
                                                    <ImageListItem key={item.img}>
                                                        <img
                                                            src={`${item.img}?w=248&fit=crop&auto=format`}
                                                            srcSet={`${item.img}?w=248&fit=crop&auto=format&dpr=2 2x`}
                                                            alt={item.title}
                                                            loading="lazy"
                                                        />
                                                        <a href={item.img} target='_blank'>
                                                            <ImageListItemBar position="below" title={item.title} subtitle={<span>Date: {item.date}</span>} style={{ width: '300px' }} />
                                                        </a>
                                                    </ImageListItem>
                                                </a>
                                            ))}
                                        </ImageList>
                                    </Box> :
                                        <Box>
                                            <ImageList variant="masonry" cols={1} gap={1}>
                                                {itemData.map((item) => (
                                                    <ImageListItem key={item.img}>
                                                        <img
                                                            src={`${item.img}?w=248&fit=crop&auto=format`}
                                                            srcSet={`${item.img}?w=248&fit=crop&auto=format&dpr=2 2x`}
                                                            alt={item.title}
                                                            loading="lazy"
                                                        />
                                                        <a href={item.img} target='_blank'>
                                                            <ImageListItemBar position="below" title={item.title} subtitle={<span>Date: {item.date}</span>}  style={{ width: '300px' }} />
                                                        </a>
                                                    </ImageListItem>
                                                ))}
                                            </ImageList>
                                        </Box>
                                    }

                                </div>
                            </div>
                        </section>
                    </section>
                </div>
            </div>
        )
    }
}
export default DandGChitrakar;