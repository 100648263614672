import React, { useState } from 'react';
import axios from 'axios';
import Button from '@mui/material/Button';

export default function Str() {

  const [alias, setAlias] = useState(null);
  const [org, setOrg] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isError, setIsError] = useState(false);

  function handleSubmit(e) {
    e.preventDefault();
    axios.post('https://4b99ip0pxg.execute-api.us-east-1.amazonaws.com/v1/postAlias',
      {
        "key1": `${alias}`,
        "key2": `${org}`
      }
    ).then((res) => {
      setAlias('');
      setOrg('');
    });
    alert('Shortened link: https://www.archivenepal.org/' + `${alias}`);
  }

  return (

    <div className="application">
      <div id="page-wrapper">
        <section id="main" className="container">
          <section className="box special features">


            <div>
              <label style={{ float: "left" }}>Orginal Link:</label>
              <input
                type="text"
                name="org"
                onChange={e => setOrg(e.target.value)}
                value={org}
                style={{ marginBottom: '25px' }}
              />

              <label style={{ float: "left" }}>Alias:</label>
              <input
                type="text"
                name="alias"
                onChange={e => setAlias(e.target.value)}
                value={alias}
                style={{ marginBottom: '25px' }}

              />

              <Button variant="contained" onClick={handleSubmit}>Shorten the Link</Button>

            </div>
          </section>
        </section>
      </div>
    </div>
  );
}