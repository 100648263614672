import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import Fade from 'react-reveal/Fade';
import Zoom from 'react-reveal/Zoom';
import { FaInstagram, FaFacebook } from 'react-icons/fa';
import { CgWebsite } from 'react-icons/cg'
import '../styles/main.css';
import '../styles/won.css';

class WomenOfNepal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            windowWidth: window.innerWidth,
            searchTerms: '',
            isLoading: false,
            error: null
        };
    }

    handleResize = (e) => {
        this.setState({ windowWidth: window.innerWidth });
    }

    componentDidMount() {
        window.addEventListener("resize", this.handleResize);
    }

    componentWillUnmount() {
        window.addEventListener("resize", this.handleResize);
    }

    render() {
        const { windowWidth, searchTerms, isLoading, error } = this.state;

        return (
            <div className="application">
                <Helmet>
                    <meta charset="utf-8" />
                    <title>Influential women of Nepal over the years | Archive Nepal</title>
                    <link rel="canonical" href="https://www.archivenepal.org/sushilasingh" />
                    <meta name="description" content="On this International Woman’s day,  we are paying a homage to women before us who pave their own way raising above not just the misogyny and patriarchy but various other social, economic, and religious issues like child marriages, gender based education disparities, menstrual discrimination - still existing chhaupadi,  among many others gender inequalities, thus, creating a space for women today to continue their legacy. One major historical event, not so long ago in 1920 (1977 BS), that helped secure women’s place in Nepali society is the abolition of ancient Hindu custom Sati system where the widow, mistresses, and female servants were burned on the funeral pyre of a deceased man voluntarily or by use of force or coercion. The hurdles women face today are still numerous, however, one before us made sure to challenge many societal norms and taboo to raise above all the difficulties so that their younger generations women have one less fight to fight. This collection is such tribute to all the women who have made a significant dent in our society. The hurdles women face today are still numerous, however, one before us made sure to challenge many societal norms and taboo to push boundaries so that their younger generations women have one less fight to fight. This collection is such tribute to all the women who have made waves in our society." />
                </Helmet>
                <div id="page-wrapper">
                    <section id="onlineexhibitmain" class="profilemain style1">
                        {windowWidth >= 500 ? <h3 id='profileHeader' style={{ color: '#ffffff', fontWeight: 'Bold', width: '500px', marginRight: 'auto', marginLeft: 'auto', background: '#c8102e', opacity: '0.9', fontSize: '21px' }}>INFLUENTIAL WOMEN OF NEPAL OVER THE YEARS</h3> : <h3 id='profileHeader' style={{ color: '#ffffff', fontWeight: 'Bold', marginRight: 'auto', marginLeft: 'auto', background: '#c8102e', opacity: '0.9', fontSize: '21px'}}>INFLUENTIAL WOMEN OF NEPAL OVER THE YEARS</h3>}
                        <Fade left>
                            <div style={{ padding: '0 25px 0 25px', color: '#000000', marginTop: '400px'}}>
                                <p class="info">On this International Women’s day,  we are paying a homage to women before us who pave their own way raising above not just the misogyny and patriarchy but various other social, economic, and religious issues like child marriages, gender based education disparities, menstrual discrimination - still existing <a href='https://www.dw.com/en/nepal-why-menstrual-huts-still-exist-despite-being-illegal/a-63464074' target='_blank'>chhaupadi</a>, among many others gender inequalities, thus, creating a space for women today to continue their legacy. The hurdles women face today are still numerous, however, one before us made sure to challenge many societal norms and taboo to push boundaries so that their younger generations women have one less fight to fight. This Digibit - Digital Exhibit is such tribute to all the women who have made waves in our society.</p>
                            </div>
                        </Fade>
                        <span style={{color: '#ffffff'}}>Photo Credit: Women waiting to vote [1966] by Wayne Stinson</span>
                    </section>
            
                    <section id="oepic2" class="profilemain style3 primary">
                    <div style={{ fontFamily: 'Montserrat', flex: 'auto' }}><h3 style={{ fontSize: '35px', color:"#000000" }}>Parijat</h3></div>
                    <Fade left>
                            <div style={{ padding: '0 25px 0 25px', marginTop: '600px' }}>
                            <p class="info">Bishnu Kumari Waiba, popularly known as Parijat was a Nepali writer and poet. She is best known for her novel Shirish Ko Phool published in 1964 for which she won the Madan Puraskar, becoming the first woman to receive the award in 1965. She published multiple novels and wrote many poems and stories in her lifetime.</p>
                            </div>
                    </Fade>
                    <span style={{color: '#ffffff'}}>Photo Credit: Nepal Picture Library</span>
                    </section>

                    <section id="oepic3" class="profilemain style3 primary">
                    <div style={{ fontFamily: 'Montserrat', flex: 'auto' }}><h3 style={{ fontSize: '35px', color:"#ffffff" }}>Jhamak Kumari Ghimire</h3></div>
                    <Fade left>
                            <div style={{ padding: '0 25px 0 25px', marginTop: '600px' }}>
                            <p class="info">Jhamak Kumari Ghimire was Born on 4 July 1980 and is a Nepali writer. She was born with cerebral palsy and writes with her left foot. She had been awarded with the Madan Puraskar for her autobiography ‘Jiwan Kada Ki Phool’ which was published in 2010. She is also a columnist at the Kantipur newspaper.</p>
                            </div>
                    </Fade>
                    <span style={{color: '#ffffff'}}>Photo Credit: The Annapurna Express</span>
                    </section>

                    <section id="oepic4" class="profilemain style3 primary">
                    <div style={{ fontFamily: 'Montserrat', flex: 'auto' }}><h3 style={{ fontSize: '35px', color:"#000000" }}>Mangala Devi Singh</h3></div>
                    <Fade left>
                            <div style={{ padding: '0 25px 0 25px', marginTop: '600px' }}>
                            <p class="info">Mangala Devi Singh was a pioneer feminist and prominent democratic right activist of Nepal. Singh got involved in politics in 1940 at the age of 16. Her husband was the leading Nepali Congress Leader Ganesh Man Singh. In 1948 Mangala Devi Singh led a delegation to Prime Minister Padma Shumsher to demand education, employment and voting rights for women. In 1952 the Nepal Women's Association had an ideological split, with Mangala leading a faction which believed that women's rights could be attained through democratic reform.</p>
                            </div>
                    </Fade>
                    <span style={{color: '#ffffff'}}>Photo Credit: Flicker, CC BY-SA 2.0</span>
                    </section>
                   
                    <section id="oepic5" class="profilemain style3 primary">
                    <div style={{ fontFamily: 'Montserrat', flex: 'auto' }}><h3 style={{ fontSize: '35px', color:"#ffffff" }}>Anuradha Koirala</h3></div>
                    <Fade left>
                            <div style={{ padding: '0 25px 0 25px', marginTop: '600px' }}>
                            <p class="info">Anuradha Koirala has been an inspiring icon in Nepal. She runs a non-profit organisation, Maiti Nepal (founded in April 1993). It works on helping survivors of sex trafficking, rescuing and reuniting them with their families. Additionally, Maiti Nepal also operates a rehabilitation home and an academy in Kathmandu, preventive homes in other cities as well as transit homes at the Indo-Nepal border towns operating with the help of police and law enforcement authorities. Koirala also served as the governor of the Bagmati province and currently holds a place in the Nepali Congress party (since November 2017).</p>
                            </div>
                    </Fade>
                    <span style={{color: '#ffffff'}}>Photo Credit: Flicker, CC BY-SA 2.0</span>
                    </section>

                    <section id="oepic6" class="profilemain style3 primary" >
                    <div style={{ fontFamily: 'Montserrat', flex: 'auto' }}><h3 style={{ fontSize: '35px', color:"#000000" }}>Pushpa Basnet</h3></div>
                    <Fade left>
                            <div style={{ padding: '0 25px 0 25px'}}>
                            <p class="info">Pushpa Basnet won CNN Hero in the year 2012. She is a social worker and the founder/president of Early Childhood Development Center and Butterfly Home, non-profit organizations, in Kathmandu, Nepal. Her organization works to strengthen the rights of children living behind bars with their incarcerated parents. She has impacted more than 100 lives. Nepal’s Department of Prison Management estimates 80 children live in the nation’s prisons.</p>
                            </div>
                    </Fade>
                    <iframe width="100%" height="100%" src="https://www.youtube.com/embed/qGQzi4zBAgM" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                    </section>

                    <section id="oepic1" class="profilemain style3 primary">
                    <div style={{ fontFamily: 'Montserrat', flex: 'auto' }}><h3 style={{ fontSize: '35px', color:"#ffffff" }}>Bidhya Devi Bhandari: Nepal's First Female President</h3></div>
                    <Fade left>
                            <div style={{ padding: '0 25px 0 25px', marginTop: '600px'}}>
                            <p class="info">Bidhya Devi Bhandari is a prominent figure who is active in politics for decades. She potentially has greater influence in national policies and decision making. She has been a Member of Parliament in 1993, 1994 and 1999 A.D., Minister for Environment and Population in 1997, Minister for Defence from 2010 to 2012. Eventually she was elected as the President of Nepal on October 28, 2015. Today she is the first lady President of Nepal. This is a huge progress for Nepali women over the years. </p>
                            </div>
                    </Fade>
                    <span style={{color: '#ffffff'}}>Photo Credit: Nepal Picture Library</span>
                    </section>
                    
                    <section id="oepic7" class="profilemain style3 primary">
                    <div style={{ fontFamily: 'Montserrat', flex: 'auto' }}><h3 style={{ fontSize: '35px', color:"#ffffff" }}>Mira Rai</h3></div>
                    <Fade left>
                            <div style={{ padding: '0 25px 0 25px', marginTop: '600px' }}>
                            <p class="info">Mira Rai is a Nepali trail runner and sky runner. She has participated in many international competitions. She has taken part and was successful in achieving various positions at Ben Nevis Ultra Race completion (2017), Barro Sky Night race, Mont-Blanc 80-km race (King of the Hills race. She has won numerous awards. She was the winner of 2017 National Geographic Adventurer of the Year.</p>
                            </div>
                    </Fade>
                    <span style={{color: '#ffffff'}}>Photo Credit: Getty Images</span>
                    </section>

                    <section id="oepic8" class="profilemain style3 primary">
                    <div style={{ fontFamily: 'Montserrat', flex: 'auto' }}><h3 style={{ fontSize: '35px', color:"#ffffff" }}>Rama Singh</h3></div>
                    <Fade left>
                            <div style={{ padding: '0 25px 0 25px', marginTop: '600px' }}>
                            <p class="info">Rama Singh started her career as a news anchor of Nepal Television in 1986. She is known to be the first news anchor in Nepali Television history but she was also a great football player. Singh started her journey in football in 1983 as the captain on Nepal’s women football's Bagmati team. At the 1986 Asian Cup, she captained Nepal as it made its international debut.</p>
                            </div>
                    </Fade>
                    <span style={{color: '#ffffff'}}>Photo Credit: Wikimedia, CC BY-SA 4.0 </span>
                    </section>

                    <section id="oepic6" class="profilemain style3 primary" >
                    <div style={{ fontFamily: 'Montserrat', flex: 'auto' }}><h3 style={{ fontSize: '35px', color:"#000000" }}>Ambica Shrestha</h3></div>
                    <Fade left>
                            <div style={{ padding: '0 25px 0 25px'}}>
                            <p class="info">Ambica Shrestha is a Nepali entrepreneur. Shrestha is the president of 5 starred Dwarika´s Hotels and Resorts. Shrestha, who was born in Darjeeling, India, got her secondary education in St. Joseph. She has inspired a lot of women by stepping into the business sector and leading it tremendously well.</p>
                            </div>
                    </Fade>
                    <iframe width="100%" height="100%" src="https://www.youtube.com/embed/ZIS7NJsez1o" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>            
                    </section>

                    <section id="oepic9" class="profilemain style3 primary">
                    <div style={{ fontFamily: 'Montserrat', flex: 'auto' }}><h3 style={{ fontSize: '35px', color:"#ffffff" }}>Pasang Lhamu Sherpa: First Nepali woman to summit Mt. Everest</h3></div>
                    <Fade left>
                            <div style={{ padding: '0 25px 0 25px', marginTop: '600px' }}>
                            <p class="info">Pasang Lhamu Sherpa was the first Nepali woman to climb the summit of Mount Everest. She had tried three times before but was successful to summit Mount Everest on April 22 1993. She was born in a mountaineering family and was involved in climbing from her teens. She had successfully climbed Mount Blanc, Mount Cho Oyu, Mount Yalapic, Pisang Himal, and others.</p>
                            </div>
                    </Fade>
                    <span style={{color: '#ffffff'}}>Photo Credit: <a href='https://datebook.sfchronicle.com/movies-tv/documentary-unveils-long-elusive-story-of-nepalese-woman-who-defied-caste-systems-to-climb-everest' target='_blank' style={{fontWeight: 'normal'}}>Follow Your Dream Foundation</a></span>
                    </section>

                    <section id="oepic10" class="profilemain style3 primary">
                    <div style={{ fontFamily: 'Montserrat', flex: 'auto' }}><h3 style={{ fontSize: '35px', color:"#ffffff" }}>Lakpa Sherpa: Female world record holder for the most successful summit of Mt. Everest</h3></div>
                    <Fade left>
                            <div style={{ padding: '0 25px 0 25px', marginTop: '600px' }}>
                            <p class="info">Lhakpa Sherpa is a Nepali Sherpa mountain climber. She has climbed Mount Everest ten times, the most of any woman in the world. Her record-breaking tenth climb was on May 12, 2022, which she financed via a crowdfunding campaign. In 2000, she became the first Nepali woman to climb and descend Everest successfully. She was choosen as the 100 most influential people by the BBC.</p>
                            </div>
                    </Fade>
                    <span style={{color: '#ffffff'}}>Photo Credit: <a href='https://www.nytimes.com/2023/01/31/sports/lhakpa-sherpa-everest.html' target='_blank' style={{fontWeight: 'normal'}}>Stan Godlewski for The New York Times</a></span>
                    </section>
                {/*
                    <section id="oepic11" class="profilemain style3 primary">
                    <div style={{ fontFamily: 'Montserrat', flex: 'auto' }}><h3 style={{ fontSize: '35px', color:"#000000" }}>Shanta and Milan Dixit</h3></div>
                    <Fade left>
                            <div style={{ padding: '0 25px 0 25px', marginTop: '600px' }}>
                            <p class="info">Milan Dixit is the Principal and Shanta Dixit is the Director of Rato Bangla School, Lalitpur. Rato Bangala School was founded by Shanta Dixit and Milan Dixit in 1992 with a commitment to groom Nepali students to be analytical independent thinkers aware of both the country's present situation and its potential. Rato Bangla School, one of the few institution with women in top leadership role, has been consistently rated as one of the best schools in Nepal.</p>
                            </div>
                    </Fade>
                    <span style={{color: '#ffffff'}}>Photo Credit: Rato Bangla School</span>
                    </section>
                 {/*
                    <section id="oepic12" class="profilemain style3 primary">
                    <div style={{ fontFamily: 'Montserrat', flex: 'auto' }}><h3 style={{ fontSize: '35px', color:"#ffffff" }}>13. Tara Devi</h3></div>
                    <Fade left>
                            <div style={{ padding: '0 25px 0 25px', marginTop: '600px' }}>
                            <p class="info">Dol Kumari Karki (15 January 1946 – 23 January 2006), professionally known as Tara Devi was a Nepali singer. She is known as the "Nightingale of Nepal". She was primarily involved in singing for children's programs at Radio Nepal. She was able to pursue her musical career along with her studies. She completed her Bachelor's in Music.</p>
                            </div>
                    </Fade>
                    <span style={{color: '#ffffff'}}>Photo Credit: Boss Nepal</span>
                    </section>
                 */}
                    <section id="oepic6" class="profilemain style3 primary" >
                    <div style={{ fontFamily: 'Montserrat', flex: 'auto' }}><h3 style={{ fontSize: '35px', color:"#000000" }}>Tara Devi</h3></div>
                    <Fade left>
                            <div style={{ padding: '0 25px 0 25px'}}>
                            <p class="info">Dol Kumari Karki (15 January 1946 – 23 January 2006), professionally known as Tara Devi was a Nepali singer. She is affectionatively known as the "Nightingale of Nepal". Much of her music revolved around the themes of patriotism and love. Tara Devi professionally started singing at the age of seven and went on to record 4000 songs in her 40 year singing career.</p>
                            </div>
                    </Fade>
                    <iframe width="100%" height="100%" src="https://www.youtube.com/embed/kAAjnLlO05M" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                    </section>   

                    <section id="oepic12" class="profilemain style3 primary">
                    <div style={{ fontFamily: 'Montserrat', flex: 'auto' }}><h3 style={{ fontSize: '35px', color:"#ffffff" }}>Sushila Karki: Nepal's First Female Chief Justice</h3></div>
                    <Fade left>
                            <div style={{ padding: '0 25px 0 25px', marginTop: '600px' }}>
                            <p class="info">Sushila Karki is a Nepali jurist. She is the former Chief Justice of the Supreme Court of Nepal and the only woman to have held the post. Karki became Chief Justice on 11 July 2016.</p>
                            </div>
                    </Fade>
                    <span style={{color: '#ffffff'}}>Photo Credit: <a href='https://archive.nepalitimes.com/article/interview/a-woman-is-as-capable-sushila-karki,3375' target='_blank' style={{fontWeight: 'normal'}}>Nepali Times #834</a></span>
                    </section> 

                    <section id="oepic13" class="profilemain style3 primary">
                    <div style={{ fontFamily: 'Montserrat', flex: 'auto' }}><h3 style={{ fontSize: '35px', color:"#ffffff" }}>Sapana Pradhan Malla</h3></div>
                    <Fade left>
                            <div style={{ padding: '0 25px 0 25px', marginTop: '600px' }}>
                            <p class="info">Sapana Pradhan Malla is a Nepali Supreme Court Judge since  August 1, 2016 and a former member of the Nepali Constituent Assembly. She is also a lawyer and politician. She is a well-known figure in Nepal due to her work to advance women's rights and increase inclusive language in the country's constitution.</p>
                            </div>
                    </Fade>
                    <span style={{color: '#ffffff'}}>Photo Credit: <a href='https://asiapacific.unwomen.org/en/news-and-events/stories/2020/02/take-five-honourable-sapana-pradhan-malla' target='_blank' style={{fontWeight: 'normal'}}>UN Women/Mitch Silver</a></span>
                    </section> 

                    <section id="oepic6" class="profilemain style3 primary" >
                    <div style={{ fontFamily: 'Montserrat', flex: 'auto' }}><h3 style={{ fontSize: '35px', color:"#000000" }}>Mohna Ansari</h3></div>
                    <Fade left>
                            <div style={{ padding: '0 25px 0 25px'}}>
                            <p class="info">Mohna Ansari is a lawyer, human rights advocate and former commissioner of the National Human Rights Commission. She was appointed to this position in October 2014.  She is a well-known rights activist, and actively works to ensure representation and inclusion of women and minorities in Nepal. She worked as a commissioner at the National Women Commission (NWC) of Nepal in 2010, becoming the first Muslim woman to be in a leadership role at any constitutional commission.  She was also nominated as one of 10 Asian women presented as “Portraits of Leadership” in an event organized by The Asia Foundation in California, USA in 2008. </p>
                            </div>
                    </Fade>
                    <iframe width="100%" height="100%" src="https://www.youtube.com/embed/Jhq69Z9Cx1U" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>                    </section> 

                    <section id="oepic14" class="profilemain style3 primary">
                    <div style={{ fontFamily: 'Montserrat', flex: 'auto' }}><h3 style={{ fontSize: '35px', color:"#ffffff" }}>Dwarika Devi Thakurani: Nepal's First Female Minister</h3></div>
                    <Fade left>
                            <div style={{ padding: '0 25px 0 25px', marginTop: '600px' }}>
                            <p class="info">Dwarika Devi Thakurani was the  first Nepali woman elected to the parliament and first woman cabinet minister. Thakurani was elected to parliament in the first democratic election of Nepal, held in February 1959. She was a candidate from Dadeldhura District for Nepali Congress. She was the only woman elected to the House of Representatives (out of 15 that ran), and jointly the first woman in the Nepali parliament. Following the election, she was appointed deputy minister of Health and Local Self-governance on 27 May 1959 in the BP Koirala cabinet. </p>
                            </div>
                    </Fade>
                    <span style={{color: '#ffffff'}}>Photo Credit: <a href="https://www.himalkhabar.com/news/134674" target='_blank'>Himalkhabar.com</a></span>
                    </section> 

                    <section id="oepic15" class="profilemain style3 primary">
                    <div style={{ fontFamily: 'Montserrat', flex: 'auto' }}><h3 style={{ fontSize: '35px', color:"#ffffff" }}>Tista Prasai Joshi</h3></div>
                    <Fade left>
                            <div style={{ padding: '0 25px 0 25px', marginTop: '600px' }}>
                            <p class="info">Tista Prasai Joshi is a water scientist working on purifying water using organic arsenic. Her research into arsenic to see if it could be a water-purifying compound is unique and could have implications for other developing countries. She is also researching the residue of elements or metals in the water resources of Kathmandu that hamper human lives.  She is advocating to prioritize the importance of science with  the Nepal government.</p>
                            </div>
                    </Fade>
                    <span style={{color: '#ffffff'}}>Photo Credit: Onlinekhabar.com</span>
                    </section> 

                    <section id="work" class="profilemain style3 primary">
                        <Fade left>
                            <div style={{ padding: '0 25px 0 25px', color: '#000000' }}>
                                <p>The research needed to compile the list was done by one of our paid volunteers, Shreya Gautam, edited by Editorial team member from Archive Nepal, and the final product, Digibit - Digital Exhibit, made possible by technical team of Archive Nepal. This Digibit is a 'Living Document' that will be updated on a regular basis.</p>
                            </div>
                        </Fade>
                    </section>

                </div>
            </div>
        )
    }
}
export default WomenOfNepal;
