import React, { Component } from 'react';
import '.././styles/main.css';
import { Helmet } from 'react-helmet';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Login from './GoogleLoginPage';

const theme = createTheme({
    palette:{
      nepalBlue: {
        main: '#0e4c8f',
        contrastText: '#fff',
      },
    },
  });

class Manaslu extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            windowWidth: window.innerWidth, 
            searchTerms: '',
            isLoading: false,
            error: null
        };
    }
	
	onChange = (e) => {
		this.setState({ [e.target.name]: e.target.value });
	}
	
	onKeyUp(e){
		if (e.charCode === 13) {
			window.location.href = '/search?q=' + e.target.value;
		  }
	}

    handleResize = (e) => {
        this.setState({ windowWidth: window.innerWidth });
    }

    componentDidMount() {
        window.addEventListener("resize", this.handleResize);
    }

    componentWillUnmount() {
        window.addEventListener("resize", this.handleResize);
    }

    render() {
        //const { windowWidth, searchTerms, isLoading, error } = this.state;

        return (
            
            <div className="application">
                <Helmet>
                    <meta charset="utf-8" />
                    <title>Manaslu Records Management System</title>
                    <link rel="canonical" href="https://www.archivenepal.org/mkms" />
                    <meta name="description" content="Manaslu Records Management System" />
                </Helmet>
                <header id="header" className="alt" style={{position: 'fixed'}}>
                    <h1><img src="/images/archiveNepal.png" style={{ height: '35px', marginTop: '-8px'}} alt="Archive Nepal - Discover Nepal through its History"/></h1>
                </header>
            <div id="page-wrapper">
                <section id="main" className="container">
                <h1><img src="/images/manaslu.png" style={{ height: '75px', marginTop: '-8px', display: 'block', marginLeft: 'auto', marginRight: 'auto'}} alt="Manaslu Record Management System"/></h1>          
                          <section className="box special features" style={{textAlign: 'left'}}>
                        <p style={{textAlign: 'left'}}>Sign in below using the Google credentials</p>
                      <Login />
                    </section>          
                </section>
            </div> 
            </div>
        )
    }
}
export default Manaslu;
