import * as React from 'react';
import Link from '@mui/material/Button';
import Outgoing from '@mui/icons-material/CallMissedOutgoing';
import { FiVideo } from 'react-icons/fi';
import { IoMdBook } from 'react-icons/io';
import { IoDocumentTextOutline } from 'react-icons/io5';
import { AiOutlineAudio } from 'react-icons/ai'
import { HiOutlinePhotograph } from 'react-icons/hi';
import { BiBox } from 'react-icons/bi';
import ImageModal from './imageModal';
import ImageModal2 from './imageModal2';
import VideoModal from './videoModal';
import VideoModal2 from './videoModal2';

export default function ResultView({ result }) {
  const type = result.type.raw;
  return (
    <li className="sui-result">
      <div className="sui-result__header">
      {result.type.raw == 'image' ? <ImageModal id={result.id.raw} url={result.object.raw} title={result.title.raw} desc={result.description.raw} link={result.link.raw} salestype={result.subject.raw} collection={result.collection.raw}/> : result.type.raw == 'video' ? <VideoModal2 url={result.object.raw} title={result.title.raw} desc={result.description.raw} link={result.link.raw} collection={result.collection.snippet} /> : <a className="sui-result_title sui-result_title-link" href={result.type.raw == 'book' || result.type.raw == 'journal' || result.type.raw == 'audio' || result.type.raw == 'document' ? result.link.raw : result.object.raw} target="_blank"><span style={{ marginRight: '5px' }}>{type == 'image' ? < HiOutlinePhotograph size={20} style={{ marginBottom: '5px' }} /> : type == 'object' ? <BiBox size={20} style={{ marginBottom: '5px' }} /> : type == 'video' ? <FiVideo size={20} style={{ marginBottom: '5px' }} /> : type == 'book' ? <IoMdBook size={20} style={{ marginBottom: '5px' }} /> : type == 'audio' ? < AiOutlineAudio size={20} style={{ marginBottom: '5px' }} /> : <IoDocumentTextOutline size={20} style={{ marginBottom: '5px' }} />}</span>{result.title.raw}</a>} 
      </div>
      <div className="sui-result__body">
        <div
          className="sui-result__image"
          style={{
            maxWidth: "140px",
            paddingLeft: "24px",
            paddingTop: "10px"
          }}
        >

          {result.type.raw == 'image' ? <ImageModal2 id={result.id.raw} url={result.object.raw} title={result.title.raw} desc={result.description.raw} link={result.link.raw} salestype={result.subject.raw} collection={result.collection.raw}/> : result.type.raw == 'video' ? <VideoModal url={result.object.raw} title={result.title.raw} desc={result.description.raw} link={result.link.raw} collection={result.collection.snippet} /> : <a href={ result.type.raw == 'book' || result.type.raw == 'journal' || result.type.raw == 'audio' || result.type.raw == 'document' ? result.link.raw : result.object.raw} target="_blank"><img class="zoom" src={result.collection.snippet == 'Southwest Virginia Digital Archive' ? result.thumbnail.raw : result.object.raw} alt="thumb" style={{ display: "block", width: "100%", height: "100%", objectFit: "cover", objectPosition: "center" }}/></a>}        
        </div>
        <ul className="sui-result__details">
          <li>
            {//  <span className="sui-result__key">Decription</span>{" "}
            }
            <span
              className="sui-result__value"
              dangerouslySetInnerHTML={{
                __html: result.description.snippet
              }}
            />
          </li>
          <li>
            <span style={{ fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif', fontSize: '15px' }}>Date: </span>
            <span
              className="sui-result__value"
              dangerouslySetInnerHTML={{
                __html: result.date.raw
              }}
            />
          </li>
          <li>

            <span style={{ fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif', fontSize: '15px' }}>Source: </span>
            <span
              className="sui-result__value"
              dangerouslySetInnerHTML={{
                __html: result.collection.raw
              }}
            />
          </li>

          <li>
            {result.type.raw == 'video' || result.type.raw == 'book' || result.type.raw == 'journal' || result.type.raw == 'audio' ? '' : <Link rel="noopener" href={result.link.raw} target='_blank' style={{ fontSize: '13px', textTransform: 'Capitalize' }}>Learn More <Outgoing size={10} /></Link>}
          </li>

        </ul>
      </div>
    </li>

  );
}


