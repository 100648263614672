import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import Fade from 'react-reveal/Fade';
import Zoom from 'react-reveal/Zoom';
import { FaInstagram, FaFacebook } from 'react-icons/fa';
import { HiOutlineMail } from 'react-icons/hi';
import { CgWebsite } from 'react-icons/cg'
import Box from '@mui/material/Box';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import ImageModal2 from './imageModal2';
import './styles/main.css';

const itemData =
    [
        {
            "img": "https://archivenepal.s3.amazonaws.com/sushila_singh_collection/access/2.jpg",
            "link": "https://docs.google.com/forms/d/e/1FAIpQLSeQsZsbsPyI-Rv4doLHLAqtJ-3B2-2gioo69rdMImoSOS1_qA/viewform?entry.2053101105=Dattatraya [2012]",
            "title": "Dattatraya [2012]",
            "salestype": 'Physical Reprints'
        },
        {
            "img": "https://archivenepal.s3.amazonaws.com/sushila_singh_collection/access/3.jpg",
            "link": "https://docs.google.com/forms/d/e/1FAIpQLSeQsZsbsPyI-Rv4doLHLAqtJ-3B2-2gioo69rdMImoSOS1_qA/viewform?entry.2053101105=Karunamaya - Bundyo 2 [2021]",
            "title": "Karunamaya - Bundyo 2 [2021]",
            "salestype": 'Physical Reprints'
        },
        {
            "img": "https://archivenepal.s3.amazonaws.com/sushila_singh_collection/access/4.jpg",
            "link": "https://docs.google.com/forms/d/e/1FAIpQLSeQsZsbsPyI-Rv4doLHLAqtJ-3B2-2gioo69rdMImoSOS1_qA/viewform?entry.2053101105=Karunamaya - Bundyo 1 [2021]",
            "title": "Karunamaya - Bundyo 1 [2021]",
            "salestype": 'Physical Reprints'
        },
        {
            "img": "https://archivenepal.s3.amazonaws.com/sushila_singh_collection/access/1.jpg",
            "link": "https://docs.google.com/forms/d/e/1FAIpQLSeQsZsbsPyI-Rv4doLHLAqtJ-3B2-2gioo69rdMImoSOS1_qA/viewform?entry.2053101105=Astamatrika -  Deforming Myself [2019]",
            "title": "Astamatrika -  Deforming Myself [2019]",
            "description": "The search for another image within an image, its perceiving or making; Astamatrika. The divine mothers, matrikas, of Nepal Mandal; we are blessed. They are present around us all the time and are ready to protect us every time. The subconscious self is always longing for happiness or searching for the ways to overcome the aches we come across, consciously or subconsciously; don’t we? The divine mothers have a large role on us, on the subconscious us; but it’s the subject of one’s belief. Should I say Astamangal is a divine perception or symbol, (I’m unsure. I wish it would bless our foreheads even when we are not aware.",
            "salestype": 'Original Art'
        },
        {
            "img": "https://archivenepal.s3.amazonaws.com/sushila_singh_collection/access/5.jpg",
            "link": "https://docs.google.com/forms/d/e/1FAIpQLSeQsZsbsPyI-Rv4doLHLAqtJ-3B2-2gioo69rdMImoSOS1_qA/viewform?entry.2053101105=Cosmic Shades 1",
            "title": "Cosmic Shades 1",
            "description": "Visitors, event the critics, are deceived in the first sight taking these art as mere sketches. These are the insights expressed in mere lines but in the form of historical images – jatra, mandir, toran, rakshak or any details crafted on walls, doors, gates and surrounding of architectural heritages. 'The historical images are both forms and medium for me, to make the abstract insights concrete- they are home to my lines,' Sushila says. She expects from the serious art readers to try to understand her lines- their length motion, direction and anything possible beyond the dominant images they collectively reflect. Leaving aside the negative areas – so to speak negative energy – to be consumed and consummated with emptiness, she has accentuated the positive areas with her lines. At the same time, she has left the most obvious parts – nose and eyes of the deities for example – untouched that gives onlookers space to imagine! Her effort seems to be making abstract the concrete and most concrete the abstract – beauty in perfection on one level and ‘imperfect beauty’ in another level. Hers are not sketches and shades, but shades without a shade- cosmic shades with full of positive energy.",
            "salestype": 'Physical Reprints'
        },
        {
            "img": "https://archivenepal.s3.amazonaws.com/sushila_singh_collection/access/6.jpg",
            "link": "https://docs.google.com/forms/d/e/1FAIpQLSeQsZsbsPyI-Rv4doLHLAqtJ-3B2-2gioo69rdMImoSOS1_qA/viewform?entry.2053101105=Cosmic Shades 2",
            "title": "Cosmic Shades 2",
            "description": "Visitors, event the critics, are deceived in the first sight taking these art as mere sketches. These are the insights expressed in mere lines but in the form of historical images – jatra, mandir, toran, rakshak or any details crafted on walls, doors, gates and surrounding of architectural heritages. 'The historical images are both forms and medium for me, to make the abstract insights concrete- they are home to my lines,' Sushila says. She expects from the serious art readers to try to understand her lines- their length motion, direction and anything possible beyond the dominant images they collectively reflect. Leaving aside the negative areas – so to speak negative energy – to be consumed and consummated with emptiness, she has accentuated the positive areas with her lines. At the same time, she has left the most obvious parts – nose and eyes of the deities for example – untouched that gives onlookers space to imagine! Her effort seems to be making abstract the concrete and most concrete the abstract – beauty in perfection on one level and ‘imperfect beauty’ in another level. Hers are not sketches and shades, but shades without a shade- cosmic shades with full of positive energy.",
            "salestype": 'Physical Reprints'
        },
        {
            "img": "https://archivenepal.s3.amazonaws.com/sushila_singh_collection/access/7.jpg",
            "link": "https://docs.google.com/forms/d/e/1FAIpQLSeQsZsbsPyI-Rv4doLHLAqtJ-3B2-2gioo69rdMImoSOS1_qA/viewform?entry.2053101105=Cosmic Shades 3",
            "title": "Cosmic Shades 3",
            "description": "Visitors, event the critics, are deceived in the first sight taking these art as mere sketches. These are the insights expressed in mere lines but in the form of historical images – jatra, mandir, toran, rakshak or any details crafted on walls, doors, gates and surrounding of architectural heritages. 'The historical images are both forms and medium for me, to make the abstract insights concrete- they are home to my lines,' Sushila says. She expects from the serious art readers to try to understand her lines- their length motion, direction and anything possible beyond the dominant images they collectively reflect. Leaving aside the negative areas – so to speak negative energy – to be consumed and consummated with emptiness, she has accentuated the positive areas with her lines. At the same time, she has left the most obvious parts – nose and eyes of the deities for example – untouched that gives onlookers space to imagine! Her effort seems to be making abstract the concrete and most concrete the abstract – beauty in perfection on one level and ‘imperfect beauty’ in another level. Hers are not sketches and shades, but shades without a shade- cosmic shades with full of positive energy.",
            "salestype": 'Physical Reprints'
        },
        {
            "img": "https://archivenepal.s3.amazonaws.com/sushila_singh_collection/access/8.jpg",
            "link": "https://docs.google.com/forms/d/e/1FAIpQLSeQsZsbsPyI-Rv4doLHLAqtJ-3B2-2gioo69rdMImoSOS1_qA/viewform?entry.2053101105=Cosmic Shades 4",
            "title": "Cosmic Shades 4",
            "description": "Visitors, event the critics, are deceived in the first sight taking these art as mere sketches. These are the insights expressed in mere lines but in the form of historical images – jatra, mandir, toran, rakshak or any details crafted on walls, doors, gates and surrounding of architectural heritages. 'The historical images are both forms and medium for me, to make the abstract insights concrete- they are home to my lines,' Sushila says. She expects from the serious art readers to try to understand her lines- their length motion, direction and anything possible beyond the dominant images they collectively reflect. Leaving aside the negative areas – so to speak negative energy – to be consumed and consummated with emptiness, she has accentuated the positive areas with her lines. At the same time, she has left the most obvious parts – nose and eyes of the deities for example – untouched that gives onlookers space to imagine! Her effort seems to be making abstract the concrete and most concrete the abstract – beauty in perfection on one level and ‘imperfect beauty’ in another level. Hers are not sketches and shades, but shades without a shade- cosmic shades with full of positive energy.",
            "salestype": 'Physical Reprints'
        },
        {
            "img": "https://archivenepal.s3.amazonaws.com/sushila_singh_collection/access/9.jpg",
            "link": "https://docs.google.com/forms/d/e/1FAIpQLSeQsZsbsPyI-Rv4doLHLAqtJ-3B2-2gioo69rdMImoSOS1_qA/viewform?entry.2053101105=Cosmic Shades 5",
            "title": "Cosmic Shades 5",
            "description": "Visitors, event the critics, are deceived in the first sight taking these art as mere sketches. These are the insights expressed in mere lines but in the form of historical images – jatra, mandir, toran, rakshak or any details crafted on walls, doors, gates and surrounding of architectural heritages. 'The historical images are both forms and medium for me, to make the abstract insights concrete- they are home to my lines,' Sushila says. She expects from the serious art readers to try to understand her lines- their length motion, direction and anything possible beyond the dominant images they collectively reflect. Leaving aside the negative areas – so to speak negative energy – to be consumed and consummated with emptiness, she has accentuated the positive areas with her lines. At the same time, she has left the most obvious parts – nose and eyes of the deities for example – untouched that gives onlookers space to imagine! Her effort seems to be making abstract the concrete and most concrete the abstract – beauty in perfection on one level and ‘imperfect beauty’ in another level. Hers are not sketches and shades, but shades without a shade- cosmic shades with full of positive energy.",
            "salestype": 'Physical Reprints'
        },
        {
            "img": "https://archivenepal.s3.amazonaws.com/sushila_singh_collection/access/10.jpg",
            "link": "https://docs.google.com/forms/d/e/1FAIpQLSeQsZsbsPyI-Rv4doLHLAqtJ-3B2-2gioo69rdMImoSOS1_qA/viewform?entry.2053101105=Cosmic Shades 6",
            "title": "Cosmic Shades 6",
            "description": "Visitors, event the critics, are deceived in the first sight taking these art as mere sketches. These are the insights expressed in mere lines but in the form of historical images – jatra, mandir, toran, rakshak or any details crafted on walls, doors, gates and surrounding of architectural heritages. 'The historical images are both forms and medium for me, to make the abstract insights concrete- they are home to my lines,' Sushila says. She expects from the serious art readers to try to understand her lines- their length motion, direction and anything possible beyond the dominant images they collectively reflect. Leaving aside the negative areas – so to speak negative energy – to be consumed and consummated with emptiness, she has accentuated the positive areas with her lines. At the same time, she has left the most obvious parts – nose and eyes of the deities for example – untouched that gives onlookers space to imagine! Her effort seems to be making abstract the concrete and most concrete the abstract – beauty in perfection on one level and ‘imperfect beauty’ in another level. Hers are not sketches and shades, but shades without a shade- cosmic shades with full of positive energy.",
            "salestype": 'Physical Reprints'
        },
        {
            "img": "https://archivenepal.s3.amazonaws.com/sushila_singh_collection/access/11.jpg",
            "link": "https://docs.google.com/forms/d/e/1FAIpQLSeQsZsbsPyI-Rv4doLHLAqtJ-3B2-2gioo69rdMImoSOS1_qA/viewform?entry.2053101105=Cosmic Shades 7",
            "title": "Cosmic Shades 7",
            "description": "Visitors, event the critics, are deceived in the first sight taking these art as mere sketches. These are the insights expressed in mere lines but in the form of historical images – jatra, mandir, toran, rakshak or any details crafted on walls, doors, gates and surrounding of architectural heritages. 'The historical images are both forms and medium for me, to make the abstract insights concrete- they are home to my lines,' Sushila says. She expects from the serious art readers to try to understand her lines- their length motion, direction and anything possible beyond the dominant images they collectively reflect. Leaving aside the negative areas – so to speak negative energy – to be consumed and consummated with emptiness, she has accentuated the positive areas with her lines. At the same time, she has left the most obvious parts – nose and eyes of the deities for example – untouched that gives onlookers space to imagine! Her effort seems to be making abstract the concrete and most concrete the abstract – beauty in perfection on one level and ‘imperfect beauty’ in another level. Hers are not sketches and shades, but shades without a shade- cosmic shades with full of positive energy.",
            "salestype": 'Physical Reprints'
        },
        {
            "img": "https://archivenepal.s3.amazonaws.com/sushila_singh_collection/access/12.jpg",
            "link": "https://docs.google.com/forms/d/e/1FAIpQLSeQsZsbsPyI-Rv4doLHLAqtJ-3B2-2gioo69rdMImoSOS1_qA/viewform?entry.2053101105=Uddan 1",
            "title": "Uddan 1",
            "description": "In this series of paintings, I am trying to sketch dignities moving beyond bounds and borders. To give wings to the temples I have used motifs of birds. And moving a bit further the rigidity of using monochromatic color in my paintings, I have used the shades of orange to signify the life in long-ignored monuments in the City of Temples. People revel festivals, visit temples and go to different places to observe jatras; can’t the gods and temples make a visit to different places to observe jatras; can’t the gods and temples make a visit to different places? This question pecked me for a long and my answer through UDDAN is they can, and they do!",
            "salestype": 'Original Art'
        },
        {
            "img": "https://archivenepal.s3.amazonaws.com/sushila_singh_collection/access/13.jpg",
            "link": "https://docs.google.com/forms/d/e/1FAIpQLSeQsZsbsPyI-Rv4doLHLAqtJ-3B2-2gioo69rdMImoSOS1_qA/viewform?entry.2053101105=Uddan 2",
            "title": "Uddan 2",
            "description": "In this series of paintings, I am trying to sketch dignities moving beyond bounds and borders. To give wings to the temples I have used motifs of birds. And moving a bit further the rigidity of using monochromatic color in my paintings, I have used the shades of orange to signify the life in long-ignored monuments in the City of Temples. People revel festivals, visit temples and go to different places to observe jatras; can’t the gods and temples make a visit to different places to observe jatras; can’t the gods and temples make a visit to different places? This question pecked me for a long and my answer through UDDAN is they can, and they do!",
            "salestype": 'Original Art'
        },
        {
            "img": "https://archivenepal.s3.amazonaws.com/sushila_singh_collection/access/14.jpg",
            "link": "https://docs.google.com/forms/d/e/1FAIpQLSeQsZsbsPyI-Rv4doLHLAqtJ-3B2-2gioo69rdMImoSOS1_qA/viewform?entry.2053101105=Uddan 3",
            "title": "Uddan 3",
            "description": "In this series of paintings, I am trying to sketch dignities moving beyond bounds and borders. To give wings to the temples I have used motifs of birds. And moving a bit further the rigidity of using monochromatic color in my paintings, I have used the shades of orange to signify the life in long-ignored monuments in the City of Temples. People revel festivals, visit temples and go to different places to observe jatras; can’t the gods and temples make a visit to different places to observe jatras; can’t the gods and temples make a visit to different places? This question pecked me for a long and my answer through UDDAN is they can, and they do!",
            "salestype": 'Original Art'
        },
        {
            "img": "https://archivenepal.s3.amazonaws.com/sushila_singh_collection/access/15.jpg",
            "link": "https://docs.google.com/forms/d/e/1FAIpQLSeQsZsbsPyI-Rv4doLHLAqtJ-3B2-2gioo69rdMImoSOS1_qA/viewform?entry.2053101105=Uddan 4",
            "title": "Uddan 4",
            "description": "In this series of paintings, I am trying to sketch dignities moving beyond bounds and borders. To give wings to the temples I have used motifs of birds. And moving a bit further the rigidity of using monochromatic color in my paintings, I have used the shades of orange to signify the life in long-ignored monuments in the City of Temples. People revel festivals, visit temples and go to different places to observe jatras; can’t the gods and temples make a visit to different places to observe jatras; can’t the gods and temples make a visit to different places? This question pecked me for a long and my answer through UDDAN is they can, and they do!",
            "salestype": 'Original Art'
        }
    ]

class SushilaSingh extends Component {
    constructor(props) {
        super(props);
        this.state = {
            windowWidth: window.innerWidth,
            searchTerms: '',
            isLoading: false,
            error: null
        };
    }

    handleResize = (e) => {
        this.setState({ windowWidth: window.innerWidth });
    }

    componentDidMount() {
        window.addEventListener("resize", this.handleResize);
    }

    componentWillUnmount() {
        window.addEventListener("resize", this.handleResize);
    }

    render() {
        const { windowWidth, searchTerms, isLoading, error } = this.state;

        return (
            <div className="application">
                <Helmet>
                    <meta charset="utf-8" />
                    <title>Sushila Singh Collection | Archive Nepal</title>
                    <link rel="canonical" href="https://www.archivenepal.org/sushilasingh" />
                    <meta name="description" content="Sushila Singh Collection comprises of the arts from Ms. Sushila Singh who is an MFA – with graduate specialization in composition. She learnt art from Babu Raja Dyola and as a student at Lalit Sikshya Academy and Central Department of Fine Arts, TU, Kirtipur. She is a receipent of Nepal Academy Of Fine Arts’ Bishesh Purashkar -2078 for Installation awarded to the artwork Maa – Shakti & Nepal Academy Of Fine Arts’ Bishesh Purashkar -2076 for Handicraft awarded to the artwork Ghat." />
                </Helmet>
                <div id="page-wrapper">
                    <section id="intro" class="profilemain style1">
                        {windowWidth >= 500 ? <h3 id='profileHeader' style={{ color: '#ffffff', fontWeight: 'Bold', width: '380px', marginTop: '555px', marginRight: 'auto', marginLeft: 'auto', background: '#c8102e', opacity: '0.9', fontSize: '25px' }}>SUSHILA SINGH COLLECTION</h3> : <h3 id='profileHeader' style={{ color: '#ffffff', fontWeight: 'Bold', marginTop: '155px', marginRight: 'auto', marginLeft: 'auto', background: '#c8102e', opacity: '0.9', fontSize: '25px', position: 'fixed' }}>SUSHILA SINGH COLLECTION</h3>}

                    </section>

                    <section id="one" class="profilemain style2 right dark fullscreen">
                        <Fade right>
                            <div style={{ height: '700px', color: '#000000', marginTop: '900px' }}>
                                <div class="content profilebox style2" style={{ overflowY: 'auto', padding: '20px', textAlign: 'left' }}>
                                    <p>Sushila Singh is an MFA – with graduate specialization in composition. She learnt art from Babu Raja Dyola and as a student at Lalit Sikshya Academy and Central Department of Fine Arts, TU, Kirtipur.</p>
                                    <p>She is a receipent of Nepal Academy Of Fine Arts’ Bishesh Purashkar -2078 for Installation awarded to the artwork Maa – Shakti & Nepal Academy Of Fine Arts’ Bishesh Purashkar -2076 for Handicraft awarded to the artwork Ghat.</p>
                                </div>
                            </div>
                        </Fade>
                    </section>

                    <section id="work" class="profilemain style3 primary">
                        <Fade left>
                            <div style={{ marginLeft: 'auto', marginRight: 'auto', height: 'auto', display: 'flex' }}>
                                <div style={{ fontSize: '150px', lineHeight: '0.7', fontWeight: '400', color: '#c8102e', textAlign: 'right', flex: 'auto', marginRight: '10px' }}><i>&ldquo;</i></div>
                                <div style={{ fontFamily: 'Montserrat', flex: 'auto' }}><h3 style={{ fontSize: '35px', textAlign: 'left' }}>Art for Bliss. How does an art take its form?</h3></div>
                            </div>
                            <div style={{ padding: '0 25px 0 25px' }}>
                                <p style={{ textAlign: 'left' }}> Sushila Singh has a unique experience about it. She cannot do any art if she is compelled to do within a specific time and place. In a certain state of mind, a certain insight calls for an expression. She feels restless unless she tries to listen to her intuition and express it in an art. But sometimes, even when she tries, the vivid lines and image that she recently had would disappear leaving her disappointed. She says, “In my case, an art takes its form with a timely response to a calling. ”What inspires the calling?“ Perhaps a mixture of many things - the time and space I am in; the ambience and the images I have exposure to; my state of mind; and the habitus. Sometimes some external factors like visual exposure to lights and shadow, space and tangible things inspire for the calling. Other times, the thoughts and insights stimulated by the past and present bring in the calling. ”What does an art do to an artist?“ I get some insights, a calling. I make an art to respond to that calling. The indulgent to the response gets pleasing, fulfilling – sometimes to the level of an ecstatic bliss. So, self-satisfaction is what an art does to an artist. Similarly, it is expected to satisfy the onlookers, it must have a quality to engage the onlookers and perhaps provoke their thoughts, if not to satisfy them.”</p>
                            </div>
                        </Fade>
                    </section>

                    <section id="two" class="profilemain style2 right dark fullscreen">
                        <Zoom>
                            <div style={{ height: '700px', color: '#000000', marginTop: '500px' }}>
                                <div style={{ marginLeft: 'auto', marginRight: 'auto', height: 'auto', display: 'flex' }}>
                                    <div style={{ fontSize: '150px', lineHeight: '0.7', fontWeight: '400', color: '#c8102e', textAlign: 'right', flex: 'auto', marginRight: '10px' }}><i>&ldquo;</i></div>
                                    <div style={{ fontFamily: 'Montserrat', flex: 'auto' }}><h3 style={{ fontSize: '60px', textAlign: 'left', color: '#ffffff' }}>Heritage on ink and paper</h3></div>
                                </div>
                            </div>
                        </Zoom>
                    </section>

                    <section id="work" class="profilemain style3 primary">
                        <Fade left>
                            <div style={{ padding: '0 25px 0 25px', color: '#000000' }}>
                                <h3 style={{ fontFamily: 'Montserrat' }}>SUPPORT LOCAL ARTIST! </h3>
                                <p style={{ fontWeight: '600' }}>Archive Nepal is pleased to collaborate with local artists who are dedicated to preserving and promoting our heritage and culture through art.</p>
                                <p>You can support this initiative by buying Sushila Singh's masterpieces. Click on each art below for options to buy digital re-prints on musuem quality canvas or to buy her original art. You will receive a certification of authenticity with each purchase. Part of the sales proceeds will go to support Archive Nepal's projects and operations. </p>
                            </div>
                        </Fade>
                        <div id="masonry" style={{ padding: '10px 10px 0 0' }}>

                            {windowWidth >= 500 ? <Box>
                                <ImageList variant="masonry" cols={3}>
                                    {itemData.map((item) => (
                                        <ImageListItem key={item.img}>
                                            <ImageModal2 url={item.img} title={item.title} desc={item.description} link={item.link} salestype={item.salestype} />
                                        </ImageListItem>
                                    ))}
                                </ImageList>
                            </Box> :
                                <Box>
                                    <ImageList variant="masonry" cols={1} gap={1}>
                                        {itemData.map((item) => (
                                            <ImageListItem key={item.img}>
                                                <ImageModal2 url={item.img} title={item.title} desc={item.description} link={item.link} salestype={item.salestype} />
                                            </ImageListItem>
                                        ))}
                                    </ImageList>
                                </Box>
                            }
                        </div>
                        <div style={{ textAlign: 'center' }}>
                            <span className="icon minor" ><a href="https://www.facebook.com/sushilasinghart" target="_blank" ><FaFacebook size={20} /></a></span>
                            <span className="icon minor" ><a href="https://www.instagram.com/art.sushilasingh" target="_blank" style={{ textDecoration: 'none' }}><FaInstagram size={20} /></a></span>
                            <span className="icon minor" ><a href="https://www.sushilasingh.com/" target="_blank" style={{ textDecoration: 'none' }}><CgWebsite size={20} /></a></span>
                        </div>
                    </section>

                </div>
            </div>
        )
    }
}
export default SushilaSingh;
