import React, { Component } from 'react';
import './styles/main.css';
import { Helmet } from 'react-helmet';

class ContactUs extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            windowWidth: window.innerWidth
            };
    }

    handleResize = (e) => {
        this.setState({ windowWidth: window.innerWidth });
    }

    componentDidMount() {
        window.addEventListener("resize", this.handleResize);
    }

    componentWillUnmount() {
        window.addEventListener("resize", this.handleResize);
    }
    render() {
        const { windowWidth} = this.state;

        return (
            <div className="application">
                <Helmet>
                    <meta charset="utf-8" />
                    <title>Current Openings | Archive Nepal</title>
                    <link rel="canonical" href="https://www.archivenepal.org/contactUs" />
                    <meta name="description" content="We would love to hear your feedback and suggestions on how to better improve Archive Nepal." />
                </Helmet>
            <div id="page-wrapper">
                <section id="main" className="container">
                <h2>Current Openings</h2>

                <section className="box special features" style={{textAlign: 'left'}}>
                                        <p>We currently have the following paid and volunteer openings. Please email us at <a href="mailto:contact@archivenepal.org">contact@archivenepal.org</a> if you have any questions.</p>
                    <div className="features-row">
                        
                        {/*
                        <h3>Paid Positions:</h3>
                        <ol>
                            <li><a href="https://archivenepal.s3.amazonaws.com/shyam_chitrakar_collection/Archive+Assistant+-+Shyam+Chitrakar+Collection.pdf?utm_source=pocket_saves" target="_blank">Archive Assistant - Shyam Chitrakar Digitization Project</a></li>
                        </ol>
                        */}
                        <h3>Volunteer Positions</h3>
                        <p>Get INVOLVED. We are looking for the following Volunteers to join our team. Expected time commitment is approx. 5 hours per week. No previous experience is required and training will be provided. Please feel free to share the opportunity with your network or refer anyone you view as a good fit for this role to <a href="mailto:hr@archivenepal.org">hr@archivenepal.org</a>.</p>

                            <ol>
                                <li>Data entry volunteer</li>
                                <ul>
                                    <li>Sort, clean and ingest raw data using Google Sheets from various sources so it can be made available on Archive Nepal.</li> 
                                </ul>
                                <li>Content creator volunteer</li>
                                <ul>
                                    <li>Identify and create engaging, educational digital galleries using the materials in Archive Nepal.</li>
                                    <li>Promote Archive Nepal’s mission and outreach.</li>
                                    <li>Create special digital exhibitions and newsletters.</li>
                                </ul>
                                
                            </ol>
                        <p>Again THANK YOU for being part of our journey and lets keep discovering Nepal through its history!</p> 
                           
   
                    </div>
                    </section>
                </section>
            </div>
            </div>
        )
    }
}
export default ContactUs;
