import React, { Component } from 'react';
import '../styles/main.css';
import Button from '@mui/material/Button';
import { Link } from 'react-router-dom';
import { FaSearch } from 'react-icons/fa';
import { Helmet } from 'react-helmet';

class Constitutions extends Component {
    constructor(props) {
        super(props);
        this.state = {
            windowWidth: window.innerWidth,
            searchTerms: '',
            isLoading: false,
            error: null
        };
    }

    onChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    }

    onKeyUp(e) {
        if (e.charCode === 13) {
            window.location.href = '/search?q=' + e.target.value;
        }
    }

    handleResize = (e) => {
        this.setState({ windowWidth: window.innerWidth });
    }

    componentDidMount() {
        window.addEventListener("resize", this.handleResize);
    }

    componentWillUnmount() {
        window.addEventListener("resize", this.handleResize);
    }

    render() {
        const { windowWidth, searchTerms } = this.state;

        return (
            <div className="application">
                <Helmet>
                    <meta charset="utf-8" />
                    <title>Timeline of the constituions of Nepal | Archive Nepal</title>
                    <link rel="canonical" href="https://www.archivenepal.org/unmembership" />
                    <meta name="description" content="Timeline regarding the various constitutions of Nepal" />
                </Helmet>

                <div id="page-wrapper">

                    {windowWidth >= 1285 ? <div className="input-group col-md-4 childSearchBox" style={{ float: 'right', marginTop: '65px' }}>
                        <input type="text" className="form-control input-lg" name="searchTerms" placeholder="Discover Nepal through its history" value={searchTerms} onChange={this.onChange} onKeyPress={this.onKeyUp} style={{ padding: "5px" }} />
                        <Link to={'/search?q=' + this.state.searchTerms} className="searchButton" ><FaSearch /></Link>
                    </div> :
                        <div className="input-group col-md-4 childSearchBox" style={{ float: 'right', marginTop: '65px' }}>
                            <input type="text" className="form-control input-md" name="searchTerms" placeholder="Discover Nepal through its history" value={searchTerms} onChange={this.onChange} onKeyPress={this.onKeyUp} />
                            <Link to={'/search?q=' + this.state.searchTerms} className="searchButton" ><FaSearch /></Link>
                        </div>
                    }
                    <section id="main" className="container">
                        <h2>Timeline of the constitutions of Nepal</h2>
                        <section className="box special features">
                            <div className="features-row">
                                <div style={{ textAlign: 'left' }}>
                                        <p>The Constitution of Nepal, adopted on September 20, 2015, is a significant legal document that establishes the framework for the governance of the country. It is the culmination of a long and tumultuous political process following the end of the civil war in 2006 and the subsequent abolition of the monarchy in 2008.</p>
                                        <p>Nepal has a rich history of constitutional development, with several constitutions enacted since the 1950s. The transition to a federal democratic republic was marked by a desire to address ethnic diversity, inclusion, and social justice. The 2015 Constitution was the first to be drafted by an elected assembly and represents a significant step towards democratic governance.</p>
                                </div>
                                <div id="masonry">
                                 <iframe src="https://cdn.knightlab.com/libs/timeline3/latest/embed/index.html?source=1vqKmJ1ifkR0VwT6jPk9eukDmlR0zmQku8VKLEzCyPqg&font=Default&lang=en&initial_zoom=2&height=650" width="100%" height="650px" frameborder="0"></iframe> 
                                </div>
                                <p style={{textAlign: 'left'}}>
                                <ol>
                                    <li>Read more: <a href="https://kathmandupost.com/miscellaneous/2015/09/24/here-are-things-you-need-to-know-about-the-7-constitutions-of-nepal" target="_blank">Here are things you need to know about the 7 constitutions of Nepal - The Kathmandu Post </a></li>
                                    <li>Access the constitutions <a href="https://www.archivenepal.org/search?q=constitutions&size=n_20_n" target="_blank">here</a></li>
                                </ol>
                                </p>
                            </div>
                        </section>
                    </section>
                </div>
            </div>
        )
    }
}
export default Constitutions;