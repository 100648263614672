import React, { Component } from 'react';
import '../styles/main.css';
import { Link } from 'react-router-dom';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import { FaSearch } from 'react-icons/fa';
import { Helmet } from 'react-helmet';
import ImageModal2 from '../imageModal2';
import VideoModal2 from '../videoModal';

const itemData = [
    {
    img: "https://ogimages.bl.uk/images/019/019WDZ000003279U00000000[SVC2].jpg",
    link: "https://ogimages.bl.uk/images/019/019WDZ000003279U00000000[SVC2].jpg",
    date: "1856",
    title: "The Kumari jatra. Three temple cars outside the Hanuman Dhoka, or Old Palace, Kathmandu",
    type: "image" 
    },
{
 img: "https://ogimages.bl.uk/images/019/019PHO000000855U00003000[SVC2].jpg",
 link: "https://ogimages.bl.uk/images/019/019PHO000000855U00003000[SVC2].jpg",
 date: "1863",
 title: "The image of Bhairub, uncovered only during the Indra Jatra festival, [Kathmandu]",
 type: "image" 
},
{
 img: "https://images.eap.bl.uk/EAP166/EAP166_2_1_18/102.jp2/full/!600,300/0/default.jpg",
 link: "https://images.eap.bl.uk/EAP166/EAP166_2_1_18/102.jp2/full/!1400,900/0/default.jpg",
 date: "1932 - 1945", 
 title: "Rana generals at Indra Jatra",
 type: "image" 
},
{
 img:"https://roundsimaging.com/galleries/nepal-peace-corp-photos/individual-contributors/dave-oconnor/photos/DO_CS0405.jpg",
 link: "https://roundsimaging.com/galleries/nepal-peace-corp-photos/individual-contributors/dave-oconnor/DO_CS0405-single.php",
 date: "1967",
 title: "Statue decorated with flowers in Indra Chowk during Indra Jatra.",
 type: "image" 
},
{
 img: "https://images.eap.bl.uk/EAP166/EAP166_2_1_18/141.jp2/full/!600,300/0/default.jpg",
 link: "https://images.eap.bl.uk/EAP166/EAP166_2_1_18/141.jp2/full/!1400,900/0/default.jpg",
 date: "1932 - 1945",
 title: "King Tribhuvan taking salute from Padma S.S, and other Rana generals at Hanumandhoka in Indra Jatra",
 type: "image" 
},
{
 img: "https://roundsimaging.com/galleries/nepal-peace-corp-photos/individual-contributors/bill-hanson/photos/BH_CS000107.jpg",
 link: "https://roundsimaging.com/galleries/nepal-peace-corp-photos/individual-contributors/bill-hanson/BH_CS000107-single.php",
 date: "1969",
 title: "Military and dancers at Indra Jatra festival at Durbar Square",
 type: "image" 
},
{
img: "https://archivenepal.s3.amazonaws.com/images/lakhe.jpeg",
title: "Indra Jatra 1968",
link: "https://lh3.googleusercontent.com/pw/AM-JKLV7_TchLyMCJUqDVbhPosLRItX1vHnPiqHIrrqQJzxGJOuSRUDc6qQeVEzTPin_cbTPlG_8oN7FYMvpjPPAYQa-YTuBY96U7x0wmlDtYzP0EJdAzmdsbQUg2T_PGaRpCQ_0nXPLwn-avBYZ79pQd_lKUA=w704-h460-no?authuser=0",
date: "1968",
type: "image" 
},
{
 img: "https://i3.ytimg.com/vi/LDhwAe3DusI/hqdefault.jpg",
 link: "https://www.youtube.com/embed/LDhwAe3DusI?si=leZo255yNsw73CTL",
 title: "VIDEO: The Red demon God Majipa LaaKhe of Yenya and Indra Jatra.",
 type: "video" 
},
{
 img: "https://i3.ytimg.com/vi/S8Nl6FwaWqk/hqdefault.jpg",
 link: "https://www.youtube.com/embed/S8Nl6FwaWqk?si=ysGUxZ0jgoo87P9F",
 title: "VIDEO: Indra the God of Haven at Maru Yenyaa and Indrajatra stories",
 type: "video" 
},
{
 img: "https://i3.ytimg.com/vi/TuCIQQwZegA/maxresdefault.jpg",
 link: "https://www.youtube.com/embed/TuCIQQwZegA?si=0-JwgozqZXPpfx-i",
 date: "1931",
 title: "VIDEO: इन्द्र जात्रा पर्व - १९३१ || Rare Films of Indra Jatra Festival Kathmandu, Nepal - 1931",
 type: "video" 
},
{
 img: "https://archivenepal.s3.amazonaws.com/images/indramount.jpeg",
 link: "https://lh3.googleusercontent.com/pw/AM-JKLWdmz8Rh8CryKPYReWzD3xyzkt_6qYbf2pogvady_3Ept9lc32WAc168nIr6kSaiHK14RHjjBzaq7WTWWudwJ-lKRWnrP8sgHfNiLlMqvfjhIaZx5tXu2mCOWCyngCZdUmRQ34_8PtTl6P8rqou9t8qcg=w704-h443-no?authuser=0",
 date: "1968",
 title: "Indra's mount (elephant)",
 type: "image" 
},
{
    img:"https://images.eap.bl.uk/EAP166/EAP166_2_1_18/103.jp2/full/!1400,900/0/default.jpg",
    link:"https://images.eap.bl.uk/EAP166/EAP166_2_1_18/103.jp2/full/!1400,900/0/default.jpg",
    date:"1932 - 1945",
    title:" King Tribhuvan on an elephant at Indra Jatra",
    type: "image" 
},
{
    img: "https://roundsimaging.com/galleries/nepal-peace-corp-photos/individual-contributors/dave-oconnor/photos/DO_CS0409.jpg",
    link: "https://roundsimaging.com/galleries/nepal-peace-corp-photos/individual-contributors/dave-oconnor/DO_CS0409-single.php",
    title: "Bhairab mask during Indra Jatra celebration in Durbar Square.",
    type: "image" 
},
{
    img: "https://images.eap.bl.uk/EAP166/EAP166_2_1_18/42.jp2/full/!1400,900/0/default.jpg",
    link: "https://images.eap.bl.uk/EAP166/EAP166_2_1_18/42.jp2/full/!1400,900/0/default.jpg",
    title: "Indra Jatra at Basantapur",
    type: "image" 
},
{
    img: "https://roundsimaging.com/galleries/nepal-peace-corp-photos/individual-contributors/charles-bailey/photos/CB_CS00129.jpg",
    link: "https://roundsimaging.com/galleries/nepal-peace-corp-photos/individual-contributors/charles-bailey/CB_CS00129-single.php",
    date: "1967",
    title: "Women gathered on the steps of a temple in Durbar Square to watch the Kumari procession during Indra Jatra.",
    type: "image" 
},
{
    img:"https://roundsimaging.com/galleries/nepal-peace-corp-photos/individual-contributors/dave-oconnor/photos/DO_CS0413.jpg",
    link: "https://roundsimaging.com/galleries/nepal-peace-corp-photos/individual-contributors/dave-oconnor/DO_CS0413-single.php",
    date: "1975",
    title: "Living Goddess Kumari in her chariot during Indra Jatra festival celebration near Durbar Square.",
    type: "image" 
},
{
    img:"https://roundsimaging.com/galleries/nepal-peace-corp-photos/individual-contributors/bill-hanson/photos/BH_CS000101.jpg",
    link:"https://roundsimaging.com/galleries/nepal-peace-corp-photos/individual-contributors/bill-hanson/BH_CS000101-single.php",
    date:"1969",
    title: "Queen Ratna, royal family members and entourage review the festivities at Durbar Square during Indra Jatra.",
    type: "image" 
},
{
    img:"https://roundsimaging.com/galleries/nepal-peace-corp-photos/individual-contributors/dave-oconnor/photos/DO_CS0415.jpg",
    link:"https://roundsimaging.com/galleries/nepal-peace-corp-photos/individual-contributors/dave-oconnor/DO_CS0415-single.php",
    date: "1975",
    title: "Indra Jatra festival before paying homage to Living Goddess Kumari.",
    type: "image" 
},
{
    img:"https://roundsimaging.com/galleries/nepal-peace-corp-photos/individual-contributors/bill-hanson/photos/BH_CS000105.jpg",
    link:"https://roundsimaging.com/galleries/nepal-peace-corp-photos/individual-contributors/bill-hanson/BH_CS000105-single.php",
    date: "1969",
    title: "Square and is carried on a large cart through the streets during Indra Jatra.",
    type: "image"
},
{
    img:"https://roundsimaging.com/galleries/nepal-peace-corp-photos/individual-contributors/alan-fairbank/photos/AF_CS00365.jpg",
    link:"https://roundsimaging.com/galleries/nepal-peace-corp-photos/individual-contributors/alan-fairbank/AF_CS00365-single.php",
    date: "1969",
    title: "Man dressed up in the costume of Lakhe preparing to perform the Lakhe dance at the Indra Jatra",
    type: "image" 
},
,
{
    img:"https://archivenepal.s3.amazonaws.com/images/mahendraindrajatra.jpeg",
    link:"https://photos.google.com/share/AF1QipNKUhI4xd3Hglj4in7UnM9rHuSm-IU_oPRqC7jCzaOwzYOrmnmXE36tVrnRT8kVtg/photo/AF1QipN1cKL0a8jGYOGVp8n1GX_0XLxi5J5MRWr5aMRM?key=X1VxM0xCR1FtZlJhZG5DWnVpT1ByN2VmZWlQZkx3",
    date: "1968",
    title:"King Mahendra & wife at Indra Jatra 1968",
    type: "image" 
},
{
img: "https://roundsimaging.com/galleries/nepal-peace-corp-photos/individual-contributors/bill-hanson/photos/BH_CS000104.jpg",
link: "https://roundsimaging.com/galleries/nepal-peace-corp-photos/individual-contributors/bill-hanson/BH_CS000104-single.php",
date: "1969",
title: "Military and dancers at Indra Jatra festival at Durbar Square",
type: "image" 
},
{
    img:"https://roundsimaging.com/galleries/nepal-peace-corp-photos/individual-contributors/dave-oconnor/photos/DO_CS0410.jpg",
    link: "https://roundsimaging.com/galleries/nepal-peace-corp-photos/individual-contributors/dave-oconnor/photos/DO_CS0410.jpg",
    date:"1975",
    title: "Soldiers during Indra Jatra celebration in Durbar Square.",
    type: "image" 
}
]; 

class IndraJatra extends Component {
    constructor(props) {
        super(props);
        this.state = {
            windowWidth: window.innerWidth,
            searchTerms: '',
            isLoading: false,
            error: null
        };
    }

    onChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    }

    onKeyUp(e) {
        if (e.charCode === 13) {
            window.location.href = '/search?q=' + e.target.value;
        }
    }

    handleResize = (e) => {
        this.setState({ windowWidth: window.innerWidth });
    }

    componentDidMount() {
        window.addEventListener("resize", this.handleResize);
    }

    componentWillUnmount() {
        window.addEventListener("resize", this.handleResize);
    }

    render() {
        const { windowWidth, searchTerms, isLoading, error } = this.state;

        return (
            <div className="application">
               <Helmet>
                    <meta charset="utf-8" />
                    <title>Indra Jatra | Archive Nepal</title>
                    <link rel="canonical" href="https://www.archivenepal.org/indrajatra" />
                    <meta name="description" content="Indra Jatra digital collection." />
                </Helmet>

                <div id="page-wrapper">

                    {windowWidth >= 1265 ? <div className="input-group col-md-4 childSearchBox" style={{ float: 'right', marginTop: '65px' }}>
                        <input type="text" className="form-control input-lg" name="searchTerms" placeholder="Discover Nepal through its history" value={searchTerms} onChange={this.onChange} onKeyPress={this.onKeyUp} style={{ padding: "5px" }} />
                        <Link to={'/search?q=' + this.state.searchTerms} className="searchButton" ><FaSearch /></Link>
                    </div> :
                        <div className="input-group col-md-4 childSearchBox" style={{ float: 'right', marginTop: '65px' }}>
                            <input type="text" className="form-control input-md" name="searchTerms" placeholder="Discover Nepal through its history" value={searchTerms} onChange={this.onChange} onKeyPress={this.onKeyUp} />
                            <Link to={'/search?q=' + this.state.searchTerms} className="searchButton" ><FaSearch /></Link>
                        </div>
                    }
                    <section id="main" className="container">
                        <h2>Indra Jatra [Yenya Punhi]</h2>
                        <h5>Compiled by: Sristi Shrestha</h5>
                        <section className="box special features">
                            <div className="features-row">
                        <div style={{ textAlign: 'left' }}>
                                    <p>Indra Jātrā, also known as Yenyā (Nepal Bhasa: येँयाः), is the biggest religious street festival in Kathmandu, Nepal. The celebrations consist of two events, Indra Jātrā and Kumāri Jātrā. Indra Jātrā is marked by masked dances of deities and demons, displays of sacred images and tableaus in honor of the deity Indra, the king of heaven. Kumāri Jātrā is the chariot procession of the living goddess Kumari. <Button size="small" href='https://en.wikipedia.org/wiki/Indra_Jatra'>Read More</Button></p>
                                </div>
                                <div id="masonry">
                                    {windowWidth >= 1265 ? <Box>
                                        <ImageList variant="masonry" cols={3} gap={20}>
                                            {itemData.map((item) => (
                                                    <ImageListItem key={item.img}>
                                                        {item.type == 'image' ? <ImageModal2 url={item.img} title={item.title} desc={item.description} salestype='none' link={item.link} /> : <VideoModal2 url={item.img} title={item.title} desc={item.description} link={item.link} />}
                                                    </ImageListItem>
                                            ))}
                                        </ImageList>
                                    </Box> :
                                        <Box>
                                            <ImageList variant="masonry" cols={1} gap={1}>
                                                {itemData.map((item) => (
                                                    <ImageListItem key={item.img}>
                                                        {item.type == 'image' ? <ImageModal2 url={item.img} title={item.title} desc={item.description} salestype='none' link={item.link} /> : <VideoModal2 url={item.img} title={item.title} desc={item.description} link={item.link} />}
                                                    </ImageListItem>
                                                ))}
                                            </ImageList>
                                        </Box>
                                    }

                                </div>
                            </div>
                        </section>
                        <h5>Photos Credit: Nepal Peace Corp, British Library | Videos Credit: Baakhan Nyanewaa </h5>
                    </section>
                </div>
            </div>
        )
    }
}
export default IndraJatra;
