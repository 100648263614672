import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import './styles/main.css';

/*class AboutUs extends Component {
    constructor(props) {
        super(props);
    }*/
function AboutUs() {
    //render() {
        return (
            <div className="application">
                <Helmet>
                    <meta charset="utf-8" />
                    <title>About us | Archive Nepal</title>
                    <link rel="canonical" href="https://www.archivenepal.org/aboutUs" />
                    <meta name="description" content="Archive Nepal is a platform to publish and discover historic materials related to Nepal." />
                    <meta name="description" content="Archive Nepal focuses on three main goals: Curate: We index digitized resources scattered around the world. We also seek partners to host their digitized materials and make it freely available through our platform. | Digitize: We seek to partner with individuals and organizations to digitize their physical materials and make them freely available through our platform. | Improve Access: Our digital collection from around the world is easily accessible in our platform through our search engine powered by Elasticsearch." />
                </Helmet>
                <div id="page-wrapper">
                    <section id="main" className="container">
                        <section className="box special features">
                            <div style={{ textAlign: 'left' }}>
                                <h3>About us</h3>
                                <p>Archive Nepal is a non profit corporation incorporated in New York, USA with <a href='https://app.box.com/s/poevf52tw10wfuxchfcngd94ps83ekx7' target='_blank'>501(c)(3) status </a> and in <a href='https://app.box.com/s/vgai6nzqvq3zy149ljwwas4ysqhv6um6' target='_blank'>Kathmandu, Nepal</a> with the affiliation of the <a href='https://app.box.com/s/a5ek7arbqsx7op27zwjgqe598ht3nzzq' target='_blank'>Social Welfare Council </a> of Nepal. Our mission is to encourage the understanding of Nepal’s rich history and culture by curating, digitizing, and improving accessibility to digital resources. In short we want to discover Nepal through its history and invite you to join in our journey.</p>
                                <h3>What’s the need?</h3>
                                <p>Digitization and Archiving are still very nascent fields in Nepal. Most of the digitization of Nepali historical materials has primarily been carried out by foreign universities and museums. Moreover, due to the lack of a central record or national archival repository, they have been siloed in multiple websites, making it even harder for researchers and users to access. We want to lower that barrier by actively searching the web for any historically significant materials and making them freely available via our search engine.</p>
                                <h3>So is Archive Nepal a search engine?</h3>
                                <p>Yes, search engine is a major component of Archive Nepal that improves the accessibility to digital materials but we are more than that. We want to develop Archive Nepal as a digital archive of Nepal, a platform to publish and discover historic materials related to our history and culture. As stated in our mission, Archive Nepal focuses on three main goals

                                    <ol>
                                        <li>Curate: We index digitized resources scattered around the world. We also seek partners to host their digitized materials and make it freely available through our platform.</li>
                                        <li>Digitize: We seek to partner with individuals and organizations to digitize their physical materials and make them freely available through our platform.</li>
                                        <li>Improve Access: Our digital collection from around the world is easily accessible in our platform through our industry leading search engine.</li>
                                    </ol>
                                </p>
                                <h3>What can I find in Archive Nepal?</h3>
                                <p>Currently you can find around 30,000 digitized materials that includes images, objects, videos, books, documents and audios from 18 different national and international sources such as The British Museum, Art institute of Chicago, Metropolitan Museum of Art and Madan Puraskar Pustakalaya. We are constantly curating more materials and will be updating our collection on a regular basis.</p>
                                <h3>How can I use these materials?</h3>
                                <p>All the materials indexed by Archive Nepal are freely available. So feel free to use or share them as you see fit but please make sure you provide appropriate reference to the relevant organization that owns it. Moreover, we would love to hear creative ways you use what you find in Archive Nepal.</p>
                                <h3>Does Archive Nepal own the digitized materials?</h3>
                                <p>As of now, we do not own any digitized materials that are available on the website. Archive Nepal actively searches for digitized materials that are historically significant to Nepal and indexes them to be made available via our search engine. We encourage you to contact the appropriate organization for more information regarding an item. </p>
                                <h3>How can I partner with Archive Nepal?</h3>
                                <p>We love to partner with individuals and organizations and provide access to historic materials that you may have. Please reach out to discuss the scope of your project. We can help with the following
                                    <ol>
                                        <li>If you already have digital resources that are freely available on the web, we can index them so that they are available via Archive Nepal.</li>
                                        <li>If you have materials digitized but are not currently available on the web, we can host them and make them available via Archive Nepal.</li>
                                        <li>If you have physical materials, we can coordinate to digitize them.</li>
                                    </ol>
                                    We can also create an Archive portal for your organization or collection that you can share with your users.
                                    You can contact us using this <a href='/contactUs'>form</a>, email us at <a href="mailto:contact@archivenepal.org">contact@archivenepal.org</a> or through social media.
                                </p>
                                <h3>How can I help?</h3>
                                <p>We are a non profit so we rely on your support and <a href='https://gofund.me/6f6bdb63' target='_blank'>donation</a>.You can also send us your suggestions for new features, recommend collections and items to index and if interested volunteer by filling up this <a href='/contactUs'>form</a>. Your generous contribution will help make Archive Nepal a valuable resource for years to come.
                                </p>

                            </div>
                        </section>
                    </section>

                </div>
            </div>
        )
    }
//}
//}
export default AboutUs;
