import React, { Component } from 'react';
import '../styles/main.css';
import { Link } from 'react-router-dom';
import Box from '@mui/material/Box';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar';
import {FaSearch} from 'react-icons/fa';
import { Helmet } from 'react-helmet';

const itemData = [
    
    {
        "img": "https://archivenepal.s3.amazonaws.com/images/JRktm8b.jpeg",
        "link": "https://photos.google.com/share/AF1QipNKUhI4xd3Hglj4in7UnM9rHuSm-IU_oPRqC7jCzaOwzYOrmnmXE36tVrnRT8kVtg/photo/AF1QipMBeaZU5KQKNtlffM6W09f1Bwj4WNHMxHAObXNA?key=X1VxM0xCR1FtZlJhZG5DWnVpT1ByN2VmZWlQZkx3",
        "title": "People looking out from Kumari's window at Indra Jatra 1968"
    },
    {
        "img": "https://roundsimaging.com/galleries/nepal-peace-corp-photos/individual-contributors/steve-mott/photos/SM028.jpg",
        "link": "https://roundsimaging.com/galleries/nepal-peace-corp-photos/individual-contributors/steve-mott/SM028-single.php?fbclid=IwAR1nRSXWoHIREXHaf5bz_zlgAxqUy_2_GXEpZiPyYrtV1eoOayxx_ZA-JHM",
        "title": "Hill people in the bazaar"
    },
    {
        "img": "https://roundsimaging.com/galleries/nepal-peace-corp-photos/individual-contributors/mike-furst/photos/MFurst0243.jpg",
        "link": "https://roundsimaging.com/galleries/nepal-peace-corp-photos/individual-contributors/mike-furst/MFurst0243-single.php?fbclid=IwAR2uoEPb8lQVkL5RrI2nnwPquOU4KaqiEFfC7Z2JhYuH17dzE7JyRYhd4vE",
        "title": "People waiting to see Gai Jatra procession. tra procession"
    },
    {
        "img": "https://roundsimaging.com/galleries/nepal-peace-corp-photos/individual-contributors/rich-pfau/photos/RP_CS0263.jpg",
        "link": "https://roundsimaging.com/galleries/nepal-peace-corp-photos/individual-contributors/rich-pfau/RP_CS0263-single.php",
        "title": "Gai Jatra in the bazaar."
    },
    {
        "img": "https://roundsimaging.com/galleries/nepal-peace-corp-photos/individual-contributors/jim-fields/photos/JF_CS0032.jpg",
        "link": "https://roundsimaging.com/galleries/nepal-peace-corp-photos/individual-contributors/jim-fields/JF_CS0032-single.php?fbclid=IwAR3tyxuBLzW_w0DQLSlYwQQZvEQlVgXD_SKpr-3xXWeG1HuK-Qts_UoV_ZI",
        "title": "This Newar woman in Ramchok was one of the few people still weaving cloth in the Chiti area."
    },
    {
        "img": "https://roundsimaging.com/galleries/nepal-peace-corp-photos/individual-contributors/larry-daloz/photos/LD_CS00005.jpg",
        "link": "https://roundsimaging.com/galleries/nepal-peace-corp-photos/individual-contributors/larry-daloz/LD_CS00005-single.php?fbclid=IwAR3PjzFyhIizOy1uKkofn7f6PLWWZBKwdValyFPrwPhHyYzoXSuGP-qDBxs",
        "title": "Sherpa woman selling potatoes to a Newar couple at the Dobali Mela. The Dobali Mela was an annual affair held near Bhojpur bazaar, a huge market festival that lasted about a week. It was a primary meeting place for people from the nearby villages to trade with people from the north (Sherpas) and the south near the border and took place every January. This particular woman was a regular at the Bhojpur weekly bazaar and we often bought potatoes or \"shergem,\" a hard, dried cheese, from her. The Dobali Mela seemed to be a dying tradition and people complained that the numbers were dwindling. People were surmising that the \"bright lights\" of Dharan, down at the edge of the Terai, were more appealing now. After all, you could see a movie down there."
    },
    {
        "img": "https://roundsimaging.com/galleries/nepal-peace-corp-photos/individual-contributors/larry-daloz/photos/LD_CS00084.jpg",
        "link": "https://roundsimaging.com/galleries/nepal-peace-corp-photos/individual-contributors/larry-daloz/LD_CS00084-single.php?fbclid=IwAR3PjzFyhIizOy1uKkofn7f6PLWWZBKwdValyFPrwPhHyYzoXSuGP-qDBxs",
        "title": "1964 Location: Kulunga, Bhojpur Description: Kulungi Rai man near his village in remote, far northern Bhojpur District. This man was one of the few people in the village who had seen Westerners before. This was an extremely remote area and the people were reputed to be dangerous, although there was no indication that this was the case. The man's clothing is largely, if not entirely, homespun from the local plant source for linen."
    },
    {
        "img": "https://roundsimaging.com/galleries/nepal-peace-corp-photos/individual-contributors/john-greer/photos/JG159.jpg",
        "link": "https://roundsimaging.com/galleries/nepal-peace-corp-photos/individual-contributors/john-greer/JG159-single.php?fbclid=IwAR1PS1SNKXLgvpjr9UKQUSJ1XOIPsG6kgQfSPX6oMMmmG83tn7p7j_k4z-0",
        "title": "Young men from Jumla spending the coldest winter months in the relative warmth of Surkhet. As was typical for people from Juhey are dressed in woolen clothing."
    },
    {
        "img": "https://roundsimaging.com/galleries/nepal-peace-corp-photos/individual-contributors/swami-paramananda-saraswati/photos/PS326.jpg",
        "link": "https://roundsimaging.com/galleries/nepal-peace-corp-photos/individual-contributors/swami-paramananda-saraswati/PS326-single.php?fbclid=IwAR11AQqDQVSIeDaXMfg11vnu4qiy7p26sg1eyxoMiERzruHxHosFsatb0tI",
        "title": "Two soldiers on leave from British Army in Hong Kong, north of Kulong on their way to their homes in extremely remote villages, where most people couldn't speak Nepali. Keywords: Nepal Photo History Project, Peace Corp Photo History, People, Swami Paramananda Saraswati, soldier"
    },
    {
        "img": "https://roundsimaging.com/galleries/nepal-peace-corp-photos/individual-contributors/gerald-oicles/photos/GO_009.jpg",
        "link": "https://roundsimaging.com/galleries/nepal-peace-corp-photos/individual-contributors/gerald-oicles/GO_009-single.php?fbclid=IwAR1iXjycgbkOZu6Fc4jv8xqJEP8MsNUxa3L7yTT631OAlbaIOo6MGSu9wHc",
        "title": "Three boys, two Madhesi, one Newar. The area was predominately Madhesi, but many Hill People (Newar) had moved down looking for farmland after World War II."
    },
    {
        "img": "https://roundsimaging.com/galleries/nepal-peace-corp-photos/individual-contributors/nancy-larimore/photos/NL015.jpg",
        "link": "https://roundsimaging.com/galleries/nepal-peace-corp-photos/individual-contributors/nancy-larimore/NL015-single.php?fbclid=IwAR0UmYTJ128WdRF9vb8DWcFrWcFa-JAkZ5cyQQoPg6N8boHjMQi_KTwt_xY",
        "title": "Mohan Kanya High School students. Sita Devi Sharma is the girl in the second row with flowers in both braids. Begum Devi, who liked to make people laugh, is in the second row on the right."
    },
    {
        "img": "https://roundsimaging.com/galleries/nepal-peace-corp-photos/individual-contributors/kelvin-kent/photos/KBK-0013.jpg",
        "link": "https://roundsimaging.com/galleries/nepal-peace-corp-photos/individual-contributors/kelvin-kent/KBK-0013-single.php?fbclid=IwAR09AMT2aonyJNngyKdY14p0FK3B-oo5aG4xvCorH0J4KilN9d8Z5xj05qc",
        "title": "Dashain at the British Gurkha Depot in Dharan. The three dressed-up people are marunies who are actually young men who perform dances, The post with the year written on it was where the buffalo beheadings took place (maula)."
    },
    {
        "img": "https://roundsimaging.com/galleries/nepal-peace-corp-photos/individual-contributors/peter-von-mertens/photos/PVM_CS000069.jpg",
        "link": "https://roundsimaging.com/galleries/nepal-peace-corp-photos/individual-contributors/peter-von-mertens/PVM_CS000069-single.php?fbclid=IwAR1iXjycgbkOZu6Fc4jv8xqJEP8MsNUxa3L7yTT631OAlbaIOo6MGSu9wHc",
        "title": "Middle aged woman in Gatlang valley a sparsely populated and rather poor area west of the Trisuli River. The people called themselves Bhotia and were of Tibetan stock but had probably been living in Nepal for many generations."
    },
    {
        "img": "https://roundsimaging.com/galleries/nepal-peace-corp-photos/individual-contributors/john-greer/photos/JG005.jpg",
        "link": "https://roundsimaging.com/galleries/nepal-peace-corp-photos/individual-contributors/john-greer/JG005-single.php?fbclid=IwAR2nbZUm8ruHATgYoHiupa2sTEaX6X0vYB5n0n9UdC7RiU1XA2IXK1UqNKs",
        "title": "This man was a refugee from Tibet, carrying all his worldly belongings. He was at the Chispani ford on the Karnali River, a location normally busy with people waiting to cross on one of the dugout canoes. He was very friendly but didn’t speak Nepali. "
    },
    {
        "img": "https://roundsimaging.com/galleries/nepal-peace-corp-photos/individual-contributors/craig-fournier/photos/CF_CS00059.jpg",
        "link": "https://roundsimaging.com/galleries/nepal-peace-corp-photos/individual-contributors/craig-fournier/CF_CS00059-single.php?fbclid=IwAR1emfAwUcIsuImycHhOTtt5EDmIg6AUoXXIHrvqNx2cxBSzYLHBWrADdLU",
        "title": "Police officer with Lee Enfield 303 rifle"
    },
    {
        "img": "https://roundsimaging.com/galleries/nepal-peace-corp-photos/individual-contributors/craig-fournier/photos/CF_CS00034.jpg",
        "link": "https://roundsimaging.com/galleries/nepal-peace-corp-photos/individual-contributors/craig-fournier/CF_CS00034-single.php?fbclid=IwAR1mFQJQWpoiwevCUkyUPdzLaEfqjv0llsa32BnJaFddVO0T6nOfKBRO05M",
        "title": "Bhotia boy with Hindu farmer in background. A few Bhotia (Tibetan) families come down to lower elevations in the winter to live among the Hindus before going back to higher elevations in the spring."
    },
    {
        "img": "https://roundsimaging.com/galleries/nepal-peace-corp-photos/individual-contributors/allen-davenport/photos/AD_FB0038.jpg",
        "link": "https://roundsimaging.com/galleries/nepal-peace-corp-photos/individual-contributors/allen-davenport/AD_FB0038-single.php?fbclid=IwAR1nRSXWoHIREXHaf5bz_zlgAxqUy_2_GXEpZiPyYrtV1eoOayxx_ZA-JHM",
        "title": "Children harvesting rice."
    },
    {
        "img": "https://roundsimaging.com/galleries/nepal-peace-corp-photos/individual-contributors/craig-fournier/photos/CF_CS00049.jpg",
        "link": "https://roundsimaging.com/galleries/nepal-peace-corp-photos/individual-contributors/craig-fournier/CF_CS00049-single.php?fbclid=IwAR2nCyCcWUbDyRk2kl5JXkxR_EyQEdVdNaPqi-oIADAGUaiC-BY_aZa23t8",
        "title": "Tamang villagers dressed in finest clothes."
    },
    {
        "img": "https://roundsimaging.com/galleries/nepal-peace-corp-photos/individual-contributors/craig-fournier/photos/CF_CS00002.jpg",
        "link": "https://roundsimaging.com/galleries/nepal-peace-corp-photos/individual-contributors/craig-fournier/CF_CS00002-single.php?fbclid=IwAR0_s0BxlMflqr9nyxTRybcu__QsZetfwzz-DFLYNNJiR0QNa6gUqINoCxc",
        "title": "Curious children. "
    },
    {
        "img": "https://roundsimaging.com/galleries/nepal-peace-corp-photos/individual-contributors/dan-karr/photos/DK00009.jpg",
        "link": "https://roundsimaging.com/galleries/nepal-peace-corp-photos/individual-contributors/dan-karr/DK00009-single.php?fbclid=IwAR1PS1SNKXLgvpjr9UKQUSJ1XOIPsG6kgQfSPX6oMMmmG83tn7p7j_k4z-0",
        "title": "Wife of Ram Ji Babu Thapa with her children and neighboring children."
    },
    {
        "img": "https://roundsimaging.com/galleries/nepal-peace-corp-photos/individual-contributors/craig-fournier/photos/CF_CS00035.jpg",
        "link": "https://roundsimaging.com/galleries/nepal-peace-corp-photos/individual-contributors/craig-fournier/CF_CS00035-single.php?fbclid=IwAR09AMT2aonyJNngyKdY14p0FK3B-oo5aG4xvCorH0J4KilN9d8Z5xj05qc",
        "title": "Tamang family. "
    },
    {
        "img": "https://roundsimaging.com/galleries/nepal-peace-corp-photos/individual-contributors/larry-daloz/photos/LD_CS00059.jpg",
        "link": "https://roundsimaging.com/galleries/nepal-peace-corp-photos/individual-contributors/larry-daloz/LD_CS00059-single.php?fbclid=IwAR19wpcjDaswB9ryf-tz9el_YfrnfbU4_pkfxfdu_XWVoKKr8asxBpCTE8Q",
        "title": "1964 Location: Bhojpur Description: Two people share a glass of raksi in front of the traditional \"still\" used to ferment it. The large pot holds the fermented millet which is heated over a fire. The steam condenses on the bottom of the brass cone-shaped pot and drips down into the smaller earthenware pot resting on top of the millet. The cone-shaped pot is filled with cold water to enhance condensation, and the water is periodically changed, making for different batches. The raksi made from the \"first water\" is, of course, the best. The woman in the picture, locally known as the \"Mantrini\" because her husband had been Minister of Agriculture (Mantri) until he was imprisoned by the King. She was the landlady of Peace Corps volunteer Larry Daloz and became a very close friend."
    },
    {
        "img": "https://roundsimaging.com/galleries/nepal-peace-corp-photos/individual-contributors/charles-bailey/photos/CB_CS00142.jpg",
        "link": "https://roundsimaging.com/galleries/nepal-peace-corp-photos/individual-contributors/charles-bailey/CB_CS00142-single.php?fbclid=IwAR2nbZUm8ruHATgYoHiupa2sTEaX6X0vYB5n0n9UdC7RiU1XA2IXK1UqNKs",
        "title": "Students Til Bahadur Baniya and Bhojendraman Shakya in doorway of Ananda Kuti Vidhyapeeth at Swayambhu. "
    },
    {
        "img": "https://roundsimaging.com/galleries/nepal-peace-corp-photos/individual-contributors/craig-fournier/photos/CF_CS00082.jpg",
        "link": "https://roundsimaging.com/galleries/nepal-peace-corp-photos/individual-contributors/craig-fournier/CF_CS00082-single.php?fbclid=IwAR1xxHXPp1xfSQVScj6YR2HKWwzvJfJutybA14eUHvz93jzZuqUeF0aMo1k",
        "title": "Making chura by pounding rice."
    },
    {
        "img": "https://roundsimaging.com/galleries/nepal-peace-corp-photos/individual-contributors/charles-bailey/photos/CB_CS00127.jpg",
        "link": "https://roundsimaging.com/galleries/nepal-peace-corp-photos/individual-contributors/charles-bailey/CB_CS00127-single.php?fbclid=IwAR2btam5ijAM8BilifJOKYjYnTlt0jHzdiEy2Ft2dG6j6hikXz7d181B_Rg",
        "title": "Soldiers stand at ease in Durbar Square during the Kumari procession on Indra Jatra."
    },
    {
        "img": "https://roundsimaging.com/galleries/nepal-peace-corp-photos/individual-contributors/joe-hapak/photos/JHAP_FB00094.jpg",
        "link": "https://roundsimaging.com/galleries/nepal-peace-corp-photos/individual-contributors/joe-hapak/JHAP_FB00094-single.php?fbclid=IwAR2xl1ROxJQTt6GLFbqFNA5H6hT2j1sMBjN6NtEO-ToKluujvp3hnudwSLI",
        "title": "Brahmin women. "
    },
    {
        "img": "https://roundsimaging.com/galleries/nepal-peace-corp-photos/individual-contributors/jim-fields/photos/JF_CS0011.jpg",
        "link": "https://roundsimaging.com/galleries/nepal-peace-corp-photos/individual-contributors/jim-fields/JF_CS0011-single.php?fbclid=IwAR2hwx77fZzvjXUjVtr9DUBXqhgrwEMMkdUv9sMteE6_885zvTEtosOowbk",
        "title": "Members of the Gurung ethnic group return from a trip above the highest permanent settlements with the fruit of a Jack-in-the-Pulpit plant. The wool capes (boku) slung over their shoulders here were their only protection against rain and cold."
    },
    {
        "img": "https://roundsimaging.com/galleries/nepal-peace-corp-photos/individual-contributors/gil-donahue/photos/GD035.jpg",
        "link": "https://roundsimaging.com/galleries/nepal-peace-corp-photos/individual-contributors/gil-donahue/GD035-single.php?fbclid=IwAR2nbZUm8ruHATgYoHiupa2sTEaX6X0vYB5n0n9UdC7RiU1XA2IXK1UqNKs",
        "title": "Girl from a hill village who has been cutting fodder to feed the animals. Flowers in her hair."
    },
    {
        "img": "https://roundsimaging.com/galleries/nepal-peace-corp-photos/individual-contributors/jim-fisher/photos/JFisher0115.jpg",
        "link": "https://roundsimaging.com/galleries/nepal-peace-corp-photos/individual-contributors/jim-fisher/JFisher0115-single.php?fbclid=IwAR1ni-bc2PWE5-Sxic2aAYXfftAeB86g7z_5O_KjICfaxwdOOFB5HxQ_Xwo",
        "title": "A woman is about to leave the village, with her husband, on a winter trading trip. Grease is being applyed to the part in her hair, for good luck The strip of bandage protects her ear, which was torn off her face when her sister-in-law stuck her finger in the earring hole and tore the bottom of her ear off her face. The woman in the lower left of the photo has rolled up an empty cigarette package to stick in the earring hole in her ear, so that it will not grow shut when she is not wearing earrings. The mother of the house, also to the left, is wearing a large, solid gold earring usually worn only during festivals. She is the only woman in the village who wears such earrings every day; when asked why she does that, she said, simply, that she likes to do so. "
    },
    {
        "img": "https://roundsimaging.com/galleries/nepal-peace-corp-photos/individual-contributors/ron-rude/photos/RRude0013.jpg",
        "link": "https://roundsimaging.com/galleries/nepal-peace-corp-photos/individual-contributors/ron-rude/RRude0013-single.php?fbclid=IwAR2abQjZA42tuDBXDTKlkmZHuKuYfjueb0K6YWRKaB6iViGKcABoGh74Qho",
        "title": " 1970 Location: Megharaul, Mahottari Description: Villagers shop and socialize during a village mela. Vendors sold many different items such as candy, cloth, ribbons, and the pinwheels shown here. In the background several people ride on an elephant."
    },
    {
        "img": "https://archivenepal.s3.amazonaws.com/images/JRGaneshHima32.jpeg",
        "link": "https://photos.google.com/share/AF1QipMRk3A9ApHZAw6E300WvU9X6KmM7Sf8tcScwPiOxABiyhq63wuIuweYLzsJogmYOA/photo/AF1QipM5TCWzbG1KAEF_7cs3dfy-ysgCE2q5R2mAklWH?key=Ym5XQkNKUGc5dzFuVFVDMF8xaHlXT1N1M3YxcFNB",
        "title": "Gatlung woman and child "
    },
    {
        "img": "https://3.api.artsmia.org/800/100642.jpg",
        "link": "https://collections.artsmia.org/art/100642/brick-workers-david-parker?fbclid=IwAR0sQ4sC1106jliNWTKUcRjdq-E-3-rl70IYQfQBsvKGrqnEoJw8AnqBl3Q",
        "title": "Brick Workers, Nepal, 1993"
    },
    {
        "img": "https://collections.mfa.org/internal/media/dispatcher/737112/preview",
        "link": "https://collections.mfa.org/objects/113070/girl-with-dalai-lama-locket?ctx=455e67eb-4ec2-49af-aa00-0c5c38d3f50a&idx=6&fbclid=IwAR0sQ4sC1106jliNWTKUcRjdq-E-3-rl70IYQfQBsvKGrqnEoJw8AnqBl3Q",
        "title": "Girl with Dalai Lama Locket"
    },
    {
        "img": "https://collections.mfa.org/internal/media/dispatcher/1326917/preview",
        "link": "https://collections.mfa.org/objects/266376/rana-tharu-brothers-with-sunglasses-dekat-bhuli-village-ka?ctx=455e67eb-4ec2-49af-aa00-0c5c38d3f50a&idx=2&fbclid=IwAR0BXd5FTr-Sn76U0fqxxRQUk1k7c9AJqqb4spUCx_j1UrC9G3RSiKE1w5E",
        "title": "Rana Tharu Brothers with Sunglasses, Dekat Bhuli Village, Kanchanpur, Nepal"
    },
    {
        "img": "https://collections.mfa.org/internal/media/dispatcher/1326916/preview",
        "link": "https://collections.mfa.org/objects/266533/lama-kangri-tenzing-and-young-monk-sonam-tsering-mangri-vil?ctx=455e67eb-4ec2-49af-aa00-0c5c38d3f50a&idx=3&fbclid=IwAR1259-ZtKwt_Pr-dhnBpcUz3nS8Ce7fjQM0Iirext94oG_iqnuMKpcV0BM",
        "title": "Lama Kangri Tenzing and Young Monk Sonam Tsering, Mangri Village, Mugu, Nepal"
    }
];



class FacesOfNepal extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            windowWidth: window.innerWidth, 
            searchTerms: '',
            isLoading: false,
            error: null
        };
    }
	
	onChange = (e) => {
		this.setState({ [e.target.name]: e.target.value });
	}
	
	onKeyUp(e){
		if (e.charCode === 13) {
			window.location.href = '/search?q=' + e.target.value;
		  }
	}

    handleResize = (e) => {
        this.setState({ windowWidth: window.innerWidth });
    }

    componentDidMount() {
        window.addEventListener("resize", this.handleResize);
    }

    componentWillUnmount() {
        window.addEventListener("resize", this.handleResize);
    }

    render() {
        const { windowWidth, searchTerms, isLoading, error } = this.state;

        return (
            <div className="application">
                <Helmet>
                    <meta charset="utf-8" />
                    <title>Faces of Nepal | Archive Nepal</title>
                    <link rel="canonical" href="https://www.archivenepal.org/facesofnepal" />
                    <meta name="description" content="Faces of Nepal digital collection." />
                </Helmet>
         
            <div id="page-wrapper">
                
                {windowWidth >= 1285 ? <div className="input-group col-md-4 childSearchBox" style={{float: 'right', marginTop: '65px'}}>
					<input type="text" className="form-control input-lg" name="searchTerms" placeholder="Discover Nepal through its history" value={searchTerms} onChange={this.onChange} onKeyPress={this.onKeyUp} style={{padding: "5px"}} />	
						<Link to={'/search?q=' + this.state.searchTerms} className="searchButton" ><FaSearch /></Link>					
					</div>  :
                    <div className="input-group col-md-4 childSearchBox" style={{float: 'right', marginTop: '65px'}}>
					<input type="text" className="form-control input-md" name="searchTerms"  placeholder="Discover Nepal through its history" value={searchTerms} onChange={this.onChange} onKeyPress={this.onKeyUp} />	
						<Link to={'/search?q=' + this.state.searchTerms} className="searchButton" ><FaSearch /></Link>					
                    </div> 
                    }
                <section id="main" className="container">
                    <h2>Faces of Nepal</h2>
                    <h5>Compiled by: Dristi Manandhar</h5>
                    <section className="box special features">
                        <div className="features-row">
                            <div style={{textAlign: 'left'}}>
                                
                            </div>
                            <div id="masonry">

                                {windowWidth >= 1285 ? <Box>
                                    <ImageList variant="masonry" cols={3} gap={8}>
                                        {itemData.map((item) => (
                                            <a href={item.link} target='_blank'>
                                            <ImageListItem key={item.img}>
                                                <img
                                                    src={`${item.img}?w=248&fit=crop&auto=format`}
                                                    srcSet={`${item.img}?w=248&fit=crop&auto=format&dpr=2 2x`}
                                                    alt={item.title}
                                                    loading="lazy"
                                                />
                                                 <a href={item.link} target='_blank'>
                                                        <ImageListItemBar position="below" title={item.title} style={{ width: '300px' }} />
                                                    </a>
                                            </ImageListItem>
                                            </a>
                                        ))}
                                    </ImageList>
                                </Box> :
                                    <Box>
                                        <ImageList variant="masonry" cols={1} gap={1}>
                                            {itemData.map((item) => (
                                                <ImageListItem key={item.img}>
                                                    <img
                                                        src={`${item.img}?w=248&fit=crop&auto=format`}
                                                        srcSet={`${item.img}?w=248&fit=crop&auto=format&dpr=2 2x`}
                                                        alt={item.title}
                                                        loading="lazy"
                                                    />
                                                    <a href={item.link} target='_blank'>
                                                        <ImageListItemBar position="below" title={item.title} style={{ width: '300px' }} />
                                                    </a>
                                                </ImageListItem>
                                            ))}
                                        </ImageList>
                                    </Box>
                                }

                            </div>
                        </div>
                    </section>
                </section>
                </div> 
            </div>
        )
    }
}
export default FacesOfNepal;